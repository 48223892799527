import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { DistributionPartnerRoleInfoAdapter } from '../../../../../../billing/shared/distribution-partner-role-info/distribution-partner-role-info.adapter';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { RESPONSE_CODES } from '../../../../../../shared/constants/common.constants';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { CCAuthorizationAgreementParams, EFTAuthorizationAgreementParams } from '../../../../../../shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { DistributedDocumentsResponseModel } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form/model/distributed-documents-response.model';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { PaymentItem } from '../../../../shared/models/payment-item.model';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
import { EditAutomaticPaymentsReview } from './edit-automatic-payments-review.model';
import { EditAutomaticPaymentsReviewService } from './edit-automatic-payments-review.service';
@Component({
    selector: 'app-edit-automatic-payments-review',
    templateUrl: './edit-automatic-payments-review.component.html',
    styleUrls: ['./edit-automatic-payments-review.component.scss']
})

export class EditAutomaticPaymentsReviewComponent extends BasePageComponent implements OnInit {
    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.edit.review;
    considerThisReviewFields: RecapFormField[] = [];
    paymentDetailsReviewFields: RecapFormField[] | BankcardRecapFormField[] = [];
    loadComplete = false;
    authorizationAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams;
    acknowledgedCheckboxDisabled: boolean;
    distributedDocumentsResponseModel: DistributedDocumentsResponseModel;

    // eslint-disable-next-line max-params
    constructor(
        private review: EditAutomaticPaymentsReview,
        router: Router,
        private editAutomaticPaymentsReviewService: EditAutomaticPaymentsReviewService,
        private editAutomaticPaymentsFlowService: EditAutomaticPaymentFlowService,
        private formUtils: FormUtil,
        @Inject('window') private window: Window,
        protected session: SessionService,
        private eventService: BillingEventService,
        @Inject('logger') private logger: LoggerService,
        private urlUtil: UrlUtil,
        private distributionPartnerRoleInfoAdapter: DistributionPartnerRoleInfoAdapter
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        try {
            super.ngOnInit();
            this.session.currentPage = this.pageName;
            this.review.initialize();
            this.paymentDetailsReviewFields = this.review.paymentDetailsReviewFields;
            this.considerThisReviewFields = this.review.considerThisReviewFields;
            this.setAuthorizationAgreementInputParams();
            this.loadComplete = true;
            if (this.editAutomaticPaymentsFlowService.isPaperlessEligible) {
                this.logger.info('Edit Automatic Payments Review - Page load',
                    this.editAutomaticPaymentsReviewService.logPageLoadForPaperless(this.paymentFormReview.enrollInPaperless)
                );
            }
        } catch (error) {
            this.logger.error('Edit Automatic Payments Review - load failed: ', {
                error: error.stack || error
            });
        }
    }

    get paymentFormReview(): PaymentFormGroup {
        return this.review.editAutomaticPaymentForm;
    }

    get considerThisSectionDisplayable(): boolean {
        return this.review.considerThisDisplayable;
    }

    navigateToEditAutomaticPayments(): void {
        const reviewPageUrl = environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.EDIT.LANDING(
            this.urlUtil.hashParamsString);
        this.router.navigateByUrl(`${reviewPageUrl}&fromPage=review`);
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(
            this.urlUtil.hashParamsString
        ));
    }

    navigateToConfirmationPage(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.EDIT.CONFIRMATION(
                this.urlUtil.hashParamsString
            ));
    }

    get ezPayAuthSectionDisplayable(): boolean {
        return !!this.authorizationAgreementParams;
    }

    onSubmitEditAutomaticPayment(): void {
        this.sendOnClickOfSubmitButtonEbiEvent();
        this.formUtils.touchAllFormControls(this.paymentFormReview.group);
        if (this.paymentFormReview.group.valid && !(this.ezPayAuthSectionDisplayable && this.review.addEzPayAuthGroupControls.disabled)) {
            this.loadComplete = false;
            this.editAutomaticPaymentsReviewService.submit().subscribe((res) => {
                if (this.isVulErrorResponse(res.updateAutomaticPaymentMethod)) {
                    const selectedPayMethod: PaymentItem = this.editAutomaticPaymentsFlowService.selectedPayMethod;
                    const editAutomaticPaymentForm = this.editAutomaticPaymentsFlowService.editAutomaticPaymentForm;
                    this.editAutomaticPaymentsFlowService.clear();
                    this.editAutomaticPaymentsFlowService.save({ hasCreditCardVulPolicyError: true, selectedPayMethod, editAutomaticPaymentForm });
                    this.navigateToEditAutomaticPayments();
                } else {
                    this.navigateToConfirmationPage();
                }
            });
        } else {
            if (this.ezPayAuthSectionDisplayable) {
                this.review.addEzPayAuthGroupControls.markAllAsTouched();
            }
            this.window.scroll(0, 0);
        }
    }

    sendOnClickOfSubmitButtonEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_SUBMIT_BTN_EDIT_AUTOMATIC_PAYMENTS_REVIEW_PAGE, {
            paymentPlan: this.editAutomaticPaymentsFlowService.paymentPlan
        });
    }

    private setAuthorizationAgreementInputParams(): void {
        this.authorizationAgreementParams = this.editAutomaticPaymentsFlowService.authorizationAgreementParams;
        this.distributedDocumentsResponseModel = this.editAutomaticPaymentsFlowService.distributedDocumentsResponseModel;
        if (this.review.isBankAccountFlow) {
            this.acknowledgedCheckboxDisabled = true;
        }
    }

    get paperlessSelection(): boolean {
        return this.review?.enrollingInPaperless !== 'Yes';
    }

    private isVulErrorResponse(updateAutomaticPaymentMethodResponse: boolean | HttpErrorResponse): boolean {
        return updateAutomaticPaymentMethodResponse instanceof HttpErrorResponse &&
            updateAutomaticPaymentMethodResponse.status === RESPONSE_CODES.ERRORS.SAP.VUL_CREDIT_CARD &&
            this.session.isVUL;
    }

    sendOnClickOfAuthAgreementEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_AUTHORIZATION_AGREEMENT_LINK, {
            authAgreementUrl: 'Y',
            paymentPlan: this.editAutomaticPaymentsFlowService.paymentPlan
        });
    }
}
