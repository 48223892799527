import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import {
    IsbExperienceService, MoneyProcessingService, isbExperienceServiceToken
} from '@nationwide/dgs-angular-billing-common';
import { SessionService } from '../../../../../../shared/session/session.service';
import { PAYMENT_PLAN } from '../../../../../../shared/constants/common.constants';
@Injectable()
export class EditPaymentPlanService {
    // eslint-disable-next-line max-params
    constructor(
        private sessionService: SessionService,
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private moneyProcessing: MoneyProcessingService
    ) { }

    private get billingService(): IsbExperienceService | MoneyProcessingService {
        let service: IsbExperienceService | MoneyProcessingService;
        if (this.sessionService.billingSystem === 'NBP') {
            service = this.moneyProcessing;
        } else {
            service = this.isbExperience;
        }
        return service;
    }

    patchPaymentPlan(patchPayPlanType: string): Observable<number> {
        return this.billingService.patchPayPlan({
            accessToken: this.sessionService.accessToken,
            billingSystem: this.sessionService.billingSystem,
            agreementNumber: this.sessionService.billingAccountNumber,
            payload: {
                billingPayload: {
                    requestType: 'BillingMethodChange.PayPlanUpdate',
                    billingAccountInformation: {
                        acctPlanFrequencyType: patchPayPlanType
                    }
                }
            }
        });
    }

    getAgreementText(scheduledPaymentFlag: boolean, fullPayAmount: number): string {
        if (!scheduledPaymentFlag && fullPayAmount > 0) {
            return PAYMENT_PLAN.REMAINING_BAL_DUE;
        } else if (!scheduledPaymentFlag && fullPayAmount === 0) {
            return PAYMENT_PLAN.PREMIUM_DUE;
        } else if (scheduledPaymentFlag && fullPayAmount > 0) {
            return PAYMENT_PLAN.REMAINING_BAL_WITHDRAW;
        } else if (scheduledPaymentFlag && fullPayAmount === 0) {
            return PAYMENT_PLAN.PREMIUM_WITHDRAW;
        }
    }
}
