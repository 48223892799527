export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const SIXTY_DAYS_IN_ADVANCE = 60;

export const AMEX_CVV_LENGTH = 4;
export const ALL_OTHER_CVV_LENGTH = 3;
export const NBP = 'NBP';
export const LOCAL_NAME = 'en-US';
export const DEFAULT_FORMAT_TYPE = 'MM/dd/yyyy';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const CLICK_TO_CHAT = {
    POLICY_NUMBER_UPPER: 'POLICY_NUMBER',
    BILLING_ACCOUNT_NUMBER: 'BILLING_ACCOUNT_NUMBER',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
    EMAIL_ADDRESS: 'EmailAddress',
    POLICY_NUMBER_LOWER: 'policyNumber',
    IS_CLICK_TO_CHAT_EXISTING: 'isClickToChatExisting',
    TRUE: 'true'
};

export const URL_CONSTANT = {
    BILLING_TIMELINE_ROUTE_URL: '/personal/billing-timeline',
    BILLING_PICKER_ROUTE_URL: '/personal/billing/picker',
    BILLING_DETAILS_URL: '/personal/billing/details'
};

export const TIMELINE_RESPONSE_INDEX = {
    BILL_INDEX: 0,
    RELATED_POLICY_INDEX: 1,
    POLICY_EVENT_INDEX: 2,
    TRANSACTION_INDEX: 3,
    ACCOUNT_INDEX: 4,
    ZERO_AMOUNT_DUE_INDEX: 5,
    REFUND_STATUSES: 6,
    STATIC_CONTENT_INDEX: 7
};

export const PAYMENT_METHOD_TYPES = {
    BANK_ACCOUNT: 'Bank Account',
    BANK_CARD: 'Bank Card',
    MASTERPASS: 'Masterpass'
};

export const BANK_ACCOUNT = {
    TYPE: {
        CHECKING: 'Checking',
        CHECKING_SHORTHAND: 'C',
        SAVINGS: 'Savings',
        SAVINGS_SHORTHAND: 'S'
    }
};
export const MAKE_A_PAYMENT = 'Make a payment';

// TODO: Remove once account validation code for every flow is migrated to GIACT
export const ACCOUNT_VALIDATION_CONSTANT = {
    ERROR_CODE_LIST: ['578', '579'],
    MESSAGE_CONTAINER_MAP: new Map<string, string>()
        .set('578', `We are unable to process electronic payments from this account due to your account status. Please select another
   account or another form of payment. For questions please contact your bank or Early Warning Services at 1-800-745-4210.`)
        .set('579', `Your bank account is not eligible for electronic payments. Please select another account or another form of payment.
  For questions please contact your bank.`)

};

export const LINE_OF_BUSINESS_LIST = {
    OIPA: ['IL', 'N3', 'Individual Life', 'SI', 'VL'],
    CAREMATTERS: ['C2'],
    VUL: 'VL'
};

export const INCREASE_OVERPAYMENT_STATES = ['KS', 'MO', 'MT'];

export const LIFE_LINE_OF_BUSINESS_LIST = [...LINE_OF_BUSINESS_LIST.OIPA, ...LINE_OF_BUSINESS_LIST.CAREMATTERS];

export const EFT_AUTH_FORM_CONSTANT = {
    VALIDATION_MESSAGE_NOT_PAYMENT_PREFERENCE_PAGE: `Automatic payments for checking/savings is currently unavailable. Please use credit/debit card or try again later.`,
    VALIDATION_MESSAGE_PAYMENT_PREFERENCE_PAGE: 'This document is currently unavailable for viewing at this time. Please try again later.',
    MISSING: 'Missing',
    SERVICE_DOWN: 'Service Down',
    HP_EXTREME_SERVICE_SUCCEESS_RESPONSE_CODE: 8,
    DPIM_TEMP_AGENT_NUMBER: '080Z02428012'
};

export const RESPONSE_CODES = {
    ERRORS: {
        SAP: {
            VUL_CREDIT_CARD: 417
        },
        FIVE_HUNDERED: 500

    },
    SUCCESSFUL: 204
};

export const PLACEHOLDER_MESSAGES = {
    THANKYOU_SUCCESS_MESSAGE: `Thank you for your payment and letting Nationwide protect what's most important to you.`
};

export const MAKE_ANOTHER_PAYMENT_METRICS_LABELS = {
    FAILED_PAYMENT: 'DigEx_284_failed_payment',
    SUCCESSFUL_PAYMENT: 'DigEx_284_successful_payment',
    TOTAL_PAYMENT_AMOUNT: 'DigEx_284_total_payment_amount',
    SURVEY_QUESTION: 'DigEx_284_SurveyQuestion',
    BOOTSTRAPPER_NOT_FOUND: 'DigEx_284_MakeAnotherPayment Bootstrapper Not Found'
};

export const PAPERLESS_ENROLL_MESSAGE = {
    ERROR_MESSAGE: 'An error occurred while processing your paperless billing enrollment. Please try again later.'
};

export const OMS = {
    SERVICE_TRANSFORMATION_STRATEGY: {
        AGENT_PHONE_NUMBER: '1-800-282-1446'
    }
};

export const ECIF = {
    PRODUCER_VALUES: {
        RESIDENT_PRODUCER: 'Resident Producer',
        AMF: 'AMF',
        PDS: 'PDS',
        INDEPENDENT_CHANNEL_AGENT: 'Independent Channel Agent',
        EXCLUSIVE_CHANNEL_AGENT: 'Exclusive Channel Agent',
        IA: 'IA',
        EA: 'EA'
    }
};

export const PAYMENT_PLAN = {
    MONTH_PAY_DESC: 'You pay a portion of your premium each month for more flexibility, with just a moderate processing fee.',
    FULL_PAY_DESC: 'You pay your entire premium up front for simplicity and peace-of-mind the rest of the term while avoiding monthly processing fees.',
    REMAINING_BAL_DUE: 'I understand that any remaining balance from my current policy will be due with my next issued bill AND I understand that my entire premium will be due in full at renewal.',
    REMAINING_BAL_WITHDRAW: 'I understand that any remaining balance from my current policy will be withdrawn as an automatic payment with my next issued bill AND I understand that my entire premium will be automatically withdrawn in full at renewal.',
    PREMIUM_DUE: 'I understand that my entire premium will be due in full at renewal.',
    PREMIUM_WITHDRAW: 'I understand that my entire premium will be automatically withdrawn in full at renewal.',
    INFO_DESC: 'Please note: Making the shift to the Full pay plan means you will have to contact us if you wish to change your pay plan in the future.'
};

export const PAY_PLAN_CONFIRMATION_PAGE_TEXTS = {
    UPCOMING_PAYMENTS: {
        BILL: 'Bill',
        AMOUNT: 'Amount',
        AUTOMATIC_PAYMENT_DATE: 'Automatic payment date',
        DUE_DATE: 'Due date',
        CURRENT_ISSUED: 'Current issued',
        NEXT: 'Next',
        RENEWAL: 'Renewal',
        FULL_PREMIUM_TBD: 'Full premium tbd'
    }
};

export const PAYER_INFO_MAXIMUM_ALLOW_CHAR_API = {
    FIRST_LAST_NAME: 25,
    STREET_ADDRESS: 50,
    BILLING_ADDRESS: 30
};
