import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { PaymentMethodsAdapter } from '../../../billing/payment-preferences/shared/adapters/payment-methods.adapter';
import { BankAccountPaymentItem } from '../../../billing/payment-preferences/shared/models/bankaccount-item.model';
import { BankCardPaymentItem } from '../../../billing/payment-preferences/shared/models/bankcard-item.model';
import { PaymentItem } from '../../../billing/payment-preferences/shared/models/payment-item.model';

@Injectable()
export class WaysToPayAdapter {
    constructor(
        private paymentMethodsAdapter: PaymentMethodsAdapter,
        private titleCasePipe: TitleCasePipe
    ) { }

    mapPaymentMethod(waysToPayMessage: any): PaymentItem {
        const paymentMethod = waysToPayMessage?.data?.details?.PL;
        return paymentMethod?.savePaymentDetails ? this.mapSavedPaymentMethod(paymentMethod) : this.mapOneTimePaymentMethod(paymentMethod);
    }

    private mapOneTimePaymentMethod(paymentMethod: any): PaymentItem {
        return paymentMethod?.paymentMethod === 'Credit Card' ? this.mapOneTimeBankCard(paymentMethod) : this.mapOneTimeBankAccount(paymentMethod);
    }

    private mapOneTimeBankCard(paymentMethod: any): PaymentItem {
        let expiryDate = paymentMethod?.paymentToken?.paymentTxnDetails?.paymentMethod?.expirationDate;
        // eslint-disable-next-line no-magic-numbers
        expiryDate = `${expiryDate.slice(4)}/15/${expiryDate.slice(0, 4)}`; // format comes as yyyymm, need to convert to mm/dd/yyyy

        return new PaymentItem(new BankCardPaymentItem(
            paymentMethod?.additionalDetails?.additionalCardInfo?.firstName || '',
            paymentMethod?.additionalDetails?.additionalCardInfo?.lastName || '',
            '',
            '',
            paymentMethod?.additionalDetails?.additionalCardInfo?.billingZIP || '',
            this.paymentMethodsAdapter.retrieveBankCardType(paymentMethod?.paymentToken?.paymentTxnDetails?.paymentMethod?.cardType),
            paymentMethod?.paymentToken?.paymentTxnDetails?.paymentMethod?.ccLastFour || '',
            new Date(expiryDate),
            paymentMethod?.paymentToken?.paymentTxnDetails?.paymentMethod?.ccLastFour || '',
            'Credit',
            paymentMethod?.paymentToken?.paymentTxnDetails?.paymentMethod?.ProfileId
        ),
            false,
            '',
            [],
            false
        );
    }

    private mapOneTimeBankAccount(paymentMethod: any): PaymentItem {
        return new PaymentItem(new BankAccountPaymentItem(
            paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.firstName || '',
            paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.lastName || '',
            '',
            paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.address || '',
            paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.appSuiteBuilding || '',
            paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.city || '',
            paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.state || '',
            paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.zipCode || '',
            this.titleCasePipe.transform(paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.bankName) || '',
            this.paymentMethodsAdapter.retrieveBankAccountType(paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.bankAccountType),
            paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.bankRoutingNumber || '',
            paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.bankAccountNumber || '',
            paymentMethod?.paymentToken?.paymentTxnDetails?.electronicFunds?.electronicFundsTransfer?.bankAccountNumber || ''
        ),
            false,
            '',
            [],
            false
        );
    }

    private mapSavedPaymentMethod(paymentMethod: any): PaymentItem {
        return paymentMethod.savePaymentDetails.paymentMethodType.toLowerCase() === 'bankcard.savedpaymentmethod' ? this.mapSavedBankCard(paymentMethod) : this.mapSavedBankAccount(paymentMethod);
    }

    private mapSavedBankCard(paymentMethod: any): PaymentItem {
        let expiryDate = paymentMethod.savePaymentDetails.bankCard?.expirationDate;
        // eslint-disable-next-line no-magic-numbers
        expiryDate = `${expiryDate.slice(0, 2)}/15/${expiryDate.slice(3)}`; // format comes as mm/yyyy

        return new PaymentItem(new BankCardPaymentItem(
            paymentMethod.savePaymentDetails.payorInfo?.firstName || '',
            paymentMethod.savePaymentDetails.payorInfo?.lastName ? `${paymentMethod.savePaymentDetails.payorInfo?.lastName}***` : '***',
            paymentMethod.savePaymentDetails.payorInfo?.middleName || '',
            paymentMethod.savePaymentDetails.bankCard?.cardCVV || '',
            this.isPopulated(paymentMethod.savePaymentDetails.payorInfo?.postalCode) ?
                paymentMethod.savePaymentDetails.payorInfo?.postalCode : '',
            this.paymentMethodsAdapter.retrieveBankCardType(paymentMethod.savePaymentDetails.bankCard?.cardBrand),
            paymentMethod.savePaymentDetails.bankCard?.ccLastFour || '',
            new Date(expiryDate),
            paymentMethod.savePaymentDetails.bankCard?.ccLastFour || '',
            'Credit',
            paymentMethod.savePaymentDetails.bankCard?.profileId
        ),
            paymentMethod.savePaymentDetails.preferredMethod === 'true',
            paymentMethod.savePaymentDetails.paymentMethodId,
            [],
            paymentMethod.savePaymentDetails.recurringMethod === 'true'
        );
    }

    private mapSavedBankAccount(paymentMethod: any): PaymentItem {
        return new PaymentItem(new BankAccountPaymentItem(
            paymentMethod.savePaymentDetails.payorInfo?.firstName || '',
            paymentMethod.savePaymentDetails.payorInfo?.lastName ? `${paymentMethod.savePaymentDetails.payorInfo?.lastName}***` : '***',
            paymentMethod.savePaymentDetails.payorInfo?.middleName || '',
            this.isPopulated(paymentMethod.savePaymentDetails.payorInfo?.addressLine1) ?
                paymentMethod.savePaymentDetails.payorInfo?.addressLine1 : '',
            this.isPopulated(paymentMethod.savePaymentDetails.payorInfo?.addressLine2) ?
                paymentMethod.savePaymentDetails.payorInfo?.addressLine2 : '',
            this.isPopulated(paymentMethod.savePaymentDetails.payorInfo?.city) ?
                paymentMethod.savePaymentDetails.payorInfo?.city : '',
            this.isPopulated(paymentMethod.savePaymentDetails.payorInfo?.state) ?
                paymentMethod.savePaymentDetails.payorInfo?.state : '',
            this.isPopulated(paymentMethod.savePaymentDetails.payorInfo?.postalCode) ?
                paymentMethod.savePaymentDetails.payorInfo?.postalCode : '',
            this.titleCasePipe.transform(paymentMethod.savePaymentDetails.electronicFundsTransfer?.bankName) || '',
            this.paymentMethodsAdapter.retrieveBankAccountType(paymentMethod.savePaymentDetails.electronicFundsTransfer?.bankAccountType),
            paymentMethod.savePaymentDetails.electronicFundsTransfer?.bankRoutingNumber || '',
            paymentMethod.savePaymentDetails.electronicFundsTransfer?.maskedBankAccountNumber || '',
            paymentMethod.savePaymentDetails.electronicFundsTransfer?.encryptedBankAccountNumber || ''
        ),
            paymentMethod.savePaymentDetails.preferredMethod === 'true',
            paymentMethod.savePaymentDetails.paymentMethodId,
            [],
            paymentMethod.savePaymentDetails.recurringMethod === 'true'
        );
    }

    private isPopulated(paymentMethodProperty: string): boolean {
        return paymentMethodProperty !== 'null' && paymentMethodProperty != null;
    }
}
