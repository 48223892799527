import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { QuickPayResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/portfolio-business-service';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../../../environments/environment';
import { EXTERNAL_BTL } from '../../../billing-timeline-widget/billing-timeline-widget.constants';

@Injectable()
export class CredentialManagerService {
    private jwtHelper = new JwtHelperService();

    private _policyNumbers: string[];
    private _policyNumbersRelatedToAccount: string[];

    constructor(
        private route: ActivatedRoute,
        private cookies: CookieService
    ) { }

    get billingAccountNumber(): string {
        return this.billingAccountNumberFromQueryParameters();
    }

    get billingAccountNumberForRCA(): string {
        return this.billingAccountNumberFromQueryParameters() || this.billingAccountNumberFromJwt();
    }

    private billingAccountNumberFromQueryParameters(): string {
        return this.route.snapshot.queryParams[environment.QUERY_PARAMS.billingAccount];
    }

    private billingAccountNumberFromJwt(): string {
        let agreementNumber = 'NA';
        if (this.agreements && this.agreements.length) {
            const billingProfiles = this.agreements.filter((agreement) => agreement.product_type === 'Billing')[0];
            // eslint-disable-next-line camelcase
            agreementNumber = billingProfiles?.agreement_number || 'NA';
        }
        return agreementNumber;
    }

    get policyNumber(): string {
        return this.policyNumberFromQueryParameters();
    }

    private policyNumberFromQueryParameters(): string {
        return this.route.snapshot.queryParams[environment.QUERY_PARAMS.policy];
    }

    get firstName(): string {
        let firstName = '';
        if (this.authRealm === 'employee') {
            firstName = this.jwtObject.userId;
        } else if (this.jwtObject && this.jwtObject.given_name) {
            firstName = this.jwtObject.given_name;
        } else if (this.quickPayPbsResponse && this.quickPayPbsResponse.firstName) {
            firstName = this.quickPayPbsResponse.firstName;
        }
        return firstName;
    }

    get lastName(): string {
        let lastName = '';
        if (this.authRealm === 'employee') {
            lastName = this.jwtObject.userId;
        } else if (this.jwtObject && this.jwtObject.family_name) {
            lastName = this.jwtObject.family_name;
        } else if (this.quickPayPbsResponse && this.quickPayPbsResponse.lastName) {
            lastName = this.quickPayPbsResponse.lastName;
        }
        return lastName;
    }

    get userId(): string {
        return this.jwtObject && this.jwtObject.userId ||
            sessionStorage.getItem(EXTERNAL_BTL.SESSION_STORAGE_KEYS.USER_ID) ||
            '';
    }

    get partyRoleType(): string {
        let agreements = this.jwtObject ? this.jwtObject.agreements : '';
        agreements = agreements instanceof Array ? agreements : [agreements];

        const billingAgreement = agreements.filter((agreement) => agreement.agreement_number === this.billingAccountNumber)[0];
        // eslint-disable-next-line camelcase
        return billingAgreement?.party_role_type || '';
    }

    get idToken(): string {
        return sessionStorage.getItem(environment.OAUTH_CONFIG.idTokenStorage);
    }

    // TODO: GH-Issue-1499: Needs to be used to populate ebi event data on confirmation page when the user returns from masterpass.
    get masterpassTempIdToken(): string {
        return sessionStorage.getItem('oldId');
    }

    get jwtObject(): any {
        let encodedJwt;

        if (this.idToken) {
            encodedJwt = this.idToken;
        } else if (this.masterpassTempIdToken) {
            encodedJwt = this.masterpassTempIdToken;
        }

        return encodedJwt ? this.jwtHelper.decodeToken(encodedJwt) : undefined;
    }

    get accessToken(): string {
        const accessToken = sessionStorage.getItem(environment.OAUTH_CONFIG.tokenStorage);
        return accessToken ? accessToken.split(',')[0] : '';
    }

    get authRealm(): string {
        return this.jwtObject ? this.jwtObject.realm : '';
    }

    get a2aSessionId(): string {
        const sessionId = this.a2aSessionIdFromQueryParameters();
        return sessionId && sessionId.length > 0 ? sessionId : this.a2aSessionIdFromCookies();
    }

    private a2aSessionIdFromQueryParameters(): string {
        return this.route.snapshot.queryParams[environment.QUERY_PARAMS.a2aSessionId];
    }

    private a2aSessionIdFromCookies(): string {
        return this.cookies.get('myAccountUserSessionId') || this.randomSessionId;
    }

    get randomSessionId(): string {
        const sessionId = this.a2aSessionIdFromRandom;
        // eslint-disable-next-line isb/no-hardcoded-nw-url
        const domain = environment.PRODUCTION ? '.nationwide.com' : '.nwie.net';
        this.cookies.put('myAccountUserSessionId', sessionId, { path: '/', domain });
        this.cookies.put('MYACCOUNT_USER_SESSION', `userid=${this.userId}:url=/myaccount/index.jsp`, { path: '/', domain });
        return sessionId;
    }

    get a2aSessionIdFromRandom(): string {
        const randomMultiplier = 16;
        const randomAggregater = 16;
        const charLength = 32;
        return 'xxxxxxxxxxxx4xxxxxxxxxxxxxxx4xxx'.replace(/[xy]/g, (char) => {
            const random = Math.floor(Math.random() * randomMultiplier);
            const value = char === 'x' ? random : random % randomAggregater + randomAggregater;
            return value.toString(charLength);
        });
    }

    get ecn(): string {
        let response = '';
        if (this.jwtObject && this.jwtObject.ecn) {
            response = this.jwtObject.ecn;
        } else if (this.jwtObject && this.jwtObject.sub) {
            response = this.jwtObject.sub.split(':')[1];
        }
        return response;
    }
    get guid(): string {
        return this.jwtObject ? this.jwtObject.guid : '';
    }

    get productType(): string {
        return this.jwtObject ? this.jwtObject.productType : '';
    }

    get agreements(): any[] {
        return this.jwtObject?.agreements || [];
    }

    get aud(): string {
        return this.jwtObject ? this.jwtObject.aud : '';
    }

    get clearTrustToken(): string {
        return sessionStorage.getItem(environment.OAUTH_CONFIG.loginTokenParam);
    }
    get clearTrustSessionToken(): string {
        return sessionStorage.getItem(environment.OAUTH_CONFIG.loginTokenSessionStorageLocation);
    }

    get emailId(): string {
        return this.jwtObject ? this.jwtObject.email : '';
    }

    get policyNumbers(): string[] {
        return this._policyNumbers;
    }

    set policyNumbers(numbers: string[]) {
        this._policyNumbers = numbers;
    }

    get policyNumbersRelatedToAccount(): string[] {
        return this._policyNumbersRelatedToAccount;
    }

    set policyNumbersRelatedToAccount(numbers: string[]) {
        this._policyNumbersRelatedToAccount = numbers;
    }

    get quickPayPbsResponse(): QuickPayResponse {
        let pbsResponse: QuickPayResponse;
        try {
            pbsResponse = JSON.parse(sessionStorage[environment.SESSION_STORAGE.QUICK_PAY_PBS_RESPONSE]);
        } catch {
            pbsResponse = undefined;
        }

        return pbsResponse;
    }

    get appName(): string {
        return this.route.snapshot.queryParams[environment.QUERY_PARAMS.appName];
    }
}
