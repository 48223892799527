/* eslint-disable no-negated-condition */
import { TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { SavedPaymentMethod } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience/money-processing/saved-payment-methods';
import { map, Observable } from 'rxjs';
import { CommonValuesPaymentFormGroupModel } from '../../../../../../../app/billing/model/common-values-payment-form-group.model';
import { environment } from '../../../../../../../environments/environment';
import { ManageAccountValidationService } from '../../../../../../account-validation/manage-account-validation.service';
import { BillingCommonComponent } from '../../../../../../billing/billing-common/billing-common.component';
import { PaymentItem } from '../../../../../../billing/payment-preferences/shared/models/payment-item.model';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { CardType } from '../../../../../../shared/payments/card-utils/card-type.enum';
import {
    EasyPayAuthorizationAgreementService,
    EFTAuthorizationAgreementParams
} from '../../../../../../shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { EftAuthorizationFlowService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-flow.service';
import { EftAuthorizationFormHelperService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form-helper.service';
import { EftAuthorizationFormValidationService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form-validation.service';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormControls } from '../../../../../../shared/payments/payment-form-controls';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { EasyPayAdapter } from '../../../../shared/adapters/easy-pay.adpater';
import { PaymentMethodsAdapter } from '../../../../shared/adapters/payment-methods.adapter';
import { CreatePaymentPreferencesLanding } from '../../../../shared/services/payment-preferences-api/payment-preferences-api-calls.model';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { PaymentPrefsLanding } from '../../../base/payment-prefs-landing.model';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SetupAutomaticPaymentsFlowService } from '../services/setup-automatic-payments-flow.service';
import { AccountPolicyHolderAddressElements } from './../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form/form-elements/account-policy-holder-address-elements';

@Component({
    selector: 'app-setup-automatic-payments-landing',
    templateUrl: './setup-automatic-payments-landing.component.html',
    styleUrls: ['./setup-automatic-payments-landing.component.scss']
})
export class SetupAutomaticPaymentsLandingComponent
    extends BillingCommonComponent
    implements OnInit {
    @ViewChild(FormGroupDirective)
    automaticPaymentsFormDirective: FormGroupDirective;

    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.setup.landing;
    paymentDetailsReviewFields: RecapFormField[] | BankcardRecapFormField[] = [];
    setupAutomaticPaymentsLanding: PaymentPrefsLanding;
    paymentFormGroup: PaymentFormGroup;
    paymentMethods: PaymentItem[];
    loadComplete = false;
    isEFTBilled: boolean;
    isRBCBilled: boolean;
    isBilledStatus: boolean;
    NBP: boolean;
    isFullPay: boolean;
    isLifeOnlyAccount: boolean;
    formValidated = false;
    private chosenPaymentMethod: PaymentItem;
    vulCreditCardErrorMessage: Observable<string>;
    apiCallInProgress = true;
    esddaCheckBoxChecked = false;
    paperlessEsddaValidationError = false;
    bankCardAutomaticPaymentsCapMessage: string;
    isAutoPayment = true;
    increaseReftAutoPayFlag = false;

    // eslint-disable-next-line max-params
    constructor(
        session: SessionService,
        router: Router,
        private paymentPrefsFormGroup: ManagePaymentPrefsFormBuilder,
        private paymentFormControls: PaymentFormControls,
        private paymentMethodsAdapter: PaymentMethodsAdapter,
        private paymentPrefApiService: PaymentPreferencesApiService,
        private paymentPrefsStateService: PaymentPreferencesStateService,
        private formUtil: FormUtil,
        eventService: BillingEventService,
        private titlecasePipe: TitleCasePipe,
        private easyPayAdapter: EasyPayAdapter,
        private setupAutomaticPaymentsFlowService: SetupAutomaticPaymentsFlowService,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private manageAccountValidationService: ManageAccountValidationService,
        private easyPayAuthorizationAgreementService: EasyPayAuthorizationAgreementService,
        eftAuthorizationFormHelperService: EftAuthorizationFormHelperService,
        private eftAuthorizationFormValidationService: EftAuthorizationFormValidationService,
        eftAuthorizationFlowService: EftAuthorizationFlowService,
        readonly contentfulService: ContentfulMapperService,
        @Inject('logger') private logger: LoggerService
    ) {
        super(
            session,
            router,
            eftAuthorizationFormHelperService,
            eventService,
            eftAuthorizationFlowService
        );
    }

    ngOnInit(): void {
        this.vulCreditCardErrorMessage = this.contentfulService
            .getContentfulBillingHeader()
            .pipe(
                map((contentfulData) => contentfulData.creditCardVulPolicyError.content)
            );
        this.setIncreaseReftConditions();
        this.fetchAutomaticPaymentsData();
        this.session.currentPage = this.pageName;
        this.fetchBankCardAutomaticPaymentsCapMessage();
    }

    sendOnLoadAutomaticPaymentEbiEvent(): void {
        this.eventService.handleEvent(
            EBIEventType.ON_LOAD_AUTOMATIC_PAYMENTS_LANDING_PAGE, {
            paymentPlan: this.setupAutomaticPaymentsLanding.paymentPlan
        }
        );
    }
    cancelSetupAutomaticPayments(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(
                this.currentRouteParams()
            )
        );
    }

    navigateToReviewAutomaticPayments(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.SETUP.REVIEW(
                this.currentRouteParams()
            )
        );
    }

    currentRouteParams(): string {
        return this.router.url.split('?')[1];
    }

    setSelectedPaymentmethodValues(): void {
        if (this.chosenPaymentMethod) {
            if (
                this.paymentDetailsReviewFields &&
                this.chosenPaymentMethod.isNewMethod
            ) {
                this.paymentDetailsReviewFields = [];
            } else {
                this.showPaymentDetailsReviewFields(this.chosenPaymentMethod);
            }
            this.formValidated = true;
            this.setupPaymentFormGroup(this.chosenPaymentMethod, true);
        }
    }

    get selectedPaymentMethod(): PaymentItem {
        return this.chosenPaymentMethod;
    }

    set selectedPaymentMethod(paymentMethod: PaymentItem) {
        this.chosenPaymentMethod = paymentMethod;
        if (
            this.selectedPaymentMethod &&
            !this.selectedPaymentMethod?.isPlaceholder
        ) {
            this.setupPaymentFormGroup(this.selectedPaymentMethod, true);
        }
        if (this.selectedPaymentMethod?.isBankAccount && this.NBP) {
            this.validateAgentInformation();
        } else {
            this.setSelectedPaymentmethodValues();
        }
    }

    setupPaymentFormGroup(
        paymentMethod: PaymentItem,
        isEasyPayFlow: boolean
    ): void {
        this.paymentFormGroup.group.removeControl('paymentMethodGroup');
        this.paymentFormGroup.group.addControl(
            'paymentMethodGroup',
            paymentMethod.isNewMethod ?
                this.paymentFormControls.newPaymentPreferencesPaymentMethodGroup(
                    paymentMethod,
                    {},
                    this.hasSavedPayMethods,
                    isEasyPayFlow
                ) :
                this.paymentFormControls.savedPaymentPreferencesPaymentMethodGroup(
                    paymentMethod,
                    {}
                )
        );
    }

    get hasSavedPayMethods(): boolean {
        return false;
    }

    setEnrollInPaperlessAutomatically(): void {
        if (this.autoEnrollInPaperless) {
            this.paymentFormGroup.group
                .get('considerThisGroup.enrollInPaperless')
                .setValue('yes');
        }
    }
    setupPaymentForm(): void {
        if (this.setupAutomaticPaymentsFlowService.flowInPlay) {
            this.paymentFormGroup =
                this.paymentPrefsFormGroup.setupEasyPayReconstructFormGroup({
                    selectedPayMethod: this.selectedPaymentMethod,
                    oldForm:
                        this.setupAutomaticPaymentsFlowService.setUpAutomaticPaymentsForm
                            .group,
                    hasSavedPayments: this.hasSavedPayMethods
                });
        } else {
            this.paymentFormGroup =
                this.paymentPrefsFormGroup.newAutomaticPaymentFormGroup({
                    selectedPayMethod: this.selectedPaymentMethod,
                    hasSavedPayments: this.hasSavedPayMethods
                });
        }

        this.setEnrollInPaperlessAutomatically();
    }

    initSelectedPayMethod(): void {
        this.chosenPaymentMethod = this.getSelectDefaultPayMethod();
        this.showPaymentDetailsReviewFields(this.chosenPaymentMethod);
    }

    getSelectDefaultPayMethod(): PaymentItem {
        if (
            !this.session.referringPageUrl.includes('review') ||
            this.displayVulCreditCardErrorMessage
        ) {
            return this.paymentMethods.find(
                (method) => {
                    if (this.increaseReftAutoPayFlag) {
                        return method.displayName === 'New checking/savings account';
                    } else {
                        return method.isPlaceholder;
                    }
                });
        } else {
            return this.paymentMethods.find(
                (method) =>
                    method.id ===
                    this.setupAutomaticPaymentsFlowService.selectedPayMethod.id
            );
        }
    }

    showPaymentDetailsReviewFields(selectedPaymentMethod: PaymentItem): void {
        const fields = [];

        if (selectedPaymentMethod.isBankAccount && !selectedPaymentMethod.isNewMethod) {
            fields.push({
                id: 'account-type-recap',
                label: 'Account type',
                value: this.titlecasePipe.transform(selectedPaymentMethod.methodType)
            });

            fields.push({
                id: 'bank-routing-number-recap',
                label: 'Bank routing number',
                value: selectedPaymentMethod.bankAccountRoutingNumber
            });

            fields.push({
                id: 'bank-name-recap',
                label: 'Bank name',
                value: selectedPaymentMethod.bankName
            });

            fields.push({
                id: 'account-number-recap',
                label: 'Account number',
                value: `*********${selectedPaymentMethod.displayNumbers}`
            });
        }
        if (selectedPaymentMethod.isBankCard && !selectedPaymentMethod.isNewMethod) {
            fields.push({
                id: 'card-number-recap',
                label: 'Card number',
                value: `****************${selectedPaymentMethod.displayNumbers}`,
                cardType: selectedPaymentMethod.methodType
            });

            fields.push({
                id: 'cvv-recap',
                label:
                    selectedPaymentMethod.methodType === CardType.AMERICAN_EXPRESS ?
                        'CID' :
                        'CVV',
                value:
                    selectedPaymentMethod.methodType === CardType.AMERICAN_EXPRESS ?
                        '****' :
                        '***'
            });

            fields.push({
                id: 'expiration-date-recap',
                label: 'Expiration date',
                value: selectedPaymentMethod.bankCardExpirationDate
            });

            fields.push({
                id: 'zip-recap',
                label: 'ZIP',
                value: selectedPaymentMethod.bankCardZipCode
            });
        }
        this.paymentDetailsReviewFields = fields;
    }

    fetchAutomaticPaymentsData(): void {
        this.paymentPrefApiService.createPaymentPreferencesLanding().subscribe({
            next: (
                createPaymentPreferencesLanding: CreatePaymentPreferencesLanding
            ) => {
                const {
                    retrieveCustomerAgreement,
                    internetRegistrationResponse,
                    automaticPayments,
                    savedPaymentMethods
                } = createPaymentPreferencesLanding;
                this.paymentMethods =
                    this.mapAndFilterPaymentMethods(savedPaymentMethods);
                this.NBP = this.session.sourceSystem === 'NBP';
                this.isLifeOnlyAccount =
                    this.managePaymentPrefsAdapter.hasLifeOnlyBusiness(
                        retrieveCustomerAgreement
                    );
                const billingAgreement = this.easyPayAdapter.getBillingAgreementFromRCA(
                    this.easyPayAdapter.getAgreementsFromRCA(retrieveCustomerAgreement)
                );
                this.isBilledStatus =
                    this.easyPayAdapter.getBilledStatus(billingAgreement);
                this.isEFTBilled =
                    this.easyPayAdapter.getEFTBilledStatus(billingAgreement);
                this.isRBCBilled =
                    this.easyPayAdapter.getRBCBilledStatus(billingAgreement);
                const billingAccountHolderName =
                    this.easyPayAdapter.getFullName(automaticPayments);
                const billingAccountNumber =
                    this.managePaymentPrefsAdapter.fetchBillingAccountNumber(
                        retrieveCustomerAgreement
                    );
                const amountDue =
                    this.managePaymentPrefsAdapter.fetchBillingAccountAmountDue(
                        retrieveCustomerAgreement
                    );
                const nextBillDueDate = new Date(
                    this.easyPayAdapter.nextDueDate(retrieveCustomerAgreement)
                );
                const currentBillDueDate =
                    this.managePaymentPrefsAdapter.fetchBillingAccountAmountDueDate(
                        retrieveCustomerAgreement
                    );
                const isPaperlessEligible =
                    this.managePaymentPrefsAdapter.isEligibleForPaperless(
                        internetRegistrationResponse
                    );
                const isEasyPayEligible = false;
                const emailAddress =
                    this.managePaymentPrefsAdapter.fetchMostCurrentEmail(
                        retrieveCustomerAgreement,
                        internetRegistrationResponse
                    );
                const address: AccountPolicyHolderAddressElements = this.managePaymentPrefsAdapter.addressFromInternetRegistrationResponse(internetRegistrationResponse);
                const billingAddress1 = address?.AcctPolHolderAddr1;
                const billingCity = address?.AcctPolHolderCity;
                const billingState = address?.AcctPolHolderState;

                const paymentPlan =
                    this.managePaymentPrefsAdapter.getPayPlanValueForEBI(
                        retrieveCustomerAgreement
                    );
                this.isFullPay = this.managePaymentPrefsAdapter.isAccountFullPay(
                    retrieveCustomerAgreement
                );
                this.setupAutomaticPaymentsLanding = new PaymentPrefsLanding(
                    this.paymentMethods,
                    billingAccountHolderName,
                    billingAccountNumber,
                    amountDue,
                    nextBillDueDate,
                    currentBillDueDate,
                    isPaperlessEligible,
                    isEasyPayEligible,
                    emailAddress,
                    billingAddress1,
                    billingCity,
                    billingState,
                    this.isLifeOnlyAccount,
                    paymentPlan
                );
                this.initSelectedPayMethod();
                this.setupPaymentForm();
                this.sendOnLoadAutomaticPaymentEbiEvent();
                this.loadComplete = true;
            },
            error: (error) => {
                this.sendOnLoadAutomaticPaymentEbiEvent();
                this.logger.error(
                    'HARDFALL: FETCH SETUP AUTOMATIC PAYMENTS DATA ERROR',
                    { error }
                );
                this.navigateToHardfall();
            }
        });
    }

    onEsddaCheckboxClick(value): void {
        this.esddaCheckBoxChecked = value;
        if (this.esddaCheckBoxChecked) {
            this.paperlessEsddaValidationError = false;
        }
    }

    getEmailAddress(): string {
        if (this.paymentFormGroup.enrollInPaperless === 'no') {
            this.setupAutomaticPaymentsFlowService.paperlessEmail =
                this.setupAutomaticPaymentsLanding.emailAddress;
        }
        return this.setupAutomaticPaymentsFlowService.paperlessEmail ||
            this.setupAutomaticPaymentsLanding.emailAddress;
    }

    mapAndFilterPaymentMethods(payMethods: SavedPaymentMethod[]): PaymentItem[] {
        return this.paymentMethodsAdapter
            .mapSortPayMethods(payMethods, {
                includeNewBankCard: !this.session.isVUL,
                includeNewDebitCard: this.session.isVUL,
                filterCreditCards: this.session.isVUL
            })
            .filter((method) => {
                const bankCardsAllowed = this.session.isSAP;
                return bankCardsAllowed ?
                    !method.isMasterPass :
                    !method.isMasterPass &&
                    (!method.isBankCard || method.isNewBankCard);
            });
    }

    setupEzPayAuthFormGroup(): void {
        this.paymentFormGroup.group.addControl(
            'ezPayAuthGroup',
            this.paymentFormControls.newEzPayAuthGroup()
        );
    }

    submit(): void {
        this.formUtil.touchAllFormControls(this.paymentFormGroup.group);
        if (this.isEsddaValidationFailure()) {
            this.paperlessEsddaValidationError = this.isEsddaValidationFailure();
            if (this.paperlessEsddaValidationError) {
                this.logger.info(
                    'Setup Automatic Payments Page - Failed page validation due to ESDDA after continue button is clicked',
                    {
                        accountNumber: this.session.billingAccountNumber,
                        enterpriseCustomerNumber: this.session.ecn,
                        paperlessBillingDeliveryResponse:
                            this.paymentFormGroup.enrollInPaperless
                    }
                );
            }
        }
        this.formValidated = !!this.isAccountValidationReturnErrorCode;
        this.setDefaultPaymentMethodSelectionError(this.selectedPaymentMethod);
        if (
            this.paymentFormGroup.group.valid &&
            !this.paperlessEsddaValidationError
        ) {
            this.validateBankAccount();
        }
    }

    isEsddaValidationFailure(): boolean {
        return (
            this.paymentFormGroup.enrollInPaperless === 'yes' &&
            !this.esddaCheckBoxChecked
        );
    }

    saveFormToFlow(): void {
        this.setupAutomaticPaymentsFlowService.save({
            setUpAutomaticPaymentsForm: this.paymentFormGroup,
            billingAccountHolderName:
                this.setupAutomaticPaymentsLanding.billingAccountHolderName,
            billingAccountNumber:
                this.setupAutomaticPaymentsLanding.billingAccountNumber,
            amountDue: this.setupAutomaticPaymentsLanding.amountDue,
            nextBillDueDate: this.setupAutomaticPaymentsLanding.nextBillDueDate,
            currentBillDueDate: this.setupAutomaticPaymentsLanding.currentBillDueDate,
            isPaperlessEligible:
                this.setupAutomaticPaymentsLanding.canSelectPaperlessBilling,
            isEligibleForEasyPay:
                this.setupAutomaticPaymentsLanding.isEasyPayEligible,
            emailAddress: this.setupAutomaticPaymentsLanding.emailAddress,
            selectedPayMethod: this.selectedPaymentMethod,
            selectedPaperless: this.paymentFormGroup.enrollInPaperless,
            billedStatus: this.isEFTBilled || this.isRBCBilled || this.isBilledStatus,
            billingStreetLine1: this.setupAutomaticPaymentsLanding.streetLine1,
            billingCity: this.setupAutomaticPaymentsLanding.billingCity,
            billingState: this.setupAutomaticPaymentsLanding.billingState,
            distributedDocumentsResponseModel: this.distributedDocumentsResponseModel,
            authorizationAgreementParams: this.authorizationAgreementParams,
            isLifeOnlyAccount: this.setupAutomaticPaymentsLanding.isLifeOnlyAccount,
            considerThisDisplayable: this.considerThisDisplayable,
            paymentPlan: this.setupAutomaticPaymentsLanding.paymentPlan
        });
    }

    get considerThisDisplayable(): boolean {
        return (
            this.isEasyPayEligible ||
            this.setupAutomaticPaymentsLanding.canSelectPaperlessBilling
        );
    }

    navigateToHardfall(): void {
        this.router.navigateByUrl('/personal/error/system-down');
    }

    determineNextAction(): void {
        this.commonValuesPaymentFormGroupModel =
            new CommonValuesPaymentFormGroupModel(
                this.paymentFormGroup,
                this.selectedPaymentMethod,
                this.setupAutomaticPaymentsLanding.billingAccountHolderName
            );
        this.setAuthorizationAgreementParams(false);
        if (this.selectedPaymentMethod?.isPlaceholder) {
            this.isGlobalErrorMessageReqDefaultMethod = true;
        } else if (this.selectedPaymentMethod?.isBankAccount && this.NBP) {
            this.fetchEFTAuthForms();
        } else {
            this.saveFormToFlow();
            this.navigateToReviewAutomaticPayments();
            this.setupAutomaticPaymentsFlowService.save({
                hasVULCreditCardError: false
            });
        }
    }

    fetchEFTAuthForms(): void {
        this.easyPayAuthorizationAgreementService
            .fetchEFTAuthFormsForNewlyEnrolled(
                <EFTAuthorizationAgreementParams> this.authorizationAgreementParams,
                this.agencyInformationResponseModel
            )
            .subscribe(
                (response) => {
                    this.distributedDocumentsResponseModel = response;
                    if (
                        this.eftAuthorizationFormValidationService.validateDistributedDocumentResponse(
                            this.distributedDocumentsResponseModel
                        )
                    ) {
                        this.setHPExtremeServiceResponseStatus(
                            this.distributedDocumentsResponseModel.returnCode,
                            false
                        );
                        this.saveFormToFlow();
                        this.triggerEbiEventToLogNumberOfTimesHPExtremeServiceInvoke();
                        this.navigateToReviewAutomaticPayments();
                    } else {
                        this.modifyPaymentMethods();
                        this.setSelectedPaymentmethodValues();
                        this.setEFTAuthFormValidationValues();
                        this.sendIncompleteFormElementsOnEFTAuthFormEbiEvent();
                    }
                },
                (error: HttpErrorResponse) => {
                    this.logger.error(
                        'EFT Auth Form Failure - Setup Automatic Payments',
                        error
                    );
                    this.logger.logCurrentContents();
                    this.setHPExtremeServiceResponseStatus(
                        this.getStausFromErrorResponse(error),
                        true
                    );
                    this.triggerEbiEventToLogNumberOfTimesHPExtremeServiceInvoke();
                    this.modifyPaymentMethods();
                    this.setSelectedPaymentmethodValues();
                    this.setEFTAuthFormValidationValues();
                }
            );
    }

    validateAgentInformation(): void {
        this.easyPayAuthorizationAgreementService
            .fetchAgencyInformation()
            .subscribe(
                (response) => {
                    this.agencyInformationResponseModel = response;
                    if (
                        !this.eftAuthorizationFormValidationService.validateAgentDetails(
                            this.agencyInformationResponseModel
                        )
                    ) {
                        this.modifyPaymentMethods();
                        this.setEFTAuthFormValidationValues();
                        this.sendIncompleteFormElementsOnEFTAuthFormEbiEvent();
                    }
                    this.setSelectedPaymentmethodValues();
                },
                () => {
                    this.modifyPaymentMethods();
                    this.setSelectedPaymentmethodValues();
                    this.eftAuthorizationFormHelperService.buildEBIValueAgentInformationServiceDown();
                    this.sendIncompleteFormElementsOnEFTAuthFormEbiEvent();
                    this.setEFTAuthFormValidationValues();
                }
            );
    }

    modifyPaymentMethods(): void {
        if (this.paymentMethods && this.paymentMethods.length > 0) {
            this.paymentMethods = this.removeBankAccountPaymentMethods(
                this.paymentMethods
            );
            setTimeout(() => {
                this.chosenPaymentMethod = this.getNewBankCardPaymentMethod(
                    this.paymentMethods
                );
                this.selectedPaymentMethod = this.chosenPaymentMethod;
            });
        }
    }

    setIncreaseReftConditions(): void {
        if (this.session.increaseReftAutomaticPaymentFlag) {
            this.increaseReftAutoPayFlag = this.session.increaseReftAutomaticPaymentFlag === 'true';
        } else {
            this.contentfulService.getContentfulFeatureToggles().subscribe((contentfulData) => {
                this.increaseReftAutoPayFlag = contentfulData.ReftAutoPayTest.content === 'true';
                this.session.increaseReftAutomaticPaymentFlag = contentfulData.ReftAutoPayTest.content;
            });
        }
    }

    private validateBankAccount(): void {
        if (
            this.selectedPaymentMethod?.isNewBankAccount &&
            this.manageAccountValidationService.isEnabled
        ) {
            this.apiCallInProgress = true;
            this.manageAccountValidationService
                .invokeAccountValidationService({
                    firstName: this.paymentFormGroup.firstName,
                    lastName: this.paymentFormGroup.lastName,
                    bankAccountNumber: this.paymentFormGroup.bankAccountNumber,
                    bankRoutingNumber: this.paymentFormGroup.bankRoutingNumber,
                    bankAccountType: <any> this.paymentFormGroup.bankAccountType,
                    isPlaidEnabled: false,
                    isAuthenticated: true
                })
                .subscribe(
                    (response) => {
                        this.apiCallInProgress = false;
                        this.accountValidationModel = response;
                        if (!response.accepted) {
                            if (response.failureLimitReached) {
                                this.paymentMethods = this.paymentMethods.filter(
                                    (method) => !method.isNewBankAccount
                                );
                                this.chosenPaymentMethod = this.paymentMethods.find(
                                    (method) => method.isPlaceholder
                                );
                            }

                            if (!response.wrongAccountType) {
                                this.setupPaymentForm();
                            }

                            this.setAccountValidationValues();
                            this.formValidated = true;
                        } else {
                            this.determineNextAction();
                        }
                    },
                    (error) => {
                        this.logger.error(
                            'API ERROR: SETUP AUTOMATIC PAYMENTS LANDING VALIDATE BANK ACCT',
                            error
                        );
                    }
                );
        } else {
            this.clearAccountValidationValues();
            this.determineNextAction();
        }
    }

    private fetchBankCardAutomaticPaymentsCapMessage(): void {
        const contentfulResponse =
            this.contentfulService.getContentfulBillingContent();
        contentfulResponse.subscribe((res) => {
            this.bankCardAutomaticPaymentsCapMessage =
                res.bankCardAutomaticPaymentsCapMessage.content;
        });
    }

    get submitted(): boolean {
        return (
            !!this.automaticPaymentsFormDirective &&
            this.automaticPaymentsFormDirective.submitted
        );
    }

    get globalErrorDisplayable(): boolean {
        return (
            !this.formValidated &&
            !this.paymentFormGroup.group.valid &&
            this.submitted ||
            this.isGlobalErrorMessageReqDefaultMethod
        );
    }

    get autoEnrollInPaperless(): boolean {
        return this.paymentPrefsStateService.autoCheckPaperless;
    }

    get isPaperlessEligible(): boolean {
        return (
            this.setupAutomaticPaymentsLanding &&
            this.setupAutomaticPaymentsLanding.canSelectPaperlessBilling
        );
    }

    get isEasyPayEligible(): boolean {
        return (
            this.setupAutomaticPaymentsLanding &&
            this.setupAutomaticPaymentsLanding.isEasyPayEligible
        );
    }

    get displayVulCreditCardErrorMessage(): boolean {
        return this.setupAutomaticPaymentsFlowService.hasVULCreditCardError;
    }
}
