import { PaymentFormGroup } from '../../shared/payments/payment-form-group';
import { cardUtils } from '../../shared/payments/card-utils/card-utils';
import { PaymentItem } from '../payment-preferences/shared/models/payment-item.model';
import { CardType } from '../../shared/payments/card-utils/card-type.enum';
export class CommonValuesPaymentFormGroupModel {
    constructor(
        readonly paymentFormGroup: PaymentFormGroup,
        readonly paymentItem: PaymentItem,
        readonly billingAccountHolderName: string
    ) { }

    get firstName(): string {
        return this.paymentFormGroup.firstName;
    }

    get middleInitial(): string {
        return this.paymentFormGroup.middleInitial;
    }

    get lastName(): string {
        return this.paymentFormGroup.lastName;
    }

    get payorName(): string {
        // eslint-disable-next-line no-negated-condition
        return this.middleInitial !== '' ?
            `${this.firstName} ${this.middleInitial} ${this.lastName}` :
            `${this.firstName} ${this.lastName}`;
    }

    get defaultPaymentMethod(): string {
        return this.paymentFormGroup.makeDefaultPaymentMethod ? 'Yes' : 'No';
    }

    get cvvLabel(): string {
        return this.isAmexCard ? 'CID' : 'CVV';
    }

    get maskedCvv(): string {
        return this.isAmexCard ? '****' : '***';
    }

    get cvv(): string {
        return this.paymentFormGroup.bankCardCvv;
    }

    get isAmexCard(): boolean {
        const cardNumber = this.paymentFormGroup.bankCardNumber;
        return cardUtils.parseType(cardNumber) === CardType.AMERICAN_EXPRESS;
    }

    get cardType(): CardType {
        const cardNumber = this.paymentFormGroup.bankCardNumber;
        return cardUtils.parseType(cardNumber);
    }

    get bankName(): string {
        return this.paymentFormGroup.bankName;
    }

    get bankAccountType(): string {
        return this.paymentFormGroup.bankAccountType;
    }

    get isBankAccountFlow(): boolean {
        return this.paymentItem.isBankAccount;
    }

    get isBankCardFlow(): boolean {
        return this.paymentItem.isBankCard;
    }

    get accountType(): string {
        return this.paymentFormGroup.bankAccountType;
    }

    get apartmentNumber(): string {
        return this.paymentFormGroup.apartmentNumber;
    }

    get zip(): string {
        return this.paymentFormGroup.zip;
    }

    get routingNumber(): string {
        return this.paymentFormGroup.bankRoutingNumber;
    }

    get accountNumberLastThree(): string {
        const bankAccountNumber = this.paymentFormGroup.bankAccountNumber;
        const ACCOUNT_LAST_THREE = 3;
        return bankAccountNumber.slice(bankAccountNumber.length - ACCOUNT_LAST_THREE);
    }

    get bankAccountNumber(): string {
        return this.paymentFormGroup.bankAccountNumber;
    }

    get cardExpirationMonth(): string {
        return this.paymentFormGroup.cardExpirationMonth;
    }

    get cardExpirationYear(): string {
        return this.paymentFormGroup.cardExpirationYear;
    }

    get cardExpirationDate(): string {
        return `${this.cardExpirationMonth}/${this.cardExpirationYear}`;
    }

    get cardNumberLastFour(): string {
        const cardNumber = this.paymentFormGroup.bankCardNumber;
        const CARD_LAST_FOUR = 4;
        return cardNumber.slice(cardNumber.length - CARD_LAST_FOUR);
    }

    get cardNumber(): string {
        return this.paymentFormGroup.bankCardNumber;
    }
}
