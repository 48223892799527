import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { CardType } from '../card-utils/card-type.enum';
import { BaseFormGroupComponent } from '../../../shared/payments/base-form-control/base-form-control.component';
import { AMEX_CVV_LENGTH, ALL_OTHER_CVV_LENGTH } from '../../../shared/constants/common.constants';

export type ControlNames = 'cvv';

@Component({
    selector: 'app-cvv-form-control',
    templateUrl: './cvv-form-control.component.html',
    styleUrls: [
        './cvv-form-control.component.scss',
        '../stylesheets/form-formatting.scss'
    ]
})
export class CvvFormControlComponent extends BaseFormGroupComponent<ControlNames> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    @Input() _cardType: CardType = CardType.INVALID;

    constructor(
        controlContainer: ControlContainer
    ) {
        super(controlContainer);
    }

    get cvv(): AbstractControl {
        return this.getControl('cvv');
    }

    get cvvErrorsDisplayable(): boolean {
        return this.errorsDisplayable('cvv');
    }

    get cardType(): CardType {
        return this._cardType;
    }

    @Input()
    set cardType(cardType: CardType) {
        this._cardType = cardType;
    }

    get labelText(): string {
        return this.cardType === CardType.AMERICAN_EXPRESS ? 'CID' : 'CVV';
    }

    get cvvLength(): number {
        return this.cardType === CardType.AMERICAN_EXPRESS ? AMEX_CVV_LENGTH : ALL_OTHER_CVV_LENGTH;
    }

    get helpText(): string {
        return this.cardType === CardType.AMERICAN_EXPRESS ?
            `
        For American Express, this 4-digit code appears on the
        front of the card, just above the credit card number.
      ` :
            `
        For Discover, Master Card and Visa, this 3-digit security code appears on
        the back of your credit card, after the last 4 digits of the credit card.
      `;
    }
}
