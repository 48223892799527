import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentfulData, ContentfulMapperService, IsbExperience } from '@nationwide/dgs-angular-billing-common';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';
import { environment } from '../../../../../../../environments/environment';
import { PaymentItem } from '../../../../../../billing/payment-preferences/shared/models/payment-item.model';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormControls } from '../../../../../../shared/payments/payment-form-controls';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { EasyPayAdapter } from '../../../../shared/adapters/easy-pay.adpater';
import { PaymentMethodsAdapter } from '../../../../shared/adapters/payment-methods.adapter';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
import { EditAutomaticPaymentLanding } from './edit-automatic-payments-landing.model';

import { ManageAccountValidationService } from '../../../../../../account-validation/manage-account-validation.service';
import { BillingCommonComponent } from '../../../../../../billing/billing-common/billing-common.component';
import { CommonValuesPaymentFormGroupModel } from '../../../../../../billing/model/common-values-payment-form-group.model';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { EasyPayAuthorizationAgreementService, EFTAuthorizationAgreementParams } from '../../../../../../shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { EftAuthorizationFlowService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-flow.service';
import { EftAuthorizationFormHelperService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form-helper.service';
import { EftAuthorizationFormValidationService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form-validation.service';
import { DistributedDocumentsResponseModel } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form/model/distributed-documents-response.model';
import { AccountPolicyHolderAddressElements } from './../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form/form-elements/account-policy-holder-address-elements';

@Component({
    selector: 'app-edit-automatic-payments-landing',
    templateUrl: './edit-automatic-payments-landing.component.html',
    styleUrls: ['./edit-automatic-payments-landing.component.scss'],
    providers: [EditAutomaticPaymentLanding]
})
export class EditAutomaticPaymentsLandingComponent extends BillingCommonComponent implements OnInit {
    @ViewChild(FormGroupDirective) editPaymentFormDirective: FormGroupDirective;
    declare accountValidationMessage: string;
    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.edit.landing;
    loadComplete = false;
    apiCallInProgress = true;
    paymentItem: PaymentItem;
    editPaymentForm: PaymentFormGroup;
    automaticPayments: IsbExperience.GetPayPlanResponse;
    emailAddress: string;
    isPaperlessEligible: boolean;
    paymentMethods: PaymentItem[];
    chosenPaymentMethod: PaymentItem;
    billingAccountNumber: string;
    billingAccountHolderName: string;
    amountDue: string;
    nextBillDueDate: Date;
    currentBillDueDate: Date;
    formValidated = false;
    automaticPaymentSuspended: boolean;
    isEFTBilled: boolean;
    isRBCBilled: boolean;
    isBilledStatus: boolean;
    billingStreet1: string;
    billingCity: string;
    billingState: string;
    NBP: boolean;
    isLifeOnlyAccount: boolean;
    declare distributedDocumentsResponseModel: DistributedDocumentsResponseModel;
    declare commonValuesPaymentFormGroupModel: CommonValuesPaymentFormGroupModel;
    canPayNow: boolean;
    esddaCheckBoxChecked = false;
    paperlessEsddaValidationError = false;
    vulErrorMessage: string;
    bankCardAutomaticPaymentsCapMessage: string;
    isAutoPayment = true;
    paymentPlan: string;
    isFullPay: boolean;

    // eslint-disable-next-line max-params
    constructor(
        router: Router,
        private activatedRoute: ActivatedRoute,
        session: SessionService,
        private urlUtil: UrlUtil,
        private easyPayAdapter: EasyPayAdapter,
        private landing: EditAutomaticPaymentLanding,
        private paymentPrefsApiService: PaymentPreferencesApiService,
        private editPaymentFormBuilder: ManagePaymentPrefsFormBuilder,
        private paymentMethodsAdapter: PaymentMethodsAdapter,
        private formUtils: FormUtil,
        private easternTime: EasternTimeDatePipe,
        eventService: BillingEventService,
        private paymentFormControls: PaymentFormControls,
        @Inject('window') private window: Window,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private editPaymentFlow: EditAutomaticPaymentFlowService,
        private manageAccountValidationService: ManageAccountValidationService,
        private easyPayAuthorizationAgreementService: EasyPayAuthorizationAgreementService,
        eftAuthorizationFormHelperService: EftAuthorizationFormHelperService,
        private eftAuthorizationFormValidationService: EftAuthorizationFormValidationService,
        eftAuthorizationFlowService: EftAuthorizationFlowService,
        readonly contentfulService: ContentfulMapperService,
        @Inject('logger') private logger: LoggerService
    ) {
        super(session, router, eftAuthorizationFormHelperService, eventService, eftAuthorizationFlowService);
    }

    ngOnInit(): void {
        this.session.currentPage = this.pageName;
        this.setVariablesFromApiResponse();
        this.fetchBankCardAutomaticPaymentsCapMessage();
    }

    setVariablesFromApiResponse(): void {
        try {
            this.nextSub = this.paymentPrefsApiService.editAutomaticPaymentLanding({
                callContentful: this.editPaymentFlow.hasCreditCardVulPolicyError
            }).subscribe((response) => {
                const { retrieveCustomerAgreement, internetRegistrationResponse, automaticPayments, contentful } = response;
                const billingAgreement = this.easyPayAdapter.getBillingAgreementFromRCA(this.easyPayAdapter.getAgreementsFromRCA(retrieveCustomerAgreement));
                this.automaticPayments = automaticPayments;
                this.NBP = this.session.isNBP;
                this.isLifeOnlyAccount = this.managePaymentPrefsAdapter.hasLifeOnlyBusiness(retrieveCustomerAgreement);
                this.isBilledStatus = this.easyPayAdapter.getBilledStatus(billingAgreement);
                this.isEFTBilled = this.easyPayAdapter.getEFTBilledStatus(billingAgreement);
                this.isRBCBilled = this.easyPayAdapter.getRBCBilledStatus(billingAgreement);
                this.amountDue = this.managePaymentPrefsAdapter.fetchBillingAccountAmountDue(retrieveCustomerAgreement);
                this.nextBillDueDate = this.easyPayAdapter.nextDueDate(retrieveCustomerAgreement) ? new Date(this.easyPayAdapter.nextDueDate(retrieveCustomerAgreement)) : null;
                this.currentBillDueDate = this.managePaymentPrefsAdapter.fetchBillingAccountAmountDueDate(retrieveCustomerAgreement);
                this.billingAccountHolderName = this.easyPayAdapter.getFullName(automaticPayments);
                this.billingAccountNumber = this.managePaymentPrefsAdapter.fetchBillingAccountNumber(retrieveCustomerAgreement);
                this.isPaperlessEligible = this.managePaymentPrefsAdapter.isEligibleForPaperless(internetRegistrationResponse);
                this.emailAddress = this.managePaymentPrefsAdapter.fetchMostCurrentEmail(retrieveCustomerAgreement, internetRegistrationResponse);
                this.paymentItem = this.easyPayAdapter.getPaymentItem(automaticPayments);
                this.automaticPaymentSuspended = this.easyPayAdapter.isRecurringPaymentSuspended(retrieveCustomerAgreement);
                const address: AccountPolicyHolderAddressElements = this.managePaymentPrefsAdapter.addressFromInternetRegistrationResponse(internetRegistrationResponse);
                this.billingStreet1 = address?.AcctPolHolderAddr1;
                this.billingCity = address?.AcctPolHolderCity;
                this.billingState = address?.AcctPolHolderState;
                this.paymentPlan = this.managePaymentPrefsAdapter.getPayPlanValueForEBI(retrieveCustomerAgreement);
                this.canPayNow = this.managePaymentPrefsAdapter.canPay(billingAgreement.roleInAgreement.permission);
                this.isFullPay = this.managePaymentPrefsAdapter.isAccountFullPay(retrieveCustomerAgreement);
                this.paymentMethods = this.fetchPaymentMethodOptions(this.session.isVUL);
                this.initSelectedPayMethod();
                this.setupEditPaymentForm();
                this.clearInputValues(automaticPayments);
                this.handleVulError(contentful);
                this.loadComplete = true;
                this.apiCallInProgress = false;
                this.sendOnPageLoadEbiEvent();
            });
        } catch (error) {
            this.logger.error('API ERROR: EDIT AUTOMATIC PAYMENTS LANDING PAGE', error);
        }
    }
    navigateToReview(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.EDIT.REVIEW(
                this.removeParamFromUrl(this.urlUtil.hashParamsString, 'review')
            )
        );
    }

    onEsddaCheckboxClick(value): void {
        this.esddaCheckBoxChecked = value;
        if (this.esddaCheckBoxChecked) {
            this.paperlessEsddaValidationError = false;
        }
    }

    getEmailAddress(): string {
        if (this.editPaymentForm.enrollInPaperless === 'no') {
            this.editPaymentFlow.paperlessEmail = this.emailAddress;
        }
        return this.editPaymentFlow.paperlessEmail || this.emailAddress;
    }

    removeParamFromUrl(urlParameters: string, parameterName: string): string {
        // eslint-disable-next-line no-negated-condition
        return urlParameters.includes(parameterName) ?
            urlParameters.replace('&fromPage=review', '') :
            urlParameters;
    }

    setupEditPaymentForm(): void {
        const fromPage: string = this.activatedRoute.snapshot.queryParams['fromPage'];
        if (this.editPaymentFlow.flowInPlay ||
            this.editPaymentFlow.hasCreditCardVulPolicyError && fromPage && fromPage === 'review') {
            this.editPaymentForm = this.editPaymentFormBuilder.setupEasyPayReconstructFormGroup({
                selectedPayMethod: this.editPaymentFlow.hasCreditCardVulPolicyError && fromPage === 'review' ?
                    this.editPaymentFlow.selectedPayMethod : this.selectedPaymentMethod,
                oldForm: this.editPaymentFlow.editAutomaticPaymentForm.group,
                hasSavedPayments: true
            });
        } else {
            this.editPaymentForm = this.editPaymentFormBuilder.newAutomaticPaymentFormGroup({
                selectedPayMethod: this.paymentItem,
                hasSavedPayments: true
            });
        }
        this.editPaymentFlow.editAutomaticPaymentForm = this.editPaymentForm;
    }

    onContinue(): void {
        this.formUtils.touchAllFormControls(this.editPaymentForm.group);
        this.formValidated = !!this.isAccountValidationReturnErrorCode;
        this.setDefaultPaymentMethodSelectionError(this.selectedPaymentMethod);
        this.removeRoutingNumberErrorForServiceDown();
        if (this.isEsddaValidationFailure()) {
            this.paperlessEsddaValidationError = this.isEsddaValidationFailure();
            if (this.paperlessEsddaValidationError) {
                this.logger.info('Edit Automatic Payments Page - Failed page validation due to ESDDA after continue button is clicked', {
                    accountNumber: this.session.billingAccountNumber,
                    enterpriseCustomerNumber: this.session.ecn,
                    paperlessBillingDeliveryResponse: this.editPaymentForm.enrollInPaperless
                });
            }
        }
        if (this.editPaymentForm.group.valid && !this.paperlessEsddaValidationError) {
            this.validateBankAccount();
            this.sendOnPaymentMethodEditEbiEvent();
        } else {
            this.window.scroll(0, 0);
        }
    }

    isEsddaValidationFailure(): boolean {
        return this.editPaymentForm.enrollInPaperless === 'yes' && !this.esddaCheckBoxChecked;
    }

    saveFormToFlow(): void {
        this.editPaymentFlow.save({
            editAutomaticPaymentForm: this.editPaymentForm,
            billedStatus: this.isEFTBilled || this.isRBCBilled || this.isBilledStatus,
            considerThisDisplayable: this.considerThisDisplayable,
            isPaperlessEligible: this.isPaperlessEligible,
            selectedPaperless: this.editPaymentForm.enrollInPaperless,
            emailAddress: this.emailAddress,
            selectedPayMethod: this.chosenPaymentMethod,
            billingAccountHolderName: this.billingAccountHolderName,
            billingAccountNumber: this.billingAccountNumber,
            amountDue: this.amountDue,
            currentBillDueDate: this.currentBillDueDate,
            nextBillDueDate: this.nextBillDueDate,
            currentBillingMethodType: this.automaticPayments.billingMethodType,
            automaticPaymentSuspended: this.automaticPaymentSuspended,
            billingStreet1: this.billingStreet1,
            billingCity: this.billingCity,
            billingState: this.billingState,
            distributedDocumentsResponseModel: this.distributedDocumentsResponseModel,
            authorizationAgreementParams: this.authorizationAgreementParams,
            isLifeOnlyAccount: this.isLifeOnlyAccount,
            canPayNow: this.canPayNow,
            hasCreditCardVulPolicyError: false,
            paymentPlan: this.paymentPlan
        });
    }

    initSelectedPayMethod(): void {
        this.chosenPaymentMethod = this.getSelectDefaultPayMethod();
    }

    getSelectDefaultPayMethod(): PaymentItem {
        // eslint-disable-next-line no-negated-condition
        return !this.session.referringPageUrl.includes('review') ? this.paymentMethods.find((method) => method.isPlaceholder) : this.paymentMethods.find((method) => method.equals(this.editPaymentFlow.selectedPayMethod));
    }

    cancelEditPayment(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString)
        );
    }

    clearInputValues(payPlan: IsbExperience.GetPayPlanResponse): void {
        const paymentMethodType = payPlan.paymentMethod.paymentMethodType;
        if (paymentMethodType === 'ElectronicFundsTransfer.PaymentMethod' && this.selectedPaymentMethod?.isBankAccount) {
            this.landing.accountNumber?.setValue('');
            this.landing.confirmAccountNumber?.setValue('');
        } else if (paymentMethodType === 'BankCard.PaymentMethod' && this.selectedPaymentMethod?.isBankCard) {
            this.landing.bankCardNumber?.setValue('');
        }
    }

    clearBankAcctInfo(response: any): void {
        if (!response.accepted) {
            this.landing.accountNumber?.setValue('');
            this.landing.confirmAccountNumber?.setValue('');
            this.landing.bankRoutingNumber?.setValue('');
            this.landing.bankAccountName?.setValue('');
        }
    }

    handleVulError(contentfulDataRes: ContentfulData): void {
        if (this.editPaymentFlow.hasCreditCardVulPolicyError) {
            this.vulErrorMessage = contentfulDataRes?.creditCardVulPolicyError.content;
        }
    }

    setInputValues(): void {
        const payorInfo = this.automaticPayments.paymentMethod.payorInfo;
        const eft = this.automaticPayments.paymentMethod.electronicFundsTransfer;
        const bankAccountType = eft ? this.paymentMethodsAdapter.retrieveBankAccountType(eft.bankAccountType) : '';
        const bankCard = this.automaticPayments.paymentMethod.bankCard;
        const [month, year] = bankCard ? bankCard.expirationDate.split('/') : [];
        const bankRoutingNumber = eft ? eft.bankRoutingNumber : '';
        const bankName = eft ? eft.bankName : '';
        if (this.paymentItem.isBankAccount && this.selectedPaymentMethod?.isBankAccount) {
            this.landing.bankRoutingNumber.setValue(bankRoutingNumber);
            this.landing.bankAccountType.setValue(bankAccountType);
            this.landing.bankAccountName.setValue(bankName);
        } else if (this.paymentItem.isBankCard && this.selectedPaymentMethod?.isBankCard) {
            this.landing.firstName.setValue(payorInfo.firstName);
            this.landing.lastName.setValue(payorInfo.lastName);
            this.landing.zipCode.setValue(payorInfo.postalCode);
            this.landing.cardExpirationMonth.setValue(month);
            this.landing.cardExpirationYear.setValue(year);
        }
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_LOAD_EDIT_AUTOMATIC_PAYMENTS_LANDING_PAGE, {
            paymentMethod: this.ebiPaymentMethodData,
            paymentPlan: this.paymentPlan || this.editPaymentFlow.paymentPlan
        });
    }

    sendOnPaymentMethodEditEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_EDIT_OF_PAYMENT_METHOD_ON_AUTOMATIC_PAYMENT_LANDING_PAGE, {
            paymentMethod: this.ebiPaymentMethodData,
            paymentPlan: this.paymentPlan || this.editPaymentFlow.paymentPlan
        });
    }

    removeRoutingNumberErrorForServiceDown(): void {
        if (this.selectedPaymentMethod?.isBankAccount) {
            if (this.routingNumberControl.errors &&
                this.routingNumberControl.errors.serviceDown) {
                this.routingNumberControl.setErrors(null);
            }
        }
    }

    fetchPaymentMethodOptions(hasVul: boolean): PaymentItem[] {
        const paymentItems: PaymentItem[] = this.paymentMethodsAdapter.fetchNewPaymentMethodOptions(
            {
                includeNewDebitCard: hasVul,
                includeNewBankCard: !hasVul,
                includeNewBankAccount: true,
                includePlaceholder: true,
                includeMasterpass: false
            });

        return paymentItems?.filter((paymentMethod) => !paymentMethod.isMasterPass);
    }

    determineNextAction(): void {
        this.commonValuesPaymentFormGroupModel = new CommonValuesPaymentFormGroupModel(this.editPaymentForm, this.selectedPaymentMethod, this.billingAccountHolderName);
        this.setAuthorizationAgreementParams(false);
        if (this.selectedPaymentMethod?.isPlaceholder) {
            this.isGlobalErrorMessageReqDefaultMethod = true;
        } else if (this.selectedPaymentMethod?.isBankAccount && this.NBP) {
            this.fetchEFTAuthForms();
        } else {
            this.saveFormToFlow();
            this.navigateToReview();
        }
    }

    fetchEFTAuthForms(): void {
        this.apiCallInProgress = true;
        this.nextSub = this.easyPayAuthorizationAgreementService.fetchEFTAuthFormsForNewlyEnrolled(
            <EFTAuthorizationAgreementParams> this.authorizationAgreementParams,
            this.agencyInformationResponseModel).subscribe((response) => {
                this.apiCallInProgress = false;
                this.distributedDocumentsResponseModel = response;
                if (this.eftAuthorizationFormValidationService.validateDistributedDocumentResponse(this.distributedDocumentsResponseModel)) {
                    this.setHPExtremeServiceResponseStatus(this.distributedDocumentsResponseModel.returnCode, false);
                    this.saveFormToFlow();
                    this.triggerEbiEventToLogNumberOfTimesHPExtremeServiceInvoke();
                    this.navigateToReview();
                } else {
                    this.modifyPaymentMethods();
                    this.setSelectedPaymentmethodValues();
                    this.setEFTAuthFormValidationValues();
                    this.sendIncompleteFormElementsOnEFTAuthFormEbiEvent();
                }
            }, (error: HttpErrorResponse) => {
                this.apiCallInProgress = false;
                this.logger.error('EFT Auth Form Failure - Edit Automatic Payments', error);
                this.logger.logCurrentContents();
                this.setHPExtremeServiceResponseStatus(this.getStausFromErrorResponse(error), true);
                this.triggerEbiEventToLogNumberOfTimesHPExtremeServiceInvoke();
                this.modifyPaymentMethods();
                this.setSelectedPaymentmethodValues();
                this.setEFTAuthFormValidationValues();
            });
    }

    validateAgentInformation(): void {
        this.easyPayAuthorizationAgreementService.fetchAgencyInformation()
            .subscribe((response) => {
                this.agencyInformationResponseModel = response;
                if (!this.eftAuthorizationFormValidationService.validateAgentDetails(this.agencyInformationResponseModel)) {
                    this.modifyPaymentMethods();
                    this.setEFTAuthFormValidationValues();
                    this.sendIncompleteFormElementsOnEFTAuthFormEbiEvent();
                }
                this.setSelectedPaymentmethodValues();
            }, (error) => {
                this.logger.error('Validate Agent Information Failure - Edit Automatic Payments', error);
                this.logger.logCurrentContents();
                this.modifyPaymentMethods();
                this.setSelectedPaymentmethodValues();
                this.eftAuthorizationFormHelperService.buildEBIValueAgentInformationServiceDown();
                this.sendIncompleteFormElementsOnEFTAuthFormEbiEvent();
                this.setEFTAuthFormValidationValues();
            });
    }

    modifyPaymentMethods(): void {
        if (this.paymentMethods && this.paymentMethods.length > 0) {
            this.paymentMethods = this.removeBankAccountPaymentMethods(this.paymentMethods);
            setTimeout(() => {
                this.chosenPaymentMethod = this.getNewBankCardPaymentMethod(this.paymentMethods);
                this.selectedPaymentMethod = this.chosenPaymentMethod;
            });
        }
    }
    retrieveBankCardType(cardName: string): string {
        return this.paymentMethodsAdapter.retrieveBankCardType(cardName);
    }
    get ebiPaymentMethodData(): PaymentMethodsData[] {
        const data = [];
        const payMethod = this.selectedPaymentMethod;
        const payPlan = this.automaticPayments;
        const accountType = payMethod.isBankAccount || !payMethod.isNewMethod && this.landing.bankAccountType?.value ? this.landing.bankAccountType.value : '';
        const bankName = this.editPaymentForm.bankName;
        const creditCardVendor = payMethod.isBankCard || !payMethod.isNewMethod && this.automaticPayments.paymentMethod?.bankCard ? this.editPaymentForm.cardType || this.retrieveBankCardType(payPlan.paymentMethod.bankCard.cardBrand) || this.automaticPayments.paymentMethod.bankCard.cardBrand : '';
        const paymentType = payMethod.isBankCard ? 'CC' : 'EFT';
        const paymentDate = this.billingPaymentDate;
        const nextPaymentAmount = this.amountDue;
        const stateEbi = this.editPaymentForm.state;
        data.push({
            accountType,
            bankName,
            creditCardVendor,
            paymentType,
            paymentDate,
            nextPaymentAmount,
            stateEbi
        });

        return data;
    }

    get billingPaymentDate(): string {
        return this.nextBillDueDate ? this.easternTime.transform(this.nextBillDueDate, 'MM/dd/yyyy') : 'NA';
    }

    get selectedPaymentMethod(): PaymentItem {
        return this.chosenPaymentMethod;
    }

    set selectedPaymentMethod(paymentMethod: PaymentItem) {
        this.chosenPaymentMethod = paymentMethod;
        if (this.selectedPaymentMethod && !this.selectedPaymentMethod?.isPlaceholder) {
            this.setSelectedPaymentmethodValues();
        }
        if (this.selectedPaymentMethod?.isBankAccount && this.NBP) {
            this.validateAgentInformation();
        }
    }

    setSelectedPaymentmethodValues(): void {
        this.editPaymentForm.group.removeControl('paymentMethodGroup');
        if (this.chosenPaymentMethod) {
            this.editPaymentForm.group.addControl(
                'paymentMethodGroup',
                this.paymentFormControls.newPaymentPreferencesPaymentMethodGroup(this.chosenPaymentMethod, {}, true, true)
            );
            this.editPaymentFlow.editAutomaticPaymentForm = this.editPaymentForm;
            this.formValidated = true;
            this.setInputValues();
        }
    }

    get globalErrorDisplayable(): boolean {
        return !this.formValidated && !this.editPaymentForm.group.valid &&
            this.submitted || this.isGlobalErrorMessageReqDefaultMethod;
    }

    get submitted(): boolean {
        return this.editPaymentFormDirective && this.editPaymentFormDirective.submitted;
    }

    get paymentMethodGroup(): AbstractControl {
        return this.editPaymentForm && this.editPaymentForm.group.get('paymentMethodGroup');
    }

    get considerThisGroup(): AbstractControl {
        return this.editPaymentForm && this.editPaymentForm.group.get('considerThisGroup');
    }

    get considerThisDisplayable(): boolean {
        return this.isPaperlessEligible;
    }

    get routingNumberControl(): AbstractControl {
        const paymentMethodGroup = <UntypedFormGroup> this.editPaymentForm.group.controls.paymentMethodGroup;
        const accountNumberGroup = <UntypedFormGroup>paymentMethodGroup.controls.accountNumberGroup;
        return accountNumberGroup.controls.routingNumber;
    }

    private validateBankAccount(): void {
        if (this.selectedPaymentMethod?.isNewBankAccount && this.manageAccountValidationService.isEnabled) {
            this.apiCallInProgress = true;
            this.nextSub = this.manageAccountValidationService.invokeAccountValidationService({
                firstName: this.editPaymentForm.firstName,
                lastName: this.editPaymentForm.lastName,
                bankAccountNumber: this.editPaymentForm.bankAccountNumber,
                bankRoutingNumber: this.editPaymentForm.bankRoutingNumber,
                bankAccountType: <any> this.editPaymentForm.bankAccountType,
                isPlaidEnabled: false,
                isAuthenticated: true
            }).subscribe(
                (response) => {
                    this.apiCallInProgress = false;
                    if (response.accepted) {
                        this.determineNextAction();
                    } else {
                        if (response.failureLimitReached) {
                            this.paymentMethods = this.paymentMethods.filter((method) => !method.isNewBankAccount);
                            this.chosenPaymentMethod = this.paymentMethods.find((method) => method.isPlaceholder);
                        }

                        if (!response.wrongAccountType) {
                            this.clearBankAcctInfo(response);
                        }

                        this.accountValidationMessage = response.errorMessage;
                        this.window.scroll(0, 0);
                    }
                },
                (error) => {
                    this.logger.error('API ERROR: EDIT AUTOMATIC PAYMENTS LANDING VALIDATE BANK ACCT', error);
                });
        } else {
            this.accountValidationMessage = null;
            this.determineNextAction();
        }
    }

    private fetchBankCardAutomaticPaymentsCapMessage(): void {
        const contentfulResponse = this.contentfulService.getContentfulBillingContent();
        contentfulResponse.subscribe((res) => {
            this.bankCardAutomaticPaymentsCapMessage = res.bankCardAutomaticPaymentsCapMessage.content;
        });
    }
}
