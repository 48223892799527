import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NFCIM, PBS } from '@nationwide/dgs-angular-billing-common';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { LIFE_LINE_OF_BUSINESS_LIST, LINE_OF_BUSINESS_LIST } from '../../../app/shared/constants/common.constants';
import { environment } from '../../../environments/environment';
import { QuickPayCommonEbiValues } from '../../quick-pay/shared/services/quick-pay/quick-pay-common-ebi-values.model';
import { QuickPaySearchValues } from '../../quick-pay/shared/services/quick-pay/quick-pay-search-values.model';
import { IsbSharedCommonHelperService } from '../isb-shared-common-helper.service';
import { CCAuthorizationAgreementParams, EFTAuthorizationAgreementParams } from '../payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { PingRedirectInfo } from '../service/ping-redirect/ping-redirect-info.model';
import { CredentialManagerService } from './identifiers/credential-management.service';
import { SessionNavigation } from './navigation/navigation.service';

@Injectable()
export class SessionService {
    referringPageUrl = '';
    sessionUniqueId = '';

    private _state: string;
    private _ipAddress: string;
    private _hasLifePolicy: boolean;
    private _lifeProductType: string;
    private _hasCyberLifePolicy: boolean;
    private _hasInitialized = false;
    private _initializationSubject = new ReplaySubject<boolean>(1);
    private _productType: string[];
    private _activePolicyNumbers: string[];
    private _activeProductTypes: string[];
    private _canViewBill = new BehaviorSubject<boolean>(false);
    private currentPageFromSession: string;
    private referringPageFromSession: string;
    private billingDetailsRouting: string;

    // eslint-disable-next-line max-params
    constructor(
        private sessionNavigation: SessionNavigation,
        private credentialManager: CredentialManagerService,
        private route: ActivatedRoute,
        private sharedService: IsbSharedCommonHelperService
    ) { }

    gotoLogin(): void {
        this.sessionNavigation.gotoLogin();
    }

    logOutUser(): void {
        this.sessionNavigation.logOutUser();
    }

    get authAggrementResponse():
        | EFTAuthorizationAgreementParams
        | CCAuthorizationAgreementParams {
        return (
            sessionStorage['authAggrementResponse'] &&
            JSON.parse(sessionStorage['authAggrementResponse'])
        );
    }

    set authAggrementResponse(
        model: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams
    ) {
        sessionStorage['authAggrementResponse'] = JSON.stringify(model);
    }
    get onInitialization(): Observable<boolean> {
        return this._initializationSubject;
    }

    get isBrowserBackAfterLogOut(): boolean {
        return this.sessionNavigation.isBrowserBackAfterLogOut;
    }

    get billingAccountNumber(): string {
        return (
            this.quickPayBillingAccountNumber ||
            this.credentialManager.billingAccountNumber
        );
    }

    get billingAccountNumberForRCA(): string {
        return this.credentialManager.billingAccountNumberForRCA;
    }

    setBillingAccountNumberToSession(billingAccountNumber: string): void {
        sessionStorage['BILLING_ACCOUNT_NUMBER'] = billingAccountNumber;
    }

    get policyNumber(): string {
        return this.credentialManager.policyNumber || this.quickPayPolicyNumber;
    }

    setPolicyNumberToSession(policyNumber: string): void {
        sessionStorage['POLICY_NUMBER'] = policyNumber;
    }

    setSessionIdToSession(sessionId: string): void {
        sessionStorage['camSessionId'] = sessionId;
    }

    getLifeProductType(): string {
        return this._lifeProductType;
    }

    setLifeProductType(val: string): void {
        this._lifeProductType = val;
    }

    get firstName(): string {
        return this.credentialManager.firstName;
    }
    get lastName(): string {
        return this.credentialManager.lastName;
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get userId(): string {
        return this.credentialManager.userId;
    }

    get authRealm(): string {
        return this.credentialManager.authRealm;
    }

    get a2aSessionId(): string {
        return this.credentialManager.a2aSessionId;
    }

    get accessToken(): string {
        return this.credentialManager.accessToken;
    }

    get idToken(): string {
        return this.credentialManager.idToken;
    }

    get partyRoleType(): string {
        return this.credentialManager.partyRoleType;
    }

    get currentBillPdfViewable(): boolean {
        return (
            this.route.snapshot.queryParams[
            environment.QUERY_PARAMS.currentBillPdfViewable
            ] === 'true'
        );
    }

    // for ebi purpose

    get ecn(): string {
        return this.credentialManager.ecn;
    }

    get guid(): string {
        return this.credentialManager.guid;
    }

    get productType(): string {
        return this.credentialManager.productType;
    }

    get aud(): string {
        return this.credentialManager.aud;
    }

    get ipAddress(): string {
        return this._ipAddress;
    }

    set ipAddress(data: string) {
        this._ipAddress = data;
    }

    get state(): string {
        return this._state;
    }

    set state(data: string) {
        this._state = data;
    }

    get sourceSystem(): string {
        return sessionStorage.getItem('SOURCE_SYSTEM');
    }

    set sourceSystem(data: string) {
        sessionStorage['SOURCE_SYSTEM'] = data;
    }

    get emailAddress(): string {
        return sessionStorage.getItem('emailAddress');
    }

    get paperlessExperience(): string {
        return sessionStorage.getItem('paperlessExperience');
    }

    get clearTrustToken(): string {
        return this.credentialManager.clearTrustSessionToken;
    }

    get token(): string {
        return this.credentialManager.clearTrustSessionToken;
    }

    get emailId(): string {
        return this.credentialManager.emailId || this.quickPayEmailId;
    }

    get policyNumbers(): string[] {
        return this.credentialManager.policyNumbers || [''];
    }

    set policyNumbers(numbers: string[]) {
        this.credentialManager.policyNumbers = numbers;
    }

    get policyNumbersRelatedToAccount(): string[] {
        return this.credentialManager.policyNumbersRelatedToAccount;
    }

    set policyNumbersRelatedToAccount(numbers: string[]) {
        this.credentialManager.policyNumbersRelatedToAccount = numbers;
    }

    get activePolicyNumbers(): string[] {
        return this._activePolicyNumbers || [];
    }

    set activePolicyNumbers(numbers: string[]) {
        this._activePolicyNumbers = numbers;
    }

    get activeProductTypes(): string[] {
        return this._activeProductTypes || [];
    }

    set activeProductTypes(products: string[]) {
        this._activeProductTypes = products;
    }

    get productTypes(): string[] {
        return this._productType || [''];
    }

    set productTypes(products: string[]) {
        this._productType = products;
    }

    get billingRouting(): string {
        return this.billingDetailsRouting;
    }

    set billingRouting(routing: string) {
        this.billingDetailsRouting = routing;
        sessionStorage['billing_details_routing'] = routing;
    }

    get currentPageFromSessionStorage(): string {
        return sessionStorage['CURRENT_PAGE'];
    }

    get currentPage(): string {
        return this.currentPageFromSession;
    }

    set currentPage(currentPageName: string) {
        this.currentPageFromSession = currentPageName;
        sessionStorage['CURRENT_PAGE'] = currentPageName;
    }

    get surveyKey(): string {
        return sessionStorage.getItem('W2_SurveyFlow');
    }

    set surveyKey(survey: string) {
        sessionStorage['W2_SurveyFlow'] = survey;
    }

    get dpimProducerType(): string {
        return sessionStorage.getItem('DPIM_PRODUCER_TYPE');
    }

    set dpimProducerType(dpimProducerType: string) {
        sessionStorage['DPIM_PRODUCER_TYPE'] = dpimProducerType;
    }

    get producerNumber(): string {
        return sessionStorage.getItem('PRODUCER_NUMBER');
    }

    set producerNumber(producerNumber: string) {
        sessionStorage['PRODUCER_NUMBER'] = producerNumber;
    }

    get referringPage(): string {
        return this.referringPageFromSession;
    }

    set referringPage(referringPageName: string) {
        this.referringPageFromSession = referringPageName;
        sessionStorage['REFERRING_PAGE'] = referringPageName;
    }

    setBillingDueDates(dueDates: any): void {
        sessionStorage['DUE_DATES'] = JSON.stringify(dueDates);
    }

    getBillingDueDates(): any {
        return JSON.parse(sessionStorage.getItem('DUE_DATES'));
    }

    set selectedDueDate(dueDate: string) {
        sessionStorage['SELECTED_DUE_DATE'] = dueDate;
    }

    get selectedDueDate(): string {
        return sessionStorage.getItem('SELECTED_DUE_DATE');
    }

    get payPlanEligibleFromPbsRule(): string {
        return sessionStorage.getItem('PAY_PLAN_ELIGIBLE_FROM_PBS_RULE');
    }

    set payPlanEligibleFromPbsRule(payPlanEligibleFromPbsRule: string) {
        sessionStorage['PAY_PLAN_ELIGIBLE_FROM_PBS_RULE'] =
            payPlanEligibleFromPbsRule;
    }

    intitialize(params: {
        rcaResponse?: PBS.RetriveCustomerAgreementResponse;
        nfPbs?: NFCIM.NfCustomerInformationManagementResponse;
        lineOfBusiness?: string;
    }): void {
        if (params.rcaResponse) {
            this._hasCyberLifePolicy = false;
            let agreements =
                params.rcaResponse.retrieveCustomerAgreementResponse.return.profile
                    .agreementGroup.agreement;
            agreements = agreements instanceof Array ? agreements : [agreements];
            for (const agreement of agreements) {
                if (agreement?.relatedAgreement?.refId) {
                    agreement.relatedAgreement.refId =
                        agreement.relatedAgreement.refId instanceof Array ? agreement.relatedAgreement.refId :
                            [agreement.relatedAgreement.refId];
                }
                if (
                    agreement.agreementType === 'BILLING' &&
                    (agreement.agreementNumber === this.billingAccountNumber ||
                        agreement.relatedAgreement?.refId?.includes(this.policyNumber))) {
                    this.state = agreement.placeOfIssue;
                    this.sourceSystem = agreement.system;
                }
                if (this.isLifeProduct(agreement) && agreement?.relatedAgreement?.refId?.includes(this.billingAccountNumber)) {
                    this._hasLifePolicy = true;
                    this._lifeProductType = agreement.lineOfBusiness;
                }
            }
            this.isOipaPolicy = this.containsOipaPolicy(agreements);
            this.hasCareMatters = this.containsCareMattersPolicy(agreements);
            this.billingSystem = this.sourceSystem;
        } else if (params.nfPbs) {
            this._hasLifePolicy = true;
            this._hasCyberLifePolicy = true;
            this.sourceSystem = 'CYBERLIFE';
        } else if (params.lineOfBusiness) {
            this.isOipaPolicy = this.containsOipaPolicy(params.lineOfBusiness);
            this.hasCareMatters = this.containsCareMattersPolicy(
                params.lineOfBusiness
            );
        }
        this._hasInitialized = true;
        this._initializationSubject.next(true);
    }

    isLifeProduct(agreement: any): boolean {
        return LIFE_LINE_OF_BUSINESS_LIST.includes(agreement?.lineOfBusiness);
    }

    canDisplayIDCardTile(agreements?: PBS.Agreement[]): boolean {
        const policyAgreement = agreements?.filter((agreement) => {
            agreement = this.sharedService.transformRefIdToArray(agreement);
            return agreement.agreementLabel?.equalsIgnoreCase('auto') &&
                agreement.status === 'Active' &&
                agreement.relatedAgreement?.refId?.includes(this.billingAccountNumber);
        });
        return this.sourceSystem === 'NBP' && policyAgreement?.length === 1;
    }

    canDisplayQuickPayIDCardTile(agreements: PBS.QuickPayAgreement[]): boolean {
        const relatedAgreements = agreements.filter((agreement) =>
            agreement.relatedAgreementObjects?.find(
                (relatedAgreements) =>
                    relatedAgreements.refId === this.billingAccountNumber
            )
        );
        const autoPoliciesCount = relatedAgreements.filter((agreement) =>
            agreement.agreementLabel.equalsIgnoreCase('auto')
        ).length;
        return this.sourceSystem === 'NBP' && autoPoliciesCount === 1;
    }

    containsCareMattersPolicy(paramValue: PBS.Agreement[] | string): boolean {
        let output = false;
        if (typeof paramValue == 'string') {
            output =
                paramValue &&
                LINE_OF_BUSINESS_LIST.CAREMATTERS.includes(paramValue.toUpperCase());
        } else {
            output = paramValue.some(
                (agreement) =>
                    agreement.agreementType !== 'BILLING' &&
                    agreement.lineOfBusiness &&
                    LINE_OF_BUSINESS_LIST.CAREMATTERS.includes(
                        agreement.lineOfBusiness.toUpperCase()
                    )
            );
        }
        return output;
    }

    containsOipaPolicy(paramValue: PBS.Agreement[] | string): boolean {
        let output = false;
        if (typeof paramValue == 'string') {
            output =
                paramValue &&
                LINE_OF_BUSINESS_LIST.OIPA.includes(paramValue.toUpperCase());
        } else {
            output = paramValue.some(
                (agreement) =>
                    agreement.agreementType !== 'BILLING' &&
                    agreement.lineOfBusiness &&
                    LINE_OF_BUSINESS_LIST.OIPA.includes(
                        agreement.lineOfBusiness.toUpperCase()
                    )
            );
        }
        return output;
    }

    setCyberLifeBillingSystem(): string {
        if (this.sourceSystem === 'CYBERLIFE') {
            this.billingSystem = 'NBP';
        }
        return this.billingSystem;
    }

    clearAccessToken(): void {
        sessionStorage.removeItem('jsonWebToken');
        sessionStorage.removeItem('accessToken');
    }

    clearPageInfo(): void {
        sessionStorage.removeItem('CURRENT_PAGE');
        sessionStorage.removeItem('REFERRING_PAGE');
    }

    get lifeProductType(): string {
        return this._lifeProductType;
    }

    get hasInitialized(): boolean {
        return this._hasInitialized;
    }

    get hasLifePolicy(): boolean {
        return this._hasLifePolicy;
    }

    get hasCyberLifePolicy(): boolean {
        return this._hasCyberLifePolicy;
    }

    isOwner(): boolean {
        return this.partyRoleType === 'Owner';
    }

    get isReinstatementEligible(): boolean {
        const isReinstatement = sessionStorage['REINSTATEMENT_ELIGIBLE'];
        return isReinstatement ? JSON.parse(isReinstatement) : false;
    }

    set isReinstatementEligible(isReinstatementEligible: boolean) {
        sessionStorage['REINSTATEMENT_ELIGIBLE'] = isReinstatementEligible;
    }

    get isCurrentPolicyContainedInJwt(): boolean {
        return (
            this.credentialManager.agreements.filter(
                (agreement) => agreement.agreement_number === this.policyNumber
            ).length > 0
        );
    }

    get isCurrentAccountContainedInJwt(): boolean {
        return (
            this.credentialManager.agreements.filter(
                (agreement) => agreement.agreement_number === this.billingAccountNumber
            ).length > 0
        );
    }

    set manageSavedPaymentMethodsId(id: string) {
        sessionStorage['MANAGE_SAVED_PAYMENT_METHODS_ID'] = id;
    }

    get manageSavedPaymentMethodsId(): string {
        return sessionStorage['MANAGE_SAVED_PAYMENT_METHODS_ID'];
    }

    get retrieveBillingAccounts(): any {
        if (this.credentialManager.agreements) {
            return this.credentialManager.agreements.filter(
                (agreement) => agreement.product_type === 'Billing'
            );
        } else {
            return null;
        }
    }

    get retrieveAllPolicies(): any {
        if (this.credentialManager.agreements) {
            return this.credentialManager.agreements.filter(
                (agreement) => agreement.product_type !== 'Billing'
            );
        } else {
            return null;
        }
    }

    get isCyberLifePolicyQuickPayFlow(): boolean {
        return (
            sessionStorage.getItem('isCyberLifePolicyQuickPayFlow') &&
            sessionStorage.getItem('isCyberLifePolicyQuickPayFlow') === 'true'
        );
    }

    set isCyberLifePolicyQuickPayFlow(isCyberLifePolicyQuickPayFlow: boolean) {
        sessionStorage['isCyberLifePolicyQuickPayFlow'] =
            isCyberLifePolicyQuickPayFlow;
    }

    get isPolicyLevelQuickPayPayment(): boolean {
        return sessionStorage['isPolicyLevelQuickPayPayment'] === 'true';
    }

    set isPolicyLevelQuickPayPayment(value: boolean) {
        sessionStorage['isPolicyLevelQuickPayPayment'] = value;
    }

    get isPayMidTermChange(): boolean {
        return sessionStorage['isPayMidTermChange'] === 'true';
    }

    set isPayMidTermChange(value: boolean) {
        sessionStorage['isPayMidTermChange'] = value;
    }

    get contextCacheContextId(): string {
        return sessionStorage.getItem('contextCacheContextId');
    }

    set contextCacheContextId(value: string) {
        sessionStorage['contextCacheContextId'] = value;
    }

    get payMidTermChangeAgreementNumber(): string {
        return sessionStorage.getItem('payMidTermChangeAgreementNumber');
    }

    set payMidTermChangeAgreementNumber(value: string) {
        sessionStorage['payMidTermChangeAgreementNumber'] = value;
    }

    get isLifeQuickPayFlagFromSaved(): boolean {
        return (
            sessionStorage.getItem('lifeQuickPayFlagFromSaved') &&
            sessionStorage.getItem('lifeQuickPayFlagFromSaved') === 'true'
        );
    }

    set isLifeQuickPayFlagFromSaved(lifeQuickPayFlagFromSaved: boolean) {
        sessionStorage['lifeQuickPayFlagFromSaved'] = lifeQuickPayFlagFromSaved;
    }

    get isSessionTimeOut(): boolean {
        return (
            sessionStorage.getItem('isSessionTimeOut') &&
            sessionStorage.getItem('isSessionTimeOut') === 'true'
        );
    }

    set isSessionTimeOut(isSessionTimeOut: boolean) {
        sessionStorage['isSessionTimeOut'] = isSessionTimeOut;
    }

    get pingRedirectInfo(): PingRedirectInfo {
        const redirectInfoString = sessionStorage['pingRedirectInfo'];
        let redirectInfo: PingRedirectInfo;
        if (redirectInfoString) {
            redirectInfo = JSON.parse(redirectInfoString);
        }
        return redirectInfo;
    }

    set pingRedirectInfo(redirectInfo: PingRedirectInfo) {
        sessionStorage['pingRedirectInfo'] = JSON.stringify(redirectInfo);
    }

    get quickPaySearchValues(): QuickPaySearchValues {
        let searchValues = {};
        if (sessionStorage.searchValues) {
            searchValues = JSON.parse(sessionStorage.searchValues);
        }
        return searchValues;
    }

    set quickPaySearchValues(searchValues: QuickPaySearchValues) {
        sessionStorage.setItem('searchValues', JSON.stringify(searchValues));
    }

    get quickPayAgreementNumber(): string {
        return sessionStorage['quickPayAgreementNumber'];
    }

    set quickPayAgreementNumber(quickPayAgreementNumber: string) {
        sessionStorage['quickPayAgreementNumber'] = quickPayAgreementNumber;
    }

    get billingAccountQuickPay(): string {
        return sessionStorage['billingAccount'];
    }

    set billingAccountQuickPay(billingAccountNumber: string) {
        sessionStorage['billingAccount'] = billingAccountNumber;
    }

    get billingAccountSourceSystem(): string {
        return sessionStorage['billingAccountSourceSystem'];
    }

    set billingAccountSourceSystem(billingAccountSourceSystem: string) {
        sessionStorage['billingAccountSourceSystem'] = billingAccountSourceSystem;
    }

    get quickPayPostPaymentAgreementNumber(): string {
        return sessionStorage['quickPayPostPaymentAgreementNumber'];
    }

    set quickPayPostPaymentAgreementNumber(
        quickPayPostPaymentAgreementNumber: string
    ) {
        sessionStorage['quickPayPostPaymentAgreementNumber'] =
            quickPayPostPaymentAgreementNumber;
    }

    get externalAppPageName(): string {
        return sessionStorage['externalAppPageName'];
    }

    set externalAppPageName(externalAppPageName: string) {
        sessionStorage['externalAppPageName'] = externalAppPageName;
    }

    get quickPayCommonEbiValues(): QuickPayCommonEbiValues {
        return (
            sessionStorage['quickPayCommonEbiValues'] &&
            JSON.parse(sessionStorage['quickPayCommonEbiValues'])
        );
    }

    set quickPayCommonEbiValues(
        quickPayCommonEbiValues: QuickPayCommonEbiValues
    ) {
        sessionStorage['quickPayCommonEbiValues'] = JSON.stringify(
            quickPayCommonEbiValues
        );
    }

    get billingSystem(): string {
        return sessionStorage.getItem('BILLING_SYSTEM');
    }

    set billingSystem(data: string) {
        sessionStorage['BILLING_SYSTEM'] = data;
    }

    get paymentMethod(): string {
        return sessionStorage.getItem('PAYMENT_METHOD');
    }

    set paymentMethod(data: string) {
        sessionStorage['PAYMENT_METHOD'] = data;
    }

    get quickPayEmailId(): string {
        return sessionStorage['EMAIL_ID'];
    }

    set quickPayEmailId(emailId: string) {
        sessionStorage['EMAIL_ID'] = emailId;
    }

    get isSplitPay(): boolean {
        return sessionStorage['isSplitPay'];
    }

    set isSplitPay(isSplitPay: boolean) {
        sessionStorage['isSplitPay'] = isSplitPay;
    }

    get splitPayAmount(): number {
        return sessionStorage['splitPayAmount'];
    }

    set splitPayAmount(splitPayAmount: number) {
        sessionStorage['splitPayAmount'] = splitPayAmount;
    }

    get primaryInsuredEmail(): string {
        return sessionStorage['primaryInsuredEmail'];
    }

    set primaryInsuredEmail(primaryEmail: string) {
        sessionStorage['primaryInsuredEmail'] = primaryEmail;
    }

    get secondaryPayerEmail(): string {
        return sessionStorage['secondaryPayerEmail'];
    }

    set secondaryPayerEmail(secondaryEmail: string) {
        sessionStorage['secondaryPayerEmail'] = secondaryEmail;
    }

    clear(): void {
        sessionStorage.clear();
    }

    private get quickPayPolicyNumber(): string {
        return this.isCyberLifePolicyQuickPayFlow ||
            this.isPolicyLevelQuickPayPayment ?
            this.quickPayAgreementNumber : '';
    }

    private get quickPayBillingAccountNumber(): string {
        return this.isCyberLifePolicyQuickPayFlow ?
            '' : this.quickPayAgreementNumber;
    }

    get pageNameOnBackButtonClick(): string {
        return sessionStorage['pageNameOnBackButtonClick'];
    }

    set pageNameOnBackButtonClick(data: string) {
        sessionStorage['pageNameOnBackButtonClick'] = data;
    }

    get isOipaPolicy(): boolean {
        return (
            sessionStorage['isOipaPolicy'] &&
            sessionStorage['isOipaPolicy'] === 'true'
        );
    }

    set isOipaPolicy(data: boolean) {
        sessionStorage['isOipaPolicy'] = data;
    }

    get hasCareMatters(): boolean {
        return (
            sessionStorage['hasCareMatters'] &&
            sessionStorage['hasCareMatters'] === 'true'
        );
    }

    set hasCareMatters(data: boolean) {
        sessionStorage['hasCareMatters'] = data;
    }

    get dpimServicePlusIndicator(): string {
        return sessionStorage['dpimServicePlusIndicator'];
    }

    set dpimServicePlusIndicator(data: string) {
        sessionStorage['dpimServicePlusIndicator'] = data;
    }

    get dpimAgentPhoneNumber(): string {
        return sessionStorage['dpimAgentPhoneNumber'];
    }

    set dpimAgentPhoneNumber(data: string) {
        sessionStorage['dpimAgentPhoneNumber'] = data;
    }

    get isNBP(): boolean {
        return this.billingSystem === 'NBP';
    }

    get isSAP(): boolean {
        return this.billingSystem === 'SAP Billing';
    }

    get isVUL(): boolean {
        return this._lifeProductType === LINE_OF_BUSINESS_LIST.VUL;
    }

    get isWithInISBApp(): boolean {
        return (
            this.credentialManager.appName && this.credentialManager.appName === 'ISB'
        );
    }

    clearPaymentSessionVariables(): void {
        if (sessionStorage.getItem('DUE_DATES')) {
            sessionStorage.removeItem('DUE_DATES');
        }
        this.isLifeQuickPayFlagFromSaved = null;
        this.isReinstatementEligible = null;
        this.pageNameOnBackButtonClick = null;
    }

    onViewBillChange(canViewBill): void {
        this._canViewBill.next(canViewBill);
    }

    get canViewBilling(): Observable<boolean> {
        return this._canViewBill;
    }

    set isCollectionPayment(value: boolean) {
        sessionStorage['IS_COLLECTION'] = value;
    }

    get isCollectionPayment(): boolean {
        return (
            sessionStorage['IS_COLLECTION'] &&
            sessionStorage['IS_COLLECTION'] === 'true'
        );
    }

    get paymentPlanType(): string {
        return sessionStorage.getItem('PAYMENT_PLAN_TYPE');
    }

    set paymentPlanType(data: string) {
        sessionStorage['PAYMENT_PLAN_TYPE'] = data;
    }

    get payMidTermChangeLog(): any {
        return JSON.parse(sessionStorage.getItem('MIDTERM_CHANGE_LOG'));
    }

    set payMidTermChangeLog(midTermLogData: any) {
        sessionStorage['MIDTERM_CHANGE_LOG'] = JSON.stringify(midTermLogData);
    }

    get increaseReftAutomaticPaymentFlag(): string {
        return sessionStorage['increaseAutoPayFlag'];
    }

    set increaseReftAutomaticPaymentFlag(value: string) {
        sessionStorage['increaseAutoPayFlag'] = value;
    }
}
