import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentItem } from '../../../billing/payment-preferences/shared/models/payment-item.model';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardType } from '../card-utils/card-type.enum';
import { WaysToPayModalComponent } from '../../../pay-bill/ways-to-pay/ways-to-pay-modal/ways-to-pay-modal.component';
import { BankCardPaymentItem } from '../../../billing/payment-preferences/shared/models/bankcard-item.model';
import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';

@Component({
    selector: 'app-payment-method-section',
    templateUrl: './payment-method-section.component.html',
    styleUrls: [
        './payment-method-section.component.scss',
        '../../../shared/payments/stylesheets/form-formatting.scss'
    ]
})
export class PaymentMethodSectionComponent implements OnInit {
    waysToPayModal: NgbModalRef;
    cardType: CardType = CardType.INVALID;
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    _selectedPaymentMethod: PaymentItem;
    paymentMethodIcons = new Map<string, { src: string; alt: string }>();

    @Input() hasDefaultMethod = false;
    @Input() hasSavedPaymentMethod = false;
    @Output() selectedPaymentMethodChange: EventEmitter<PaymentItem>;

    @Input() set selectedPaymentMethod(paymentMethod: PaymentItem) {
        this._selectedPaymentMethod = paymentMethod;
        this.setSelectedCardType();
        this.selectedPaymentMethodChange.emit(paymentMethod);
    }
    get selectedPaymentMethod(): PaymentItem {
        return this._selectedPaymentMethod;
    }

    constructor(
        private modalService: NgbModal,
        private contentfulMapperService: ContentfulMapperService
    ) {
        this.selectedPaymentMethodChange = new EventEmitter<PaymentItem>();
    }

    ngOnInit(): void {
        this.contentfulMapperService.getContentfulBillingContent().subscribe((response) => {
            const bankCardImages = response.billingBankCardIcons?.Images || [];
            const bankAccountImages = response.billingAccountIcon?.Images || [];
            this.mapIconImages([...bankCardImages, ...bankAccountImages]);
        });
    }

    openWaysToPayModal(): void {
        this.waysToPayModal = this.modalService.open(WaysToPayModalComponent, {
            keyboard: false, // So ESC key doesn't dismiss modal
            fullscreen: true,
            scrollable: true,
            windowClass: 'ways-to-pay-modal'
        });

        this.waysToPayModal.result.then((selectedPaymentMethod) => {
            if (selectedPaymentMethod !== 'back') {
                this.selectedPaymentMethodChange.emit(selectedPaymentMethod);
            }
        });
    }

    get selectPayMethodButtonText(): string {
        let selectPayMethodButtonText = 'Change payment method';

        if (this.selectedPaymentMethod?.isPlaceholder) {
            if (!this.hasDefaultMethod && this.hasSavedPaymentMethod) {
                selectPayMethodButtonText = 'Select payment method';
            } else {
                selectPayMethodButtonText = 'Add payment method';
            }
        }

        return selectPayMethodButtonText;
    }

    setSelectedCardType(): void {
        if (this.selectedPaymentMethod?.isBankCard) {
            this.cardType = (<BankCardPaymentItem> this.selectedPaymentMethod.item).cardType;
        }
    }

    getPaymentMethodImageSrc(): string {
        const paymentMethodType = this._selectedPaymentMethod.isBankCard ? this.cardType : 'bank';
        return this.paymentMethodIcons?.get(paymentMethodType)?.src;
    }

    getPaymentMethodImageAltText(): string {
        const paymentMethodType = this._selectedPaymentMethod.isBankCard ? this.cardType : 'bank';
        return this.paymentMethodIcons?.get(paymentMethodType)?.alt;
    }

    mapIconImages(contentfulIconsResponse: any): void {
        this.paymentMethodIcons.set(CardType.VISA, {
            src: contentfulIconsResponse?.find((icon) => icon.fields?.id === 'visa')?.fields?.URL?.fields?.file?.url,
            alt: 'Visa logo'
        });
        this.paymentMethodIcons.set(CardType.MASTERCARD, {
            src: contentfulIconsResponse?.find((icon) => icon.fields?.id === 'mastercard')?.fields?.URL?.fields?.file?.url,
            alt: 'Mastercard logo'
        });
        this.paymentMethodIcons.set(CardType.DISCOVER, {
            src: contentfulIconsResponse?.find((icon) => icon.fields?.id === 'discover')?.fields?.URL?.fields?.file?.url,
            alt: 'Discover logo'
        });
        this.paymentMethodIcons.set(CardType.AMERICAN_EXPRESS, {
            src: contentfulIconsResponse?.find((icon) => icon.fields?.id === 'amex')?.fields?.URL?.fields?.file?.url,
            alt: 'American Express logo'
        });
        this.paymentMethodIcons.set('bank', {
            src: contentfulIconsResponse?.find((icon) => icon.fields?.id === 'checkingSavings')?.fields?.URL?.fields?.file?.url,
            alt: 'Bank Account icon'
        });
    }
}
