import { AfterViewChecked, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthEmitterService, OAuthEvent } from '@nationwide/angular-oauth-module';
import {
    TimelineService,
    PersonalBillingRequestParams,
    PersonalBillingConfiguration,
    PbsService
} from '@nationwide/dgs-angular-billing-common';
import {
    Timeline,
    TimelineEntry,
    TimelineAdapter
} from '@nationwide/angular-timeline';
import { Observable, of, Subscription, concatMap, map, mergeMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoggerService } from '../shared/logger/logger.service';
import { SessionService } from '../shared/session/session.service';
import { BasePageComponent } from '../shared/base/base-page.component';
import { SessionClearingComponent } from '../shared/mixins/session-clearing.component';
import { PageName } from '../shared/page-name.enum';
import { TIMELINE_RESPONSE_INDEX } from '../shared/constants/common.constants';
import { applyMixins } from '../shared/mixins/apply-mixin';
import { APICommonService } from '@nationwide/core-component-library-base';
import { AbandonmentSurvey } from '../shared/service/abandonment-survey.service';

@Component({
    selector: 'app-billing-timeline-widget',
    templateUrl: './billing-timeline-widget.component.html',
    styleUrls: ['./billing-timeline-widget.component.scss']
})
export class BillingTimelineWidgetComponent extends BasePageComponent
    implements SessionClearingComponent, OnInit, OnDestroy, AfterViewChecked {
    pageName = PageName.BILLING_TIMELINE_EXTERNAL;
    canLoadApplication = false;
    loadComplete = false;

    isLife: boolean;

    timeline: Timeline;
    visibleEntry: TimelineEntry;
    billingHistoryUrl: string;

    hasError: boolean;
    loggerContentsEmptied: boolean;
    oAuthSub: Subscription;

    onLinkClick: (linkMap: { url: string }) => void;

    // eslint-disable-next-line max-params
    constructor(
        router: Router,
        public elementRef: ElementRef,
        private oauthEmitterService: OAuthEmitterService,
        public session: SessionService,
        @Inject('logger') private logger: LoggerService,
        private timelineAdapter: TimelineAdapter,
        private timelineService: TimelineService,
        private personalBillingConfiguration: PersonalBillingConfiguration,
        private sessionService: SessionService,
        private pbsService: PbsService,
        public abandonmentSurvey: AbandonmentSurvey,
        private apiCommon: APICommonService
    ) {
        super(session, router);
    }

    ngAfterViewChecked(): void {
        if (this.loadComplete && !this.loggerContentsEmptied) {
            this.logger.logCurrentContents();
            this.loggerContentsEmptied = true;
        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.billingHistoryUrl = '';
        this.isLifePolicyAttachedToBillingAccount();
        if (this.isOAuthRequired) {
            this.startAuthServiceCallStopwatch();
            this.nextSub = this.oauthEmitterService.oauthEmitter.subscribe((event) =>
                this.handleOAuthEvent(event)
            );
        } else {
            this.canLoadApplication = true;
            this.fetchTimeline();
        }
    }

    isLifePolicyAttachedToBillingAccount(): void {
        this.pbsService.retrieveCustomerProfile({
            agreement: [
                {
                    agreementNumber: this.billingAccountNumber,
                    agreementType: 'BILLING',
                    system: 'NBP'
                }
            ],
            sessionId: this.sessionService.a2aSessionId,
            accessToken: this.sessionService.accessToken,
            transactionId: this.apiCommon.generateTransactionId()
        }).pipe(
            concatMap((nbpPbsResponse) => {
                const profile = nbpPbsResponse?.retrieveCustomerPortfolioResponse?.return?.profile;
                const hasAgreementArray = Array.isArray(profile?.agreementGroup?.agreement);
                const billingAgreement = hasAgreementArray ?
                    profile?.agreementGroup?.agreement.find((agreement) => agreement?.agreementNumber === this.billingAccountNumber) :
                    profile?.agreementGroup?.agreement;

                return billingAgreement?.responseCode?.code === '0404' ?
                    this.pbsService.retrieveCustomerProfile({
                        agreement: [
                            {
                                agreementNumber: this.billingAccountNumber,
                                agreementType: 'BILLING',
                                system: 'SAP Billing'
                            }
                        ],
                        sessionId: this.sessionService.a2aSessionId,
                        accessToken: this.sessionService.accessToken,
                        transactionId: this.apiCommon.generateTransactionId()
                    }) :
                    of(nbpPbsResponse);
            })
        ).subscribe((rcpResponse) => {
            const agreementList = rcpResponse?.retrieveCustomerPortfolioResponse?.return?.profile?.agreementGroup?.agreement || [];
            this.isLife = agreementList.findIndex((agreement) => agreement?.agreementLabel === 'Life') > -1;
        }, (error) => {
            this.isLife = false;
            this.logger.error('Timeline Widget: PBS call failure', error);
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        window.scrollTo(0, 0);
    }

    private handleOAuthEvent(event: OAuthEvent): void {
        if (event.type === 'OAuthSuccess') {
            this.stopAuthServiceCallStopwatch({ wasSuccessful: true });
        } else if (event.type === 'OAuthFailure') {
            this.stopAuthServiceCallStopwatch({ wasSuccessful: false });
            this.logger.error('EUA FAILURE', event.description);
        } else if (event.type === 'OAuthPending') {
            this.logger.debug('EUA REQUEST', event.description);
        }

        if (
            (event.type === 'OAuthSuccess' || event.type === 'OAuthNotNecessary') &&
            !this.canLoadApplication
        ) {
            this.canLoadApplication = true;
            this.fetchTimeline();
        } else if (
            event.type === 'OAuthFailure' &&
            this.session.isBrowserBackAfterLogOut
        ) {
            this.session.gotoLogin();
        } else if (event.type === 'OAuthFailure') {
            this.canLoadApplication = true;
            this.hasError = true;
            this.loadComplete = true;
        }
    }

    private fetchTimeline(): void {
        this.retrieveTimeline(
            this.getPersonalBillingRequestParams()
        ).subscribe(
            (timeline: Timeline) => {
                this.timeline = timeline;
                if (timeline.currentBill) {
                    this.visibleEntry = timeline.currentBill;
                }
                this.loadComplete = true;
                this.hasError = false;
            },
            (error: any) => {
                this.loadComplete = true;
                this.hasError = true;
                this.logger.error('API ERROR: OCCURRED IN FETCH TIMELINE', { error: error.stack || error });
            }
        );
    }

    private retrieveTimeline(
        personalBillingRequestParams: PersonalBillingRequestParams):
        Observable<Timeline> {
        const REFUND_STATUS_OUTER_ARRAY_INDEX = 0; // refunds api response has an arbitrary outer array with only one index
        return this.timelineService.retrieveTimeline(personalBillingRequestParams).pipe(
            map((responses: Array<any>) => ({
                bills: responses[0][TIMELINE_RESPONSE_INDEX.BILL_INDEX].bills,
                relatedPolicies: responses[TIMELINE_RESPONSE_INDEX.RELATED_POLICY_INDEX].relatedPolicies,
                policyEvents: responses[TIMELINE_RESPONSE_INDEX.POLICY_EVENT_INDEX].policyEvents,
                transactions: responses[TIMELINE_RESPONSE_INDEX.TRANSACTION_INDEX].transactions,
                accounts: responses[TIMELINE_RESPONSE_INDEX.ACCOUNT_INDEX],
                zeroAmountDueBalances: responses[TIMELINE_RESPONSE_INDEX.ZERO_AMOUNT_DUE_INDEX],
                refundStatuses: responses[TIMELINE_RESPONSE_INDEX.REFUND_STATUSES][REFUND_STATUS_OUTER_ARRAY_INDEX].refunds
            })),
            mergeMap((responses) =>
                this.timelineAdapter.transform({
                    bills: responses.bills,
                    relatedPolicies: responses.relatedPolicies,
                    policyEvents: responses.policyEvents,
                    transactions: responses.transactions,
                    accountOverview: responses.accounts,
                    zeroAmountDueBalances: responses.zeroAmountDueBalances,
                    refundStatuses: responses.refundStatuses,
                    isProd: environment.PRODUCTION
                })
            )
        );
    }

    private getPersonalBillingRequestParams(): PersonalBillingRequestParams {
        const personalBillingRequestParams: PersonalBillingRequestParams = {
            accountNumber: this.billingAccountNumber, accessToken: this.accessToken,
            isZeroAmountDueServiceEnable: this.zeroAmountDueFlag
        };

        return personalBillingRequestParams;
    }

    get billingAccountNumber(): string {
        return sessionStorage.getItem('BILLING_ACCOUNT_NUMBER');
    }

    get accessToken(): string {
        const tokenStorage: string = environment.OAUTH_CONFIG.tokenStorage;
        const accessToken = sessionStorage.getItem(tokenStorage);
        return accessToken ? accessToken.split(',')[0] : '';
    }

    get zeroAmountDueFlag(): boolean {
        return true;
    }

    private startAuthServiceCallStopwatch(): void {
        if (!this.oauthEmitterService.inDescendantIframe()) {
            this.logger.startStopWatchFor('AUTHENTICATION SERVICE CALL');
        }
    }

    private stopAuthServiceCallStopwatch(params: {
        wasSuccessful: boolean;
    }): void {
        if (!this.oauthEmitterService.inDescendantIframe()) {
            this.logger.finishStopWatchFor('AUTHENTICATION SERVICE CALL', params);
        }
    }

    get paymentUrl(): string {
        return environment.ISB.ENDPOINTS.RELATIVE.payBillLanding({
            accountNumber: this.billingAccountNumber,
            sessionId: this.sessionService.a2aSessionId
        });
    }

    get isOAuthRequired(): boolean {
        return this.personalBillingConfiguration.isAccessTokenRequired;
    }
}

applyMixins(BillingTimelineWidgetComponent, [SessionClearingComponent]);
