import { AbstractControl, ControlContainer } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/naming-convention
export abstract class BaseFormGroupComponent<ControlNames extends string | null, OptionalControlNames extends string | null = null> {
    constructor(
        private controlContainer: ControlContainer
    ) { }

    get formGroup(): AbstractControl {
        return this.controlContainer.control;
    }

    getControl(controlName: ControlNames | OptionalControlNames): AbstractControl {
        return this.formGroup.get(controlName);
    }

    errorsDisplayable(controlName: ControlNames | OptionalControlNames): boolean {
        const control = this.getControl(controlName);
        return control.invalid && control.touched;
    }
}
