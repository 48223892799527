import { Injectable } from '@angular/core';
import { PBS, IsbExperience } from '@nationwide/dgs-angular-billing-common';
import { AuthIdentifier } from '@nationwide/angular-oauth-module/components/config/auth-identifier.model';
import { QuickPayServerSideErrorMessageCode } from '../../../quick-pay-server-side-error-message-code.enum';
import { QUICK_PAY_CONSTANTS } from '../../../quick-pay.constants';
import { QuickPaySearchFields, QuickPayFlowService } from '../quick-pay-flow/quick-pay-flow.service';
import { QuickPayEBIData, QuickPayPickerEBIData } from '../../../../shared/service/billing-api-common.service';
import { environment } from '../../../../../environments/environment';
import { ISBRouteParams } from '../../../../shared/routing/isb-route.model';
import { SessionService } from '../../../../shared/session/session.service';
import { NBP } from '../../../../../app/shared/constants/common.constants';
import { PaymentFlowType } from '../../../../pay-bill/shared/payment-flow-type.service';
import { QuickPayResponseModel } from '../../../domain/quick-pay-response.model';
import { QuickPayImageData } from '../../../model/quick-pay-image-data';
import { PolicyDisplayInfo } from '../../../model/policy-display-info';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Injectable()
export class QuickPayHelperService {
    constructor(
        private quickPayFlowService: QuickPayFlowService,
        private sessionService: SessionService,
        private paymentFlowType: PaymentFlowType
    ) {

    }

    /* eslint-disable @typescript-eslint/naming-convention */
    imagesMapper: {} = {
        auto: 'car-outline.svg',
        property: 'home-outline.svg',
        condo: 'home-outline.svg',
        home: 'home-outline.svg',
        life: 'lifeinsurance-outline.svg',
        fixedlife: 'lifeinsurance-outline.svg',
        umbrella: 'umbrella-outline.svg',
        tenant: 'renters-outline.svg',
        renters: 'renters-outline.svg',
        'renters/tenant': 'renters-outline.svg',
        'motorcycle/misc vehicle': 'boatsmall-outline.svg',
        powersports: 'boatsmall-outline.svg',
        boat: 'boatsmall-outline.svg',
        rv: 'boatsmall-outline.svg'
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    convertDateFormat(date: string): string {
        if (!date) {
            return '';
        }
        const dateParts = date.split('/');
        const dateLength = 3;
        if (dateParts.length === dateLength) {
            const [month, day, year] = date.split('/');
            return `${month?.padStart(2, '0')}/${day?.padStart(2, '0')}/${year}`;
        } else {
            const [month, day, year] = date.split('-');
            return `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`;
        }
    }

    getFormattedDate(dateValue: Date, format: string): string {
        return dayjs.utc(dateValue).format(format);
    }

    isRelatedAgreementObjectsArrayGreaterThanZero(selectedAgreement: PBS.QuickPayAgreement): boolean {
        return selectedAgreement.relatedAgreementObjects && selectedAgreement.relatedAgreementObjects.length > 0;
    }

    isRelatedAgreementArrayGreaterThanZero(agreement: PBS.QuickPayAgreement): boolean {
        return agreement.relatedAgreements && agreement.relatedAgreements.length > 0;
    }

    getBillingDetail(relatedAgreementObject: PBS.QuickPayAgreement): PBS.QuickPayBillingDetail {
        return relatedAgreementObject.billingAccountDetail &&
            relatedAgreementObject.billingAccountDetail.billingDetail;
    }

    buildServerSideErrorMessage(responseCode: number, inputErrorMessage?: string): string {
        let errorMessage: string;
        switch (responseCode) {
            case QuickPayServerSideErrorMessageCode.UNAUTHORIZED:
            case QuickPayServerSideErrorMessageCode.ZIP_INCORRECT:
            case QuickPayServerSideErrorMessageCode.DOB_INCORRECT:
            case QuickPayServerSideErrorMessageCode.NO_RECORDS_FOUND:
                errorMessage = inputErrorMessage || QUICK_PAY_CONSTANTS.INCORRECT_FIELDS_SERVER_ERROR_MESSAGE;
                break;
            case QuickPayServerSideErrorMessageCode.CODE_407:
                errorMessage = QUICK_PAY_CONSTANTS.CODE_407_ERROR_MESSAGE;
                break;
            case QuickPayServerSideErrorMessageCode.CODE_408:
                errorMessage = QUICK_PAY_CONSTANTS.CODE_408_ERROR_MESSAGE;
                break;
            case QuickPayServerSideErrorMessageCode.CANCELLED:
            case QuickPayServerSideErrorMessageCode.INELIGIBLE:
                errorMessage = QUICK_PAY_CONSTANTS.CANCELLED_OR_INELIGIBLE_STATUS_ERROR_MESSAGE;
                break;
            case QuickPayServerSideErrorMessageCode.SERVER_ERROR:
            case QuickPayServerSideErrorMessageCode.ECIF_ERROR:
            case QuickPayServerSideErrorMessageCode.SEARCH_PARTY_ERROR:
            case QuickPayServerSideErrorMessageCode.RETRIEVE_PARTY_ERROR:
            case QuickPayServerSideErrorMessageCode.RAD_SERVICE_ERROR:
            case QuickPayServerSideErrorMessageCode.PARSE_ERROR:
            default:
                errorMessage = QUICK_PAY_CONSTANTS.BACKEND_SERVICE_ISSUE_ERROR_MESSAGE;
                break;
        }
        return errorMessage;
    }

    formatDobForAuth(dob: string): string {
        if (!dob) {
            return '';
        }
        const dateParts = dob.split('/');
        const dateLength = 3;
        if (dateParts.length === dateLength) {
            const [month, day, year] = dob.split('/');
            return `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`;
        } else {
            const [month, day, year] = dob.split('-');
            return `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`;
        }
    }

    createAuthIdentifiers(searchParams: QuickPaySearchFields): AuthIdentifier[] {
        const authIdentifiers: AuthIdentifier[] = [
            {
                name: 'postalCode',
                value: searchParams.zipCode
            },
            {
                name: 'dateOfBirth',
                value: this.formatDobForAuth(searchParams.dob)
            }
        ];

        if (searchParams.agreementNumber) {
            authIdentifiers.push({
                name: 'agreementNumber',
                value: searchParams.agreementNumber
            });
            const isUnmaskedAgreementNumber = /^\d+$/.test(searchParams.agreementNumber);
            if (searchParams.contextId && searchParams.otp && !isUnmaskedAgreementNumber) {
                authIdentifiers.push({
                    name: 'contextId',
                    value: searchParams.contextId
                });
                authIdentifiers.push({
                    name: 'otp',
                    value: searchParams.otp
                });
            }
        } else if (searchParams.phoneNumber) {
            authIdentifiers.push({
                name: 'phoneNumber',
                value: searchParams.phoneNumber
            });
        }

        return authIdentifiers;
    }

    isYearValueDiffBetweenHideAndUnHideDOB(hideValueSplit: string[], unHideValueSplit: string[]): boolean {
        return hideValueSplit && unHideValueSplit &&
            hideValueSplit.length > 2 && unHideValueSplit.length > 2 &&
            hideValueSplit[2] && unHideValueSplit[2] &&
            hideValueSplit[2] !== unHideValueSplit[2];
    }

    getConcatenatedDate(month: string, date: string, year: string): string {
        return `${month}/${date}/${year}`;
    }

    hideValue(dobValue: string): string {
        return dobValue.replace(/^[0-9]{1,2}\/[0-9]{1,2}/g, '**/**');
    }

    splitValue(value: string): string[] {
        return value.split('/');
    }

    isMaskNotRequiredKeyCode(event: any): boolean {
        return event.keyCode !== QUICK_PAY_CONSTANTS.DELETE_KEY_CODE &&
            event.keyCode !== QUICK_PAY_CONSTANTS.BACKSPACE_KEY_CODE;
    }

    isMinimumLengthForMask(value: string): boolean {
        const sixChar = 6;
        return value.length < sixChar;
    }

    getQuickPayPickerPageRadioButtonLabel(agreement: PBS.QuickPayAgreement): string {
        let radioButtonLabel = agreement.agreementType && agreement.agreementType.equalsIgnoreCase(QUICK_PAY_CONSTANTS.BILLING) ?
            QUICK_PAY_CONSTANTS.BILLING : agreement.agreementLabel;
        const agreementNumber = this.isAlliedPolicy(agreement) ? agreement.refId : agreement.agreementNumber;
        const lastFourDigits = 4;
        radioButtonLabel = `${radioButtonLabel} (...${agreementNumber.slice(Math.max(0, agreementNumber.length - lastFourDigits))})`;
        return radioButtonLabel;
    }

    constructPolicyDisplayInfoListPolicyAgreement(agreement: PBS.QuickPayAgreement): PolicyDisplayInfo[] {
        const policyDisplayInfoArray: PolicyDisplayInfo[] = [];
        const agreementLabel = agreement.agreementLabel;
        if (agreementLabel) {
            const imageUrl: string = this.getImageUrl(this.replaceWhiteSpaceWithEmpty(agreementLabel.toLowerCase()));
            policyDisplayInfoArray.push({
                agreementLabel,
                displayText: this.getPolicyDisplayLabel(agreement),
                imageUrl
            });
        }
        return policyDisplayInfoArray;
    }

    getPolicyDisplayLabel(agreement: PBS.QuickPayAgreement): string {
        let policyDisplayLabel = agreement.agreementLabel;
        const agreementNumber = this.isAlliedPolicy(agreement) ? agreement.refId : agreement.agreementNumber;
        const lastFourDigits = 4;
        policyDisplayLabel = `${policyDisplayLabel} (...${agreementNumber.slice(Math.max(0, agreementNumber.length - lastFourDigits))})`;
        return policyDisplayLabel;
    }

    formatDobForEBI(dob: string): string {
        if (!dob) {
            return '';
        }
        const dateParts = dob.split('/');
        const dateLength = 3;
        if (dateParts.length === dateLength) {
            const [month, day, year] = dob.split('/');
            return `${month.padStart(2, '0')}${day.padStart(2, '0')}${year}`;
        } else {
            const [month, day, year] = dob.split('-');
            return `${month.padStart(2, '0')}${day.padStart(2, '0')}${year}`;
        }
    }

    getQuickPayEbiValues(responseStatus: string, description: string): QuickPayEBIData {
        const zipCode = this.quickPayFlowService.zipCode || '';
        const responseCode = this.quickPayFlowService.responseCode || NaN;
        const stateOfIssue = this.quickPayFlowService.stateOfIssue;
        const accountSize = this.quickPayFlowService.payableAccountSize;
        let dob = null;
        if (this.quickPayFlowService.dob) {
            dob = this.formatDobForEBI(this.quickPayFlowService.dob);
        }
        const age = this.quickPayFlowService.age;
        const policyNumber = this.quickPayFlowService.isAgreementFlow ? this.quickPayFlowService.agreementNumber : 'Unknown';
        const phoneNumber = this.quickPayFlowService.isPhoneNumberFlow ? this.quickPayFlowService.phoneNumber : 'Unknown';
        const clientType = 'Unauthenticated';
        let ecn = 'Unknown';
        if (this.quickPayFlowService.pbsResponse && this.quickPayFlowService.pbsResponse.ecn) {
            ecn = this.quickPayFlowService.pbsResponse.ecn;
        }
        const quickPayEBIData: QuickPayEBIData = {
            clientType, zipCode, age, dob, policyNumber, phoneNumber, responseCode, responseStatus, description, accountSize, stateOfIssue, ecn
        };
        return quickPayEBIData;
    }

    buildQuickPayPickerEBIData(): QuickPayPickerEBIData[] {
        const quickPayPickerEBIDataArray: QuickPayPickerEBIData[] = [];
        if (this.quickPayFlowService.pbsResponse &&
            this.quickPayFlowService.pbsResponse.payableAccounts) {
            this.quickPayFlowService.pbsResponse.payableAccounts.forEach((agreement) => {
                const quickPayPickerEBIData: QuickPayPickerEBIData = {
                    agreementNumber: this.isAlliedPolicy(agreement) ? agreement.refId : agreement.agreementNumber,
                    agreementLabel: agreement.agreementLabel,
                    agreementType: agreement.agreementType
                };
                quickPayPickerEBIDataArray.push(quickPayPickerEBIData);
            });
        }
        return quickPayPickerEBIDataArray;
    }

    replaceWhiteSpaceWithEmpty(text: string): string {
        let result: string;
        if (text) {
            result = text.replace(/\s/g, '');
        }
        return result;
    }
    clearSessionForQuickPayFlowValues(): void {
        if (sessionStorage.getItem('isCyberLifePolicyQuickPayFlow')) {
            sessionStorage.removeItem('isCyberLifePolicyQuickPayFlow');
        }
        if (sessionStorage.getItem('lifeQuickPayFlagFromSaved')) {
            sessionStorage.removeItem('lifeQuickPayFlagFromSaved');
        }
    }

    removeSessionTimeOutFlag(): void {
        sessionStorage.removeItem('isSessionTimeOut');
    }

    isSytemAllowOnlyPolicyNumberPostPayment(): boolean {
        return QUICK_PAY_CONSTANTS.ONLY_ALLOW_POLICY_NUMBER_SYSTEM_POST_PAYMENT_SERVICE.includes(
            this.sessionService.sourceSystem
        );
    }

    isAlliedPolicy(agreement: PBS.QuickPayAgreement): boolean {
        return !this.isCyberlifePolicy(agreement.system) &&
            this.replaceWhiteSpaceWithEmpty(agreement.agreementNumber) !== this.replaceWhiteSpaceWithEmpty(agreement.refId);
    }

    isCyberlifePolicy(system: string): boolean {
        return QUICK_PAY_CONSTANTS.CYBER_LIFE === system.toUpperCase();
    }

    getProductLine(): string {
        return this.buildProductLine();
    }

    getEbiSourceSystem(): string {
        return this.sessionService.isCyberLifePolicyQuickPayFlow ? 'CL' :
            this.sessionService.sourceSystem;
    }

    extractNumberOfCharacters(sourceSystem: string, numberOfCharExtract: number): string {
        return sourceSystem.length > numberOfCharExtract ? sourceSystem.slice(0, numberOfCharExtract) : sourceSystem;
    }

    getQuickPaySearchPageUrl(lifeQuickPay: boolean, quickPayUrlParams: ISBRouteParams): string {
        let url = environment.ISB.ENDPOINTS.QUICK_PAY.quickPaySearch(quickPayUrlParams);
        if (lifeQuickPay) {
            url = `${url}&lob=life`;
        }
        return url;
    }

    getQuickPayAgreementNumber(): string {
        return this.sessionService.isCyberLifePolicyQuickPayFlow || this.sessionService.isPolicyLevelQuickPayPayment ?
            this.quickPayFlowService.quickPayResponseModel.policyNumber :
            this.quickPayFlowService.quickPayResponseModel.billingAccountNumber;
    }

    setQuickPayAccountInformation(): void {
        if (this.sessionService?.isCyberLifePolicyQuickPayFlow) {
            this.sessionService.billingAccountQuickPay = this.quickPayFlowService?.quickPayResponseModel?.policyNumber;
            this.sessionService.billingAccountSourceSystem = 'CYBERLIFE';
        } else {
            this.sessionService.billingAccountQuickPay = this.quickPayFlowService?.quickPayResponseModel?.billingAccountNumber;
            this.sessionService.billingAccountSourceSystem = this.quickPayFlowService?.quickPayResponseModel?.billingAgreement?.system;
        }
    }

    quickPayPostPaymentAgreementNumber(): string {
        return this.isSytemAllowOnlyPolicyNumberPostPayment() || this.sessionService.isPolicyLevelQuickPayPayment ?
            this.quickPayFlowService.quickPayResponseModel.policyNumber :
            this.quickPayFlowService.quickPayResponseModel.billingAccountNumber;
    }

    getBillingSystem(): string {
        return this.sessionService.isCyberLifePolicyQuickPayFlow ? NBP :
            this.sessionService.sourceSystem;
    }

    initializePayBillStateValues(responseModel: QuickPayResponseModel): void {
        this.paymentFlowType.cyberLifePolicyQuickPayFlow = responseModel.system;
        this.paymentFlowType.sapBillingQuickPayFlow = responseModel.system;
        this.paymentFlowType.cyberLifePolicyNumber = responseModel.policyNumber;

        this.quickPayFlowService.quickPayResponseModel = responseModel;

        this.sessionService.sourceSystem = responseModel.system;
        this.sessionService.isPolicyLevelQuickPayPayment = responseModel.agreementType && responseModel.agreementType.equalsIgnoreCase('policy');
        this.sessionService.isCyberLifePolicyQuickPayFlow = this.paymentFlowType.isCyberLifePolicyQuickPayFlow;
        this.sessionService.billingSystem = this.paymentFlowType.isCyberLifePolicyQuickPayFlow ? NBP : responseModel.billingAgreement?.system;
        this.sessionService.quickPayAgreementNumber = this.getQuickPayAgreementNumber();
        this.setQuickPayAccountInformation();
        this.sessionService.quickPayPostPaymentAgreementNumber = this.quickPayPostPaymentAgreementNumber();
        this.sessionService.quickPayEmailId = responseModel.emailAddress;
    }

    private buildProductLine(): string {
        let productLine: string;

        if (this.quickPayFlowService.quickPayResponseModel) {
            const lineOfBusiness = this.quickPayFlowService.quickPayResponseModel.lifePolicy ? 'life' :
                this.quickPayFlowService.pbsResponse.lineOfBusiness;
            if (lineOfBusiness) {
                if (lineOfBusiness.toLowerCase().includes('personal')) {
                    productLine = QUICK_PAY_CONSTANTS.PERSONAL;
                } else if (lineOfBusiness.toLowerCase().includes('business')) {
                    productLine = QUICK_PAY_CONSTANTS.COMMERCIAL;
                } else if (lineOfBusiness.toLowerCase().includes('life')) {
                    productLine = QUICK_PAY_CONSTANTS.LIFE;
                } else {
                    productLine = '';
                }
            }
        }
        return productLine;
    }

    getQuickPayEcn(): string {
        return this.quickPayFlowService.pbsResponse?.ecn || this.getEcnFromJWT() || '';
    }

    getQuickPayBillingAccountNumber(): string {
        return this.quickPayFlowService.quickPayResponseModel.billingAccountNumber;
    }

    getEcnFromJWT(): string {
        const jwt = sessionStorage.getItem('jsonWebToken');
        const decodedToken = <any>(jwtDecode(jwt));
        return decodedToken['sub'].split(':')[1];
    }

    getPayBillConfirmationNumber(postPayment: IsbExperience.PostPaymentResponse): string {
        let num = '';
        if (postPayment) {
            num = postPayment.receiptId || postPayment.paymentId || postPayment.transactionId;
        }
        return num?.replace(/^0+/, '');
    }

    getPolicyDisplayInfoList(policiesOnBillingAgreement: string[]): PolicyDisplayInfo[] {
        const policyDisplayInfoArray: PolicyDisplayInfo[] = [];
        const allowedDisplayPolicySize = 2;
        const lastFourDigits = 4;
        policiesOnBillingAgreement?.sort();
        for (let i = 0; i < policiesOnBillingAgreement?.length; i++) {
            if (i === allowedDisplayPolicySize) {
                break;
            }

            const agreementNumber: string = policiesOnBillingAgreement[i];
            const agreementLabel: string = agreementNumber?.split('-', 1)[0];

            let displayText = `${agreementLabel} (...${agreementNumber?.slice(Math.max(0, agreementNumber?.length - lastFourDigits))})`;
            if (policiesOnBillingAgreement?.length > allowedDisplayPolicySize && i === allowedDisplayPolicySize - 1) {
                const remainingPolicies: number = policiesOnBillingAgreement?.length - allowedDisplayPolicySize;
                displayText = `${displayText} & ${remainingPolicies} more`;
            }
            if (agreementLabel) {
                const imageUrl: string = this.getImageUrl(agreementLabel.toLowerCase());

                policyDisplayInfoArray.push({
                    agreementLabel,
                    displayText,
                    imageUrl
                });
            }
        }
        return policyDisplayInfoArray;
    }

    getImageUrl(agreementLabel: any): string {
        const iconUrls = this.quickPayFlowService.isbProductIcons;
        return iconUrls ? iconUrls[this.imagesMapper[agreementLabel]] : '';
    }

    mapQuickPayImages(contentfulImages: any): any {
        const quickPayImageData: { [key: string]: QuickPayImageData } = {};
        QUICK_PAY_CONSTANTS.IMAGE_NAME_LIST.forEach((imageName) => {
            quickPayImageData[imageName] = this.constructQuickPayImageData(contentfulImages, imageName);
        });
        return quickPayImageData;
    }

    private constructQuickPayImageData(contentfulImages: any, imageName: string): QuickPayImageData {
        const imageObject: any = contentfulImages[imageName];
        return imageObject ? {
            title: imageObject.URL?.fields?.title,
            width: imageObject.URL?.fields?.file?.details?.image?.width,
            height: imageObject.URL?.fields?.file?.details?.image?.height,
            url: imageObject.URL?.fields?.file?.url
        } : null;
    }
}
