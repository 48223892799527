import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { AddPaymentMethodFlowService } from '../services/add-payment-method-flow.service';
import { AddPaymentMethodConfirmationService } from './add-payment-confirmation.service';
import { PaymentMethodConfirmationDetail } from '../../../shared/components/saved-payment-confimation-details/saved-payment-confimation-details.component';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { CtmEventType, CtmProductType } from '../../../../../../shared/service/ctm-service/ctm-model';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { WARNING_MESSAGES } from '../../../../shared/constants/warning-messages';
import { RefundEnrollmentStatus } from '../review/add-payment-method-review.service';
import { PaymentItem } from '../../../../shared/models/payment-item.model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { PostSavedPaymentMethodResponse, SavedPaymentMethod } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience';
import {
    PBS
} from '@nationwide/dgs-angular-billing-common';
import { LINE_OF_BUSINESS_LIST } from '../../../../../../shared/constants/common.constants';
import { PaperlessConfirmationMessage } from '../../../../shared/models/paperless-confirmation-message';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

@Component({
    selector: 'app-add-payment-method-confirmation',
    templateUrl: './add-payment-method-confirmation.component.html',
    styleUrls: ['./add-payment-method-confirmation.component.scss']
})
export class AddPaymentMethodConfirmationComponent implements OnInit, OnDestroy {
    loadComplete: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _successMessage: string;
    formattedCurrentDate: string;
    paperlessConfirmationMessage: PaperlessConfirmationMessage;
    agreements: PBS.Agreement[];
    displayVulCreditCardMessage = false;
    isRCACallProcessed = false;
    // eslint-disable-next-line max-params
    constructor(
        private paymentPreferencesState: PaymentPreferencesStateService,
        private router: Router,
        private addPaymentFlow: AddPaymentMethodFlowService,
        private addPaymentConfirmationService: AddPaymentMethodConfirmationService,
        private eventService: BillingEventService,
        @Inject('window') private window: Window,
        private urlUtil: UrlUtil,
        private session: SessionService,
        @Inject('logger') private logger: LoggerService,
        private easternTimeDatePipe: EasternTimeDatePipe
    ) { }

    ngOnInit(): void {
        this.setFormattedCurrentDate();
        this.paperlessConfirmationMessage = this.addPaymentConfirmationService.getPaperlessFlowMessages(
            this.addPaymentFlow.enrolledInPaperless, this.addPaymentFlow.paperlessEnrollmentAttempted, this.addPaymentFlow.savedPaperlessEsddaPrefs);
        if (this.addPaymentFlow.isPaperlessEligible) {
            let logMessage = 'Add Payment Method Confirmation - Page load';
            if (this.addPaymentConfirmationService.paperlessEnrollmentError(this.paperlessConfirmationMessage)) {
                logMessage = 'Add Payment Method Confirmation- Page load with error message';
            }
            this.logger.info(logMessage, this.addPaymentConfirmationService.logPageLoadForPaperless(this.addPaymentFlow.addPaymentForm.enrollInPaperless));
            this.logger.logCurrentContents();
        }
        if (this.addPaymentFlow.flowInPlay) {
            this.ebiCtmEventManager();
            this.fetchAgreements();
            this.loadComplete = true;
        } else {
            this.navigateToPaymentPreferences();
        }
        this.logPaperlessSuccessfulEnrollment();
    }

    ngOnDestroy(): void {
        this.addPaymentFlow.clear();
    }

    secondayNavigation(metaData: ButtonMetaData): void {
        if (metaData.label === 'Account Summary') {
            this.navigateToCAM();
        } else {
            this.router.navigateByUrl(metaData.navigation);
        }
    }

    setFormattedCurrentDate(): void {
        const dateFormat = 'MM/dd/yyyy';
        const timeFormat = 'hh:mm aa';
        const date = this.easternTimeDatePipe.transform(new Date(), dateFormat);
        const time = this.easternTimeDatePipe.transform(new Date(), timeFormat);
        this.formattedCurrentDate = `${date}, ${time} ET`;
    }

    onContinue(): void {
        if (this.addPaymentMethodError) {
            this.navigateToPaymentPreferences();
        } else if (this.selectedEasyPay) {
            this.navigateToEasyPay();
        } else {
            this.navigateToPaymentPreferences();
        }
    }

    showPrintDialog(): void {
        this.sendPrintButtonEbiEvent();
        this.window.print();
    }

    navigateToCAM(): void {
        this.window.location.href = environment.CAM_HOME;
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString));
    }

    get wasPreferenceEsddaEnrollmentSuccessful(): boolean {
        return this.selectedPaperless && this.addPaymentFlow.addPaymentMethodServiceResponses?.esddaPreferences === true;
    }

    navigateToPaperless(): void {
        this.window.location.href = environment.NW_HEADER.preferences;
    }

    navigateToEasyPay(): void {
        if (this.selectedPaperless) {
            this.paymentPreferencesState.autoCheckPaperless = true;
        }
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.SETUP.LANDING(this.urlUtil.hashParamsString));
    }

    sendOnPageLoadEbiEvents(): void {
        this.eventService.handleEvent(EBIEventType.PP_ADD_PAYMENT_METHOD_CONFIRMATION_LOAD, {
            responseCode: `${this.addPaymentFlow.apiResponseCode}`,
            responseStatus: this.apiCallFailed ? 'ERROR' : 'SUCCESS'
        });

        this.saveAsRefundMethodResponses.forEach((billingAccount) => {
            const accountItem = this.addPaymentFlow.billingAccountsForRefund.find((account) => account.accountNumber === billingAccount.accountNumber);
            this.eventService.handleEvent(EBIEventType.ON_CONFIRMATION_LOAD_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD, {
                responseCode: `${billingAccount.responseCode}`,
                responseStatus: billingAccount.successful ? 'SUCCESS' : 'ERROR',
                currentChangeType: accountItem.changeTypeForEbi,
                IDENTIFIER: billingAccount.accountNumber
            });
        });
    }

    sendPrintButtonEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.PP_ADD_PAYMENT_CONFIRMATION_PRINT);
    }

    sendOnApiFailEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.PP_ADD_PAYMENT_CONFIRMATION_HARDFALL);
    }

    sendAddPayMethodSuccessCtmEvent(): void {
        this.eventService.handleCtmEvent(CtmEventType.PP_ADD_PAYMENT_METHOD, {
            productType: CtmProductType.BILLING
        });
    }

    ebiCtmEventManager(): void {
        if (!this.addPaymentMethodError) {
            this.sendAddPayMethodSuccessCtmEvent();
            this.sendOnPageLoadEbiEvents();
            // eslint-disable-next-line no-negated-condition
        } else if (!this.apiCallFailed) {
            this.sendOnPageLoadEbiEvents();
        } else {
            this.sendOnApiFailEbiEvent();
        }
    }

    fetchAgreements(): void {
        this.addPaymentConfirmationService.fetchAgreements(
        ).subscribe(
            (response) => {
                this.agreements = response?.retrieveCustomerAgreementResponse?.return?.profile?.agreementGroup?.agreement;
                this.checkBothVulAndNonVul(this.agreements);
                this.isRCACallProcessed = true;
            },
            (error) => {
                if (error instanceof Error) {
                    this.router.navigateByUrl('/personal/error/system-down');
                } else {
                    this.loadComplete = true;
                }
                this.isRCACallProcessed = true;
            }
        );
    }

    checkBothVulAndNonVul(agreements: PBS.Agreement[]): void {
        const vulAndNonVul = {
            VUL: false,
            NONVUL: false
        };
        let areVulAndNonVul = false;
        for (const agreement of agreements) {
            if (agreement) {
                if (agreement.agreementType !== 'BILLING' &&
                    agreement.status && agreement.status.toUpperCase() === 'ACTIVE') {
                    if (agreement.lineOfBusiness.includes(LINE_OF_BUSINESS_LIST.VUL)) {
                        vulAndNonVul.VUL = true;
                    }
                    if (!agreement.lineOfBusiness.includes(LINE_OF_BUSINESS_LIST.VUL)) {
                        vulAndNonVul.NONVUL = true;
                    }
                }
                if (vulAndNonVul.VUL && vulAndNonVul.NONVUL) {
                    areVulAndNonVul = true;
                    break;
                }
            }
        }

        if (!this.addPaymentMethodError &&
            this.isShowVulCreditCardMessage(areVulAndNonVul) && this.bankCardFromApiResponse?.cardType === 'Credit') {
            this.displayVulCreditCardMessage = true;
        }
    }

    private logPaperlessSuccessfulEnrollment(): void {
        if (this.wasPreferenceEsddaEnrollmentSuccessful) {
            this.logger.info('user enrolled in paperless successfully through add payment method flow');
        }
    }

    private isShowVulCreditCardMessage(areVulAndNonVul: boolean): boolean {
        return areVulAndNonVul ? true : this.session.isVUL;
    }

    get secondaryButton(): ButtonMetaData {
        const buttonMetaData: ButtonMetaData = {
            label: 'Account Summary',
            navigation: environment.CAM_HOME
        };
        if (this.selectedEasyPay || this.selectedPaperless) {
            buttonMetaData.label = 'Payment Prefences';
            buttonMetaData.navigation = environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString);
        }
        return buttonMetaData;
    }

    get selectedEasyPay(): boolean {
        return this.addPaymentFlow.selectedEasyPay === 'yes';
    }

    get selectedPaperless(): boolean {
        return this.addPaymentFlow.selectedPaperless === 'yes';
    }

    get paymentMethod(): PaymentItem {
        return this.addPaymentFlow.selectedPaymentMethod;
    }

    get paymentType(): string {
        return this.addPaymentConfirmationService.paymentType;
    }

    get maskedNumber(): string {
        return this.addPaymentConfirmationService.maskedNumber;
    }

    get addPaymentConfirmationDetail(): PaymentMethodConfirmationDetail {
        return {
            paymentType: this.paymentType,
            maskedNumber: this.maskedNumber
        };
    }

    get upNextLabel(): string {
        return this.addPaymentConfirmationService.upNextLabel;
    }

    get unexpectedNavigationEncountered(): boolean {
        return !this.addPaymentFlow.flowInPlay;
    }

    get apiCallFailed(): boolean {
        return this.addPaymentConfirmationService.apiCallFailed;
    }

    get apiServiceDownMessage(): string {
        return WARNING_MESSAGES.API_FAILURE;
    }

    get paymentMethodAlreadyExists(): boolean {
        return this.addPaymentConfirmationService.paymentMethodAlreadyExists;
    }

    get paymentMethodLimitReached(): boolean {
        return this.addPaymentConfirmationService.paymentMethodLimitReached;
    }

    get addPaymentMethodError(): boolean {
        return this.addPaymentConfirmationService.addPaymentMethodError;
    }

    get bankCardFromApiResponse(): SavedPaymentMethod['bankCard'] | undefined {
        // eslint-disable-next-line no-extra-parens
        return (<PostSavedPaymentMethodResponse> this.addPaymentFlow.addPaymentMethodServiceResponses.savedPaymentMethodResponse)?.savedPaymentMethod?.bankCard;
    }

    get vulCCMessageTridionContent(): { content: string; variables: { [x: string]: string } } {
        return {
            content: this.addPaymentFlow.addPaymentMethodServiceResponses.tridion.creditCardVulDisclaimer.content,
            variables: {
                ccLastFour: this.bankCardFromApiResponse.ccLastFour
            }
        };
    }

    get saveAsRefundMethodResponses(): RefundEnrollmentStatus[] {
        const responses = this.addPaymentFlow.addPaymentMethodServiceResponses &&
            this.addPaymentFlow.addPaymentMethodServiceResponses.saveAsRefundMethodResponses;
        return responses || [];
    }

    get successfulRefundMethodEnrollments(): string[] {
        return this.saveAsRefundMethodResponses.filter((res) => res.successful).map((res) => res.accountNumber);
    }

    get unsuccessfulRefundMethodEnrollments(): string[] {
        return this.saveAsRefundMethodResponses.filter((res) => !res.successful).map((res) => res.accountNumber);
    }

    get isPaperlessEnrollmentUnsuccessful(): boolean {
        return this.addPaymentConfirmationService.paperlessEnrollmentError(this.paperlessConfirmationMessage);
    }

    get errorMessage(): string {
        return this.addPaymentConfirmationService.errorMessage;
    }
}
export interface ButtonMetaData {
    label: string;
    navigation: string;
}
