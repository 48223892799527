import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';
import { environment } from '../../../../../../../environments/environment';
import { PaymentFlowType } from '../../../../../../pay-bill/shared/payment-flow-type.service';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { ISBRouteParams } from '../../../../../../shared/routing/isb-route.model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { WARNING_MESSAGES } from '../../../../shared/constants/warning-messages';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
import { EditAutomaticPaymentConfirmationService } from './edit-automatic-payments-confirmation.service';

import { SafeCurrencyPipe } from '../../../../../../pay-bill/shared/safe-currency-pipe';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { CtmEventType, CtmProductType } from '../../../../../../shared/service/ctm-service/ctm-model';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { PaymentItem } from '../../../../../payment-preferences/shared/models/payment-item.model';
import { PaperlessConfirmationMessage } from '../../../../shared/models/paperless-confirmation-message';

@Component({
    selector: 'app-edit-automatic-payments-confirmation',
    templateUrl: './edit-automatic-payments-confirmation.component.html',
    styleUrls: ['./edit-automatic-payments-confirmation.component.scss']
})
export class EditAutomaticPaymentsConfirmationComponent extends BasePageComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _successMessage: string;
    amountDue: number;
    amountDueDate: string;
    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.edit.confirmation;
    formattedCurrentDate: string;
    paperlessConfirmationMessage: PaperlessConfirmationMessage;
    effectiveDateAutomatic = 'Automatic payment change effective date:';
    effectiveDatePaperless = 'Paperless enrollment change effective date:';

    // eslint-disable-next-line max-params
    constructor(
        private paymentFlowType: PaymentFlowType,
        private easternTimeDatePipe: EasternTimeDatePipe,
        private safeCurrencyPipe: SafeCurrencyPipe,
        router: Router,
        session: SessionService,
        private editPaymentFlow: EditAutomaticPaymentFlowService,
        private editAutomaticPaymentConfirmationService: EditAutomaticPaymentConfirmationService,
        private urlUtil: UrlUtil,
        private eventService: BillingEventService,
        @Inject('window') private window: Window,
        @Inject('logger') private logger: LoggerService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        this.session.currentPage = this.pageName;
        this.setFormattedCurrentDate();
        this.setVariablesFromApiResponse();
        this.sendOnPageLoadEbiEvent();
        this.sendEditAutoPaySuccessCtmEvent();
        this.paperlessConfirmationMessage = this.editAutomaticPaymentConfirmationService.getPaperlessFlowMessages(
            this.editPaymentFlow.enrolledInPaperless, this.editPaymentFlow.paperlessEnrollmentAttempted,
            this.editPaymentFlow.savedPaperlessEsddaPrefs);
        if (this.editPaymentFlow.isPaperlessEligible) {
            let logMessage = 'Edit Automatic Payment Confirmation - Page load';
            if (this.editAutomaticPaymentConfirmationService.paperlessEnrollmentError(this.paperlessConfirmationMessage)) {
                logMessage = 'Edit Automatic Payment Confirmation- Page load with error message';
            }
            this.logger.info(logMessage, this.editAutomaticPaymentConfirmationService.logPageLoadForPaperless(this.editPaymentFlow.editAutomaticPaymentForm.enrollInPaperless));
            this.logger.logCurrentContents();
        }
        this.logPaperlessSuccessfulEnrollment();
    }

    ngOnDestroy(): void {
        this.editPaymentFlow.clear();
    }

    get wasEsddaEnrollmentSuccessful(): boolean {
        return this.selectedPaperless && this.editPaymentFlow.editAutomaticPaymentResponse?.esddaPreferences === true;
    }

    get isPaperlessEnrollmentUnsuccessful(): boolean {
        return this.editAutomaticPaymentConfirmationService.paperlessEnrollmentError(this.paperlessConfirmationMessage);
    }

    get errorMessage(): string {
        return this.editAutomaticPaymentConfirmationService.errorMessage;
    }

    setVariablesFromApiResponse(): void {
        this.amountDue = this.safeCurrencyPipe.transformToCurrency(this.editAutomaticPaymentConfirmationService.amountDue);
        this.amountDueDate = this.editAutomaticPaymentConfirmationService.amountDueDate ?
            this.easternTimeDatePipe.transform(this.editAutomaticPaymentConfirmationService.amountDueDate) : '';
    }

    get urlParams(): ISBRouteParams {
        return {
            accountNumber: this.session.billingAccountNumber,
            sessionId: this.session.a2aSessionId,
            policyNumber: this.paymentFlowType.pKey,
            referringPage: 'review'
        };
    }

    sendEditAutoPaySuccessCtmEvent(): void {
        if (this.apiCallSuccessful) {
            this.eventService.handleCtmEvent(
                CtmEventType.PP_EDIT_EASY_PAYMENT_METHOD, {
                productType: CtmProductType.BILLING,
                paymentMethod: this.editAutomaticPaymentConfirmationService.fetchCtmPaymentMethod(this.paymentMethod, this.editAutoPaymentForm)
            });
        }
    }

    onContinue(): void {
        if (this.amountDueExists()) {
            this.navigateToMAP();
        } else {
            this.navigateToPaymentPreferences();
        }
    }

    secondayNavigation(metaData: ButtonMetaData): void {
        if (metaData.label === 'Account Summary') {
            this.navigateToCAM();
        } else {
            this.router.navigateByUrl(metaData.navigation);
        }
    }

    amountDueExists(): boolean {
        const amountDueExists = this.editAutomaticPaymentConfirmationService.amountDue > 0;
        if (this.session.isSAP) {
            return amountDueExists && this.automaticPaymentSuspended;
        } else if (this.session.isNBP) {
            return amountDueExists && this.editPaymentFlow.canPayNow;
        }
    }

    navigateToCAM(): void {
        this.window.location.href = environment.CAM_HOME;
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString));
    }

    navigateToMAP(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.RELATIVE.payBillLanding(this.urlParams));
    }

    navigateToPaperless(): void {
        this.window.location.href = environment.NW_HEADER.preferences;
    }

    showPrintDialog(): void {
        this.sendOnClickOfPrintButtonEbiEvent();
        this.window.print();
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_LOAD_OF_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE, {
            paymentPlan: this.editPaymentFlow.paymentPlan
        });
    }

    sendOnClickOfPrintButtonEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_PRINT_BTN_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE, {
            paymentPlan: this.editPaymentFlow.paymentPlan
        });
    }

    setFormattedCurrentDate(): void {
        const dateFormat = 'MM/dd/yyyy';
        const timeFormat = 'hh:mm aa';
        const date = this.easternTimeDatePipe.transform(new Date(), dateFormat);
        const time = this.easternTimeDatePipe.transform(new Date(), timeFormat);
        this.formattedCurrentDate = `${date}, ${time} ET`;
    }

    private logPaperlessSuccessfulEnrollment(): void {
        if (this.wasEsddaEnrollmentSuccessful) {
            this.logger.info('user enrolled in paperless successfully through edit automatic payments flow');
        }
    }

    get secondaryButton(): ButtonMetaData {
        const buttonMetaData: ButtonMetaData = {
            label: 'Payment preferences',
            navigation: environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString)
        };
        return buttonMetaData;
    }

    get selectedPaperless(): boolean {
        return this.editPaymentFlow.selectedPaperless === 'yes';
    }

    get editAutoPaymentForm(): PaymentFormGroup {
        return this.editPaymentFlow.editAutomaticPaymentForm;
    }

    get paymentMethod(): PaymentItem {
        return this.editPaymentFlow.selectedPayMethod;
    }

    get paymentType(): string {
        return this.editAutomaticPaymentConfirmationService.paymentType;
    }

    get maskedNumber(): string {
        return this.editAutomaticPaymentConfirmationService.maskedNumber;
    }

    get upNextLabel(): string {
        return this.editAutomaticPaymentConfirmationService.upNextLabel;
    }

    get apiCallSuccessful(): boolean {
        return this.editPaymentFlow.successfullyUpdatedEnrollment;
    }

    get apiServiceDownMessage(): string {
        return WARNING_MESSAGES.API_FAILURE;
    }

    get automaticPaymentSuspended(): boolean {
        return this.editPaymentFlow.automaticPaymentSuspended;
    }

    get canPayNow(): boolean {
        return this.editPaymentFlow.canPayNow;
    }
}
export interface ButtonMetaData {
    label: string;
    navigation: string;
}
