/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ContentfulMapperService, ContentfulISBDataItem } from '@nationwide/dgs-angular-billing-common';
import { FooterContentAdapter } from './footer-content.adapter';
import { FooterData } from '@nationwide/internet-servicing-angular-components';

@Injectable()
export class FooterService {
    constructor(
        private readonly contentfulService: ContentfulMapperService,
        private readonly footerAdapter: FooterContentAdapter
    ) { }

    defaultFooter(isAuthenticated = true): FooterData {
        return this.footerWithoutTridionContent(isAuthenticated);
    }

    fetchFooter(isAuthenticated = true): Observable<FooterData> {
        const contentfulResponse = this.contentfulService.getContentFulFooter();
        return contentfulResponse.pipe(
            map((contentful) => {
                const overrides = contentful.footer ?
                    this.footerTridionContent(contentful.footer, isAuthenticated) :
                    this.backupTridionContent(isAuthenticated);

                return {
                    ...this.footerWithoutTridionContent(isAuthenticated),
                    ...overrides
                };
            })
        );
    }

    fetchMAPFooter(isAuthenticated = true): Observable<FooterData> {
        const contentfulResponse = this.contentfulService.getContentFulFooter();
        return contentfulResponse.pipe(
            map((contentful) => {
                const overrides = contentful.footer ?
                    this.footerMAPContent(contentful.footer, isAuthenticated) :
                    this.backupTridionContent(isAuthenticated);

                return {
                    ...this.footerWithoutTridionContent(isAuthenticated),
                    ...overrides
                };
            })
        );
    }
    backupTridionContent(isAuthenticated: boolean): FooterData {
        return {
            nationwideLogo: { altText: 'Nationwide', url: '', source: '/tridion/static/nw-logo.png', title: 'Nationwide' },
            onYourSideDefaultNumber: '',
            fdic: { source: '/tridion/static/footer_sprites.png', width: '88px', height: '36px', altText: '', title: 'FDIC', url: 'https://www.fdic.gov' },
            truste: { source: '/tridion/static/footer_sprites.png', width: '93px', height: '30px', altText: 'TRUSTe', title: 'TRUSTe', url: 'https://privacy.truste.com/privacy-seal/Nationwide-Mutual-Insurance-Company/validation?rid=d4973755-5da9-4f06-bf72-f0fe1644c5af' },
            equalHousing: { source: '/tridion/static/footer_sprites.png', width: '46px', height: '36px', altText: '', title: 'Equal Hosing Opportunity Insurer', url: 'https://portal.hud.gov/hudportal/HUD?src=/program_offices/fair_housing_equal_opp' },
            facebook: { source: '/tridion/static/soc_facebook.svg', altText: '', title: 'Facebook Chiclet Icon', url: 'https://www.facebook.com/nationwide' },
            twitter: { source: '/tridion/static/soc_twitter.svg', altText: '', title: 'Twitter Chiclet Icon', url: 'https://twitter.com/nationwide' },
            instagram: { source: '/tridion/static/soc_instagram.svg', altText: '', title: 'Instagram Icon', url: 'https://www.instagram.com/nationwide/' },
            youtube: { source: '/tridion/static/soc_youtube.svg', title: 'youtube Chiclet', altText: '', url: 'https://www.youtube.com/nationwide' },
            linkedIn: { source: '/tridion/static/soc_linkedin.svg', title: 'Linkedin Icon', altText: '', url: 'https://www.linkedin.com/company/2340' },
            doNotSellMyPersonalInformationContent: '',
            contactUsLink: isAuthenticated ? environment.NW_FOOTER.contactUsLink : environment.NW_FOOTER.unauthenticatedContactUsLink,
            logoutLink: environment.NW_FOOTER.logout,
            forBusinessLink: isAuthenticated ? environment.NW_FOOTER.forBusinessLink : null,
            forIndividualLink: '',
            isAuthenticated,
            includeChicklets: true,
            disclosureContent: `
        Nationwide Mutual Insurance Company and affiliates. Home Office: Columbus,
        OH. In Washington, Auto insurance underwritten by Nationwide Insurance
        Company of America, Homeowner insurance underwritten by Nationwide Mutual
        Fire Company and Nationwide Insurance Company of America. Life Insurance
        and Annuities are issued by Nationwide Life Insurance Company and/or
        Nationwide Life and Annuity Insurance Company, Columbus, Ohio. The general
        distributor for variable insurance products is Nationwide Investment Services
        Corporation (in MI only: Nationwide Investment Svcs. Corporation), member
        <a href="https://www.finra.org/" target="_blank">FINRA</a>. © 2018 Nationwide.
       `,
            // TODO: Point to non-prod URL when possible
            /* eslint-disable isb/no-hardcoded-nw-url */
            privacySecurityContent: '<a href="https://www.nationwide.com/privacy-security.jsp" target="_blank">Privacy and Security</a>',
            termsConditionsContent: '<a href="https://www.nationwide.com/terms-conditions.jsp" target="_blank">Terms and Conditions</a>',
            accessibilityContent:
                '<a href="https://www.nationwide.com/personal/about-us/website-accessibility.html" target="_blank">Accessibility</a>'
            /* eslint-enable isb/no-hardcoded-nw-url */
        };
    }

    footerWithoutTridionContent(isAuthenticated: boolean): FooterData {
        return {
            nationwideLogo: { altText: '', url: null },
            onYourSideDefaultNumber: '',
            truste: { altText: '', url: '' },
            disclosureContent: '',
            privacySecurityContent: '',
            termsConditionsContent: '',
            accessibilityContent: '',
            doNotSellMyPersonalInformationContent: '',
            contactUsLink: isAuthenticated ? environment.NW_FOOTER.contactUsLink : environment.NW_FOOTER.unauthenticatedContactUsLink,
            logoutLink: environment.NW_FOOTER.logout,
            forBusinessLink: isAuthenticated ? environment.NW_FOOTER.forBusinessLink : null,
            forIndividualLink: '',
            isAuthenticated,
            includeChicklets: true
        };
    }

    footerTridionContent(footerContent: ContentfulISBDataItem, isAuthenticated: boolean): any {
        const contentfulData = this.footerAdapter.convertResponseV1(footerContent);
        const nationwideLogoFooter = contentfulData.Components.logoWhite.Images.logoWhite;
        const nationwideLogoFooterAltText = contentfulData?.Components?.logo?.Images?.logo;
        const nationwideLogoFooterData: NationwideLogo = {
            logoTitle: nationwideLogoFooterAltText?.AltText,
            logoSource: nationwideLogoFooter.URL,
            logoAltText: nationwideLogoFooterAltText?.AltText
        };
        return this.setFooterData(footerContent, nationwideLogoFooterData, isAuthenticated);
    }

    footerMAPContent(footerContent: ContentfulISBDataItem, isAuthenticated: boolean): any {
        const nationwideLogoFooterData: NationwideLogo = {
            logoTitle: 'Nationwide Labs Logo',
            logoSource: '/assets/images/Nationwide-labs-logo.png',
            logoAltText: 'Nationwide Labs Logo'
        };
        return this.setFooterData(footerContent, nationwideLogoFooterData, isAuthenticated);
    }

    setFooterData(footerContent: ContentfulISBDataItem, nationwideLogoData: NationwideLogo, isAuthenticated: boolean): any {
        const contentfulData = this.footerAdapter.convertResponseV1(footerContent);
        const footerData: any = {};

        footerData.contactUsLink = isAuthenticated ? environment.NW_FOOTER.contactUsLink : environment.NW_FOOTER.unauthenticatedContactUsLink;
        footerData.logoutLink = environment.NW_FOOTER.logout;
        footerData.forBusinessLink = isAuthenticated ? environment.NW_FOOTER.forBusinessLink : null;
        footerData.forIndividualLink = '';
        footerData.isAuthenticated = isAuthenticated;
        footerData.includeChicklets = true;
        footerData.onYourSideDefaultNumber = '';
        footerData.nationwideLogo = {};
        footerData.nationwideLogo.altText = nationwideLogoData.logoAltText;
        footerData.nationwideLogo.source = nationwideLogoData.logoSource;
        footerData.nationwideLogo.title = nationwideLogoData.logoTitle;
        footerData.nationwideLogo.url = isAuthenticated ? environment.CAM_HOME : environment.NATIONWIDE_HOMEPAGE;

        const trusteFooter = contentfulData.Components.truste.Images.truste;
        footerData.truste = {};
        footerData.truste.source = trusteFooter.URL;
        footerData.truste.width = trusteFooter.styleObj.width;
        footerData.truste.height = trusteFooter.styleObj.height;
        footerData.truste.altText = trusteFooter.AltText;
        footerData.truste.title = '';
        footerData.truste.url = trusteFooter.Link;

        const equalHousingFooter = contentfulData.Components.equalHousing.Images.equalHousing;
        footerData.equalHousing = {};
        footerData.equalHousing.source = equalHousingFooter.URL;
        footerData.equalHousing.width = equalHousingFooter.styleObj.width;
        footerData.equalHousing.height = equalHousingFooter.styleObj.height;
        footerData.equalHousing.altText = equalHousingFooter.AltText;
        footerData.equalHousing.title = '';
        footerData.equalHousing.url = equalHousingFooter.Link;

        footerData.disclosureContent = this.contentfulService.getFilteredData(contentfulData.Components.disclosure.Content);
        footerData.privacySecurityContent = contentfulData.Components.privacySecurity.Content;
        footerData.termsConditionsContent = contentfulData.Components.termsConditions.Content;
        footerData.accessibilityContent = contentfulData.Components.accessibility.Content;
        footerData.doNotSellMyPersonalInformationContent = contentfulData.Components.doNotSellMyPersonalInformation.Content;

        const facebookFooter = contentfulData.Components.facebook.Images.facebook;
        footerData.facebook = {};
        footerData.facebook.source = facebookFooter.URL;
        footerData.facebook.altText = facebookFooter.AltText;
        footerData.facebook.url = facebookFooter.Link;

        const twitterFooter = contentfulData.Components.twitter.Images.twitter;
        footerData.twitter = {};
        footerData.twitter.source = twitterFooter.URL;
        footerData.twitter.altText = twitterFooter.AltText;
        footerData.twitter.url = twitterFooter.Link;

        const instagramFooter = contentfulData.Components.instagram.Images.instagram;
        footerData.instagram = {};
        footerData.instagram.source = instagramFooter.URL;
        footerData.instagram.altText = instagramFooter.AltText;
        footerData.instagram.url = instagramFooter.Link;

        const youtubeFooter = contentfulData.Components.youtube.Images.youtube;
        footerData.youtube = {};
        footerData.youtube.source = youtubeFooter.URL;
        footerData.youtube.altText = youtubeFooter.AltText;
        footerData.youtube.url = youtubeFooter.Link;

        const linkedinFooter = contentfulData.Components.linkedin.Images.linkedin;
        footerData.linkedIn = {};
        footerData.linkedIn.source = linkedinFooter.URL;
        footerData.linkedIn.altText = linkedinFooter.AltText;
        footerData.linkedIn.url = linkedinFooter.Link;

        return footerData;
    }
}

export interface NationwideLogo {
    logoTitle: string;
    logoSource: string;
    logoAltText: string;
}
