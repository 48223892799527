import { Injectable } from '@angular/core';
import { ChangeDueDates } from '../../change-due-date/billing-due-dates.model';
import { BillingChangeDueDates } from '../../change-due-date/change-billing-due-dates.model';

const UP_NEXT_TAB_LABELS = {
    PAPERLESS_ENROLLMENT: 'Paperless enrollment',
    AUTOMATIC_PAYMENTS: 'Automatic payments',
    BILLING_DETAILS: 'Billing details',
    BILLS_AND_PAYMENTS: 'Bills & payments'
};
@Injectable()
export class ChangeDueDateCommonService {
    payBillFlow: any;
    upNextLabel: string;
    dueDateUpdateFailed: boolean;
    responseCode: number;

    getCurrentDueDate(billingDueDates: ChangeDueDates): string {
        const dueDate = billingDueDates?.currentBillDueDate || billingDueDates?.nextDueDate;
        if (!dueDate) {
            return null;
        }
        const formattedDueDate = this.formatDueDate(dueDate).getUTCDate().toString();
        return this.billingDueDateStatement(formattedDueDate).currentBillingDueDate;
    }

    billingDueDateStatement(dueDate: string): BillingChangeDueDates {
        let currentBillingDueDate: string;
        const billingDueDateValue: string = dueDate.toString();
        if (dueDate === '1' || dueDate === '21' || dueDate === '31') {
            currentBillingDueDate = `${dueDate}st`;
        } else if (dueDate === '2' || dueDate === '22') {
            currentBillingDueDate = `${dueDate}nd`;
        } else if (dueDate === '3' || dueDate === '23') {
            currentBillingDueDate = `${dueDate}rd`;
        } else {
            currentBillingDueDate = `${dueDate}th`;
        }
        return { currentBillingDueDate, billingDueDateValue };
    }

    formatDueDate(date: string): Date {
        const rawDate: any[] = date.split('-');
        const currentBillingDueDate = new Date(rawDate[2], rawDate[0] - 1, rawDate[1]);
        return currentBillingDueDate;
    }

    selectDueDates(billingDueDates: ChangeDueDates): BillingChangeDueDates[] {
        const startDate: Date = new Date(this.formatDueDate(billingDueDates.beginAllowedDueDate));
        const endDate: Date = new Date(this.formatDueDate(billingDueDates.endAllowedDueDate));
        const endDate1: any = new Date(endDate).setDate(endDate.getDate() + 1);
        const dueDates: Array<number> = [];
        const twentyNineDays = 29;
        let finalDueDates;
        for (const iDate = startDate; iDate < endDate1; iDate.setDate(iDate.getDate() + 1)) {
            if (iDate.getUTCDate() < twentyNineDays) {
                dueDates.push(iDate.getUTCDate());
            }
        }
        const sortedDueDates = dueDates.sort((a, b) =>
            a - b);
        if (sortedDueDates) {
            finalDueDates = sortedDueDates.map((dueDate) =>
                this.billingDueDateStatement(dueDate.toString()));
        }
        return finalDueDates;
    }

    setUpNextLabel(): void {
        const yes = 'yes';
        const payBillFlow = this.getPayBillFlow();
        if (payBillFlow?.payBillForm?.enrollInEasyPay === yes || payBillFlow?.payBillForm?.paymentType === 'easy-pay-enrollment') {
            this.upNextLabel = UP_NEXT_TAB_LABELS.AUTOMATIC_PAYMENTS;
        } else if (payBillFlow?.payBillForm?.enrollInPaperless === yes) {
            this.upNextLabel = UP_NEXT_TAB_LABELS.PAPERLESS_ENROLLMENT;
        } else {
            this.upNextLabel = UP_NEXT_TAB_LABELS.BILLS_AND_PAYMENTS;
        }
    }

    upNextTab(): string {
        let output: string;
        if (this.upNextLabel === UP_NEXT_TAB_LABELS.PAPERLESS_ENROLLMENT) {
            output = 'preferencesPage';
        } else if (this.upNextLabel === UP_NEXT_TAB_LABELS.AUTOMATIC_PAYMENTS) {
            output = 'paymentPreferences';
        } else if (this.upNextLabel === UP_NEXT_TAB_LABELS.BILLS_AND_PAYMENTS) {
            output = 'billsAndPayments';
        }
        return output;
    }

    get upNextLabelProperty(): string {
        return this.upNextLabel;
    }

    setError(error: boolean): void {
        this.dueDateUpdateFailed = error;
    }

    dueDateUpdateError(): boolean {
        return this.dueDateUpdateFailed;
    }

    storePayBillFlow(payBillFlow): void {
        this.payBillFlow = payBillFlow;
    }

    getPayBillFlow(): any {
        return this.payBillFlow;
    }
}
