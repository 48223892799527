import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddPaymentMethodFlowService } from '../services/add-payment-method-flow.service';
import { SessionService } from '../../../../../../shared/session/session.service';
import { AddPaymentMethodServiceResponses } from '../review/add-payment-method-review.service';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { HttpErrorResponse } from '@angular/common/http';
import { PBS } from '@nationwide/dgs-angular-billing-common';
import { PaperlessEnrollmentBaseService } from '../../../../shared/services/paperless-enrollment-error.service';
import { HttpCachingService } from '../../../../../../shared/service/http-caching-service/http-caching.service';

@Injectable()
export class AddPaymentMethodConfirmationService extends PaperlessEnrollmentBaseService {
    private readonly UP_NEXT_TAB_LABELS = {
        PAPERLESS_ENROLLMENT: 'Paperless enrollment',
        AUTOMATIC_PAYMENTS: 'Automatic payments',
        PAYMENT_PREFERENCES: 'Payment preferences'
    };
    private readonly MASKED_NUMBERS = {
        BANK_ACCOUNT: 3,
        BANK_CARD: 4
    };
    // eslint-disable-next-line max-params
    constructor(
        private addPaymentFlow: AddPaymentMethodFlowService,
        protected sessionService: SessionService,
        private httpCachingService: HttpCachingService) {
        super(sessionService);
    }

    get upNextLabel(): string {
        let label = '';
        if (!this.addPaymentMethodError) {
            if (this.navigateToEasyPay) {
                label = this.UP_NEXT_TAB_LABELS.AUTOMATIC_PAYMENTS;
            } else {
                label = this.UP_NEXT_TAB_LABELS.PAYMENT_PREFERENCES;
            }
        }
        return label;
    }

    get navigateToPaperless(): boolean {
        return this.addPaymentForm.enrollInPaperless === 'yes';
    }

    get navigateToEasyPay(): boolean {
        return this.addPaymentForm.enrollInEasyPay === 'yes';
    }

    get addPaymentForm(): PaymentFormGroup {
        return this.addPaymentFlow.addPaymentForm;
    }

    get paymentType(): string {
        let paymentType;
        if (this.addPaymentFlow.selectedPaymentMethod.isBankAccount) {
            paymentType = this.addPaymentFlow.addPaymentForm.bankAccountType;
        } else if (this.addPaymentFlow.selectedPaymentMethod.isBankCard) {
            paymentType = this.addPaymentFlow.addPaymentForm.cardType;
        }
        return paymentType;
    }

    get maskedNumber(): string {
        let bankNumber;
        if (this.addPaymentFlow.selectedPaymentMethod.isBankAccount) {
            bankNumber = this.addPaymentFlow.addPaymentForm.bankAccountNumber;
            bankNumber = `*********${bankNumber.slice(bankNumber.length - this.MASKED_NUMBERS.BANK_ACCOUNT)}`;
        } else if (this.addPaymentFlow.selectedPaymentMethod.isBankCard) {
            bankNumber = this.addPaymentFlow.addPaymentForm.bankCardNumber;
            bankNumber = `************${bankNumber.slice(bankNumber.length - this.MASKED_NUMBERS.BANK_CARD)}`;
        }
        return bankNumber;
    }

    get addPaymentMethodApiResponse(): AddPaymentMethodServiceResponses {
        return this.addPaymentFlow.addPaymentMethodServiceResponses;
    }

    get apiCallFailed(): boolean {
        return this.addPaymentFlow.apiCallFailed && !this.paymentMethodAlreadyExists && !this.paymentMethodLimitReached;
    }

    get paymentMethodAlreadyExists(): boolean {
        const savedPaymentMethodResponse = <HttpErrorResponse> this.addPaymentMethodApiResponse.savedPaymentMethodResponse;
        let paymentMethodExists = false;

        const nbpErrorResponseCode = '1007';
        const nbpErrorResponse = 'PAYMENT PREFERENCE EXISTS';
        const sapErrorResponse = 'not unique';

        if (this.sessionService.sourceSystem === 'SAP Billing') {
            paymentMethodExists = savedPaymentMethodResponse?.error?.developerMessage?.includes(sapErrorResponse);
        } else {
            paymentMethodExists = savedPaymentMethodResponse?.error?.developerMessage?.includes(nbpErrorResponseCode) &&
                savedPaymentMethodResponse?.error?.developerMessage?.includes(nbpErrorResponse);
        }
        return paymentMethodExists;
    }

    get paymentMethodLimitReached(): boolean {
        const savedPaymentMethodResponse = <HttpErrorResponse> this.addPaymentMethodApiResponse.savedPaymentMethodResponse;
        const nbpErrorResponseCode = '1007';
        const nbpErrorResponseEFT = 'MAX 10 EFT PREFS ALREADY EXIST';
        const nbpErrorResponseCC = 'MAX 10 CC  PREFS ALREADY EXIST';

        return savedPaymentMethodResponse?.error?.developerMessage?.includes(nbpErrorResponseCode) &&
            (savedPaymentMethodResponse?.error?.developerMessage?.includes(nbpErrorResponseEFT) ||
                savedPaymentMethodResponse?.error?.developerMessage?.includes(nbpErrorResponseCC));
    }

    get addPaymentMethodError(): boolean {
        return this.allErrors.includes(true);
    }

    fetchAgreements(): Observable<PBS.RetriveCustomerAgreementResponse> {
        return this.httpCachingService.fetchRetrieveCustomerAgreementResponse();
    }

    private get allErrors(): boolean[] {
        return [
            this.paymentMethodAlreadyExists,
            this.paymentMethodLimitReached,
            this.apiCallFailed
        ];
    }
}
