<div class="manage-payment-form-container">
    <div class="bolt-container">
        <bw-bolt-progress-bar appFocusOnInit arialabel="Update automatic payment progress value is 100%" class="progress-bar-container to-container" progressBarValue="100"></bw-bolt-progress-bar>
        <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Update automatic
            payment information: confirmation</h1>
        <div class="bolt-row">
            <div class="bolt-col-12">
                <ng-container *ngIf="apiCallSuccessful">
                    <bw-bolt-notification class="bolt-space-bottom-2xl to-container" type="success">Your automatic payment changes have been confirmed.
                    </bw-bolt-notification>
                    <app-payment-sub-header>Confirmation details</app-payment-sub-header>
                    <div class="bolt-space-bottom-2xl">
                        <ng-container>
                            <div class="bolt-row" [ngClass]="{'bolt-space-bottom-xl': wasEsddaEnrollmentSuccessful}">
                                <div class="bolt-col">
                                    <strong>{{effectiveDateAutomatic}}</strong>
                                </div>
                                <div class="bolt-col">
                                    {{formattedCurrentDate}}
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="wasEsddaEnrollmentSuccessful">
                            <div class="bolt-row">
                                <div class="bolt-col">
                                    <strong>{{effectiveDatePaperless}}</strong>
                                </div>
                                <div class="bolt-col">
                                    {{formattedCurrentDate}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="this.amountDueExists()">
                        <app-payment-sub-header>Outstanding payment due</app-payment-sub-header>
                        <p [ngClass]="[(emailAddressUpdateAttempted ||
							isPaperlessEnrollmentUnsuccessful) ?
							'bolt-space-bottom-xl':'bolt-space-bottom-2xl']">
                            A payment of {{this.amountDue}} for this account is due on
                            {{this.amountDueDate | date:'MM/dd/yyyy'}}. This
                            amount
                            will not be automatically deducted from your account.</p>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!apiCallSuccessful">
                    <bw-bolt-notification class="bolt-space-bottom-2xl to-container" type="error" id="error-notification">
                        {{ apiServiceDownMessage }}
                    </bw-bolt-notification>
                </ng-container>
                <ng-container *ngIf="isPaperlessEnrollmentUnsuccessful">
                    <p *ngIf="apiCallSuccessful">Paperless enrollment: error</p>
                    <bw-bolt-notification class="bolt-space-bottom-2xl to-container">
                        {{ errorMessage }}
                    </bw-bolt-notification>
                </ng-container>
            </div>
        </div>
    </div>
    <ng-container *ngIf="amountDueExists() && (automaticPaymentSuspended || selectedPaperless ||
		canPayNow);else amountDueNotExists;">
        <bolt-button-bar>
            <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
            <bolt-button id="secondary-button" (click)="secondayNavigation(secondaryButton)" slot="back">{{secondaryButton.label}}</bolt-button>
            <bolt-button slot="forward" id="submit-button" type="primary" (click)="onContinue()" *ngIf='upNextLabel'>
                {{upNextLabel}}</bolt-button>
        </bolt-button-bar>
    </ng-container>
    <ng-template #amountDueNotExists>
        <bolt-button-bar>
            <bolt-button id="print-button" slot="cancel" (click)="showPrintDialog()">Print</bolt-button>
            <bolt-button id="secondary-button" (click)="secondayNavigation(secondaryButton)" slot="forward">{{secondaryButton.label}}</bolt-button>
        </bolt-button-bar>
    </ng-template>
</div>