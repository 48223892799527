import { Injectable, Inject } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from '../../../environments/environment';
import { PaymentFlowType } from '../shared/payment-flow-type.service';
import { SessionService } from '../../shared/session/session.service';
import { PageName } from '../../shared/page-name.enum';

@Injectable()
export class PayBillConfirmationGuard implements CanActivate {
    // eslint-disable-next-line max-params
    constructor(
        private paymentFlowType: PaymentFlowType,
        private session: SessionService,
        @Inject('window') private window: Window
    ) { }

    canActivate(): boolean {
        if (this.session.currentPage !== PageName.PAY_BILL_REVIEW && this.paymentFlowType.isCyberLife) {
            this.goToAccountSummary();
        }
        return true;
    }

    goToAccountSummary(): void {
        this.window.location.href = environment.CAM_HOME;
    }
}
