/* eslint-disable no-useless-concat */
import { Component, OnInit, Inject } from '@angular/core';
import { AreYouSureFormGroup } from '../../../../../../shared/payments/are-you-sure-radio-buttons/are-you-sure-form-group';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SessionService } from '../../../../../../shared/session/session.service';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { RemoveRefundMethodFlowService } from '../services/remove-refund-method-flow.service';
import { timeout, Observable, of, forkJoin, catchError, tap, flatMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { isbExperienceServiceToken, IsbExperienceService, MoneyProcessingService, PersonalBilling } from '@nationwide/dgs-angular-billing-common';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { TitleCasePipe } from '@angular/common';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { OmsResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience';

@Component({
    selector: 'app-remove-refund-method-landing',
    templateUrl: './remove-refund-method-landing.component.html',
    styleUrls: ['./remove-refund-method-landing.component.scss']
})
export class RemoveRefundMethodLandingComponent extends BasePageComponent implements OnInit {
    pageLoadComplete = false;
    submitted = false;
    formGroup: AreYouSureFormGroup;
    savedRefundMethod: PersonalBilling.RefundMethod;
    pageName = ManagePaymentPreferencesPageTitle.refundMethod.remove.landing;

    // eslint-disable-next-line max-params
    constructor(
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private paymentPreferencesApi: PaymentPreferencesApiService,
        router: Router,
        private titleCase: TitleCasePipe,
        private formBuilder: ManagePaymentPrefsFormBuilder,
        private moneyProcessing: MoneyProcessingService,
        private urlUtil: UrlUtil,
        session: SessionService,
        private formUtil: FormUtil,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        @Inject('logger') private logger: LoggerService,
        private removeRefundMethodFlow: RemoveRefundMethodFlowService,
        private eventService: BillingEventService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        this.session.currentPage = this.pageName;
        this.formGroup = this.formBuilder.newAreYouSureFormGroup();
        this.setVariablesFromApiResponse();
    }

    setVariablesFromApiResponse(): void {
        this.nextSub = this.paymentPreferencesApi.removeSavedRefundMethodLanding().pipe(
            timeout(environment.TIMEOUT.apiCallDuration.paymentPreferences)
        ).subscribe({
            next: (response) => {
                this.savedRefundMethod = response.savedRefundMethod;
                this.pageLoadComplete = true;
                this.removeRefundMethodFlow.save({
                    refundMethod: this.savedRefundMethod,
                    apiCallSuccessful: true,
                    maskedBankAccountNumber: this.maskedNumber,
                    emailAddress: this.managePaymentPrefsAdapter.fetchMostCurrentEmail(response.retrieveCustomerAgreement, response.internetRegistrationResponse)
                });
            },
            error: (error) => {
                this.logger.error('HARDFALL: REMOVE REFUND METHOD LANDING API ERROR', { error });
                this.logger.logCurrentContents();
                this.removeRefundMethodFlow.save({ apiCallSuccessful: false });
                this.navigateToHardfall();
            }
        });
    }

    onSubmit(): void {
        this.submitted = true;
        this.formUtil.touchAllFormControls(this.formGroup.group);
        if (this.formGroup.group.valid) {
            this.sendSubmitButtonClickedEbiEvent();
            if (this.formGroup.selection === 'yes') {
                this.pageLoadComplete = false;
                this.handleApiCalls().subscribe(() => this.navigateToConfirmation());
            } else if (this.formGroup.selection === 'no') {
                this.cancelRemoveRefundMethod();
            }
        }
    }

    handleApiCalls(): Observable<any> {
        return this.removeRefundMethod().pipe(
            flatMap((patchPayPlanRes) =>
                forkJoin({
                    patchPayPlan: of(patchPayPlanRes),
                    removeRefundMethodEmail: this.removeRefundMethodFlow.apiCallSuccessful ?
                        this.removeRefundMethodEmail() : this.requestPlaceholder()
                })
            )
        );
    }

    sendSubmitButtonClickedEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_SUBMIT_BTN_REMOVE_REFUND_METHOD_LANDING_PAGE, {
            yesNoSelection: <'Yes' | 'No'> this.titleCase.transform(this.formGroup.selection),
            paymentMethod: this.ebiPaymentMethodData
        });
    }

    get ebiPaymentMethodData(): PaymentMethodsData[] {
        const data = [];
        const savedRefundMethod = this.savedRefundMethod;

        const stateEbi = savedRefundMethod.payorInfo.state;
        data.push({
            stateEbi
        });

        return data;
    }

    cancelRemoveRefundMethod(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.RELATIVE.paymentPreferences(
                {
                    accountNumber: this.session.billingAccountNumber,
                    sessionId: this.session.a2aSessionId
                }
            )
        );
    }

    navigateToConfirmation(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_REFUND_METHOD.REMOVE.CONFIRMATION(
                this.urlUtil.hashParamsString
            )
        );
    }

    navigateToHardfall(): void {
        this.router.navigateByUrl('/personal/error/system-down');
    }

    private removeRefundMethod(): Observable<number | HttpErrorResponse> {
        return this.moneyProcessing.patchPayPlan({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            agreementNumber: this.session.billingAccountNumber,
            payload: {
                updatedEmail: this.removeRefundMethodFlow.emailAddress,
                billingPayload: {
                    enterpriseCustomerNumber: this.session.ecn,
                    requestType: 'RefundMethodChange.PayPlanUpdate',
                    action: 'update',
                    currentBillingMethodType: 'Recurring EFT',
                    newBillingMethodType: 'Direct',
                    accountDueDate: '',
                    paymentMethod: {
                        paymentMethodType: this.savedRefundMethod.paymentMethod.paymentMethodType,
                        payorInfo: this.savedRefundMethod.payorInfo,
                        description: 'UNSET'
                    }
                }
            }
        }).pipe(
            timeout(environment.TIMEOUT.apiCallDuration.submitPayment),
            tap(() => this.removeRefundMethodFlow.save({ apiCallSuccessful: true })),
            catchError((err) => {
                this.logger.error('API ERROR: PATCH PAY PLAN ON REFUND METHOD LANDING PAGE', err);
                this.removeRefundMethodFlow.save({ apiCallSuccessful: false });
                return of(err);
            })
        );
    }

    private removeRefundMethodEmail(): Observable<OmsResponse> {
        return this.isbExperience.sendRemoveRefundMethodEmail({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            updatedEmail: this.removeRefundMethodFlow.emailAddress
        }).pipe(
            catchError((err) => of(err))
        );
    }

    private requestPlaceholder(): Observable<any> {
        return of(undefined);
    }

    get displayName(): string {
        let name;
        const bankAccountType = this.savedRefundMethod.electronicFundsTransfer.bankAccountType;
        if (bankAccountType === 'C' || bankAccountType === 'Checking') {
            name = 'Checking';
        } else {
            name = 'Savings';
        }
        return name;
    }

    get maskedNumber(): string {
        let bankNumber;
        const lastThreeDigits = 3;
        const maskedNumber = this.savedRefundMethod.electronicFundsTransfer.maskedBankAccountNumber;
        if (maskedNumber) {
            bankNumber = maskedNumber;
        } else {
            bankNumber = this.savedRefundMethod.electronicFundsTransfer.bankAccountNumber;
            bankNumber = `*********${bankNumber.slice(bankNumber.length - lastThreeDigits)}`;
        }
        return bankNumber;
    }

    get displayMessageForForm(): string {
        return `Are you sure you want to remove the refund method: ${this.displayName} ${this.maskedNumber}?`;
    }

    get globalErrorDisplayable(): boolean {
        return !this.formGroup.group.valid &&
            this.submitted;
    }

    get emailAddress(): string {
        return this.removeRefundMethodFlow.emailAddress;
    }
}
export interface RemoveRefundMethodApiResponses {
    patchPayPlan: number | HttpErrorResponse | Error;
    removeRefundMethodEmail: OmsResponse | HttpErrorResponse | Error;
}
