import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { SessionService } from '../../../../../../shared/session/session.service';
import { PageName } from '../../../../../../shared/page-name.enum';
import { PayPlanFlow } from '../../../automatic-payments/pay-plan/shared/pay-plan-flow.service';

@Injectable()
export class EditPayplanBackFromConfirmationGuard implements CanActivate {
    // eslint-disable-next-line max-params
    constructor(
        private router: Router,
        private session: SessionService,
        private payPlanFlow: PayPlanFlow) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const canActivateResponse = this.session.currentPage !== PageName.EDIT_PAYMENT_PLAN_CONFIRMATION;

        if (!canActivateResponse) {
            this.payPlanFlow.reset();
        }

        return canActivateResponse || this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(
                this.currentRouteParams()
            )
        );
    }
    currentRouteParams(): string {
        return this.router.url.split('?')[1];
    }
}
