import { Injectable, Inject } from '@angular/core';
import { BillingHistoryItem } from '../../../billing/bills-and-payments/billing-history/billing-history-item.model';
import { DocumentOpenerConfig, documentOpenerConfigToken } from './doc-opener.config';
import { SessionService } from '../../session/session.service';
import { environment } from '../../../../environments/environment';
import { APICommonService } from '@nationwide/core-component-library-base';
import { IdCardOpenerConfig } from './id-card';

@Injectable()
export class DocOpenerService {
    constructor(
        @Inject(documentOpenerConfigToken) private documentOpenerConfig: DocumentOpenerConfig,
        private session: SessionService,
        private apiCommon: APICommonService
    ) { }

    openDocument(docToOpen: BillingHistoryItem): void {
        const body = document.querySelector('body');
        const form = this.createForm(docToOpen);
        body.appendChild(form);
        this.submit(form);
    }

    openIdCardDocument(docToOpen: IdCardOpenerConfig): void {
        const body = document.querySelector('body');
        const form = this.createIDCardForm(docToOpen);
        body.appendChild(form);
        this.submit(form);
    }

    private createForm(docToOpen: BillingHistoryItem): HTMLFormElement {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = this.documentOpenerConfig.endpoint;
        form.hidden = true;
        form.style.display = 'none';
        form.target = '_blank';

        this.formInputs(docToOpen).forEach((input) => {
            form.appendChild(this.createInput(input.name, input.value));
        });

        return form;
    }

    private createIDCardForm(docToOpen: IdCardOpenerConfig): HTMLFormElement {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = docToOpen.documentOpenerEndpoint;
        form.hidden = true;
        form.style.display = 'none';
        form.target = '_blank';

        this.formIDCardInputs(docToOpen).forEach((input) => {
            form.appendChild(this.createInput(input.name, input.value));
        });

        return form;
    }

    private createInput(name: string, value: string): HTMLInputElement {
        const input = document.createElement('input');
        input.hidden = true;
        input.type = 'text';
        input.name = name;
        input.value = value;
        return input;
    }

    private formInputs(docToOpen: BillingHistoryItem): { name: string; value: string }[] {
        return [
            { name: 'access_token', value: `Bearer ${this.session.accessToken}` },
            { name: 'client_id', value: environment.CLIENT_ID },
            { name: 'message_id', value: this.apiCommon.generateTransactionId() },
            { name: 'output_format', value: 'pdf' },
            { name: 'repo', value: docToOpen.origin },
            { name: 'statement_id', value: docToOpen.statementId },
            { name: 'section_id', value: docToOpen.sectionId },
            { name: 'report_id', value: docToOpen.reportId },
            { name: 'version', value: docToOpen.version }
        ];
    }

    private formIDCardInputs(docToOpen: IdCardOpenerConfig): { name: string; value: string }[] {
        return [
            { name: 'access_token', value: docToOpen.accessToken },
            { name: 'client_id', value: docToOpen.clientId },
            { name: 'nw_repo_id', value: docToOpen.repoId },
            { name: 'file_name', value: docToOpen.fileName },
            { name: 'file_system', value: docToOpen.fileSystem },
            { name: 'pc_env', value: docToOpen.pcEnv },
            { name: 'policy_number', value: docToOpen.policyNumber },
            { name: 'message_id', value: this.apiCommon.generateTransactionId() },
            { name: 'as_ofdate', value: docToOpen.asOfDate }
        ];
    }

    private submit(form: HTMLFormElement): void {
        form.submit();
    }
}
