import { NgModule } from '@angular/core';
import { APICommonService } from '@nationwide/core-component-library-base';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { HeaderService } from './header/header.service';
import { BillingApiCommonService } from './service/billing-api-common.service';
import { BillingEventService } from './service/ebi-service/billing-events.service';
import { EbiApiService } from './service/ebi-service/ebi-api.service';
import { EbiService } from './service/ebi-service/ebi.service';
import { RoutingState } from './service/routing-state.service';
import { CtmService } from './service/ctm-service/ctm.service';
import { ChangeDueDateCommonService } from './service/change-due-date-common.service';
import { PingRedirectService } from './service/ping-redirect/ping-redirect.service';
import { DocOpenerService } from './docs/doc-opener/doc-opener.service';
import { StateClearingGuard } from './service/guards/state-clearing-guard/state-clearing.guard';
import { FooterContentAdapter } from './footer/footer-content.adapter';
import { GlobalNavWrapperContent } from './global-nav-wrapper/global-nav-wrapper-content.service';
import { AbandonmentSurvey } from './service/abandonment-survey.service';

@NgModule({
    imports: [
        InternetServicingAngularComponentsModule
    ],

    providers: [
        APICommonService,
        HeaderService,
        EbiService,
        CtmService,
        BillingEventService,
        EbiApiService,
        BillingApiCommonService,
        RoutingState,
        AbandonmentSurvey,
        ChangeDueDateCommonService,
        PingRedirectService,
        { provide: 'window', useValue: window },
        DocOpenerService,
        // eslint-disable-next-line no-undef
        { provide: 'plaid', useFactory: (): PlaidLink => Plaid },
        StateClearingGuard,
        FooterContentAdapter,
        GlobalNavWrapperContent
    ]
})
export class ISBSharedServicesModule { }
