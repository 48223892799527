import { DatePipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IsbSharedCommonHelperService } from '../../../../../../shared/isb-shared-common-helper.service';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { CardType } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { cardUtils } from '../../../../../../shared/payments/card-utils/card-utils';
import { PaymentFormControls } from '../../../../../../shared/payments/payment-form-controls';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { SessionService } from '../../../../../../shared/session/session.service';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
@Injectable()
export class EditAutomaticPaymentsReview {
    considerThisDisplayable: boolean;

    // eslint-disable-next-line max-params
    constructor(
        private editAutomaticPaymentsFlowService: EditAutomaticPaymentFlowService,
        private titleCasePipe: TitleCasePipe,
        private paymentFormControls: PaymentFormControls,
        private session: SessionService,
        private readonly datePipe: DatePipe,
        private readonly isbSharedCommonHelperService: IsbSharedCommonHelperService
    ) { }

    initialize(): void {
        if (this.editAutomaticPaymentsFlowService.flowInPlay) {
            this.considerThisDisplayable = this.editAutomaticPaymentsFlowService.considerThisDisplayable;
            this.addEmailControlToForm();
            this.addEzPayAuthGroup();
        }
    }

    addEmailControlToForm(): void {
        this.editAutomaticPaymentForm.group.addControl(
            'emailAddressGroup',
            this.paymentFormControls.newEmailFormGroup(this.editAutomaticPaymentsFlowService.emailAddress)
        );
    }

    addEzPayAuthGroup(): void {
        this.editAutomaticPaymentForm.group.addControl(
            'ezPayAuthGroup',
            this.paymentFormControls.newEzPayAuthGroup()
        );
    }

    get addEzPayAuthGroupControls(): AbstractControl {
        return this.editAutomaticPaymentForm.group.get('ezPayAuthGroup');
    }

    get emailAddress(): string {
        return this.enrollingInPaperless === 'Yes' && this.editAutomaticPaymentsFlowService.paperlessEmail ? this.editAutomaticPaymentsFlowService.paperlessEmail : this.editAutomaticPaymentForm.emailAddress;
    }

    get editAutomaticPaymentForm(): PaymentFormGroup {
        return this.editAutomaticPaymentsFlowService.editAutomaticPaymentForm;
    }

    get firstName(): string {
        return this.editAutomaticPaymentForm.firstName;
    }

    get middleInitial(): string {
        return this.editAutomaticPaymentForm.middleInitial;
    }

    get lastName(): string {
        return this.editAutomaticPaymentForm.lastName;
    }

    get payorName(): string {
        return this.middleInitial === '' ?
            `${this.firstName} ${this.lastName}` :
            `${this.firstName} ${this.middleInitial} ${this.lastName}`;
    }

    get authorizedUser(): string {
        return `${this.session.firstName} ${this.session.lastName}`;
    }

    get defaultPaymentMethod(): string {
        return this.editAutomaticPaymentForm.makeDefaultPaymentMethod ? 'Yes' : 'No';
    }

    get cvvLabel(): string {
        return this.isAmexCard ? 'CID' : 'CVV';
    }

    get maskedCvv(): string {
        return this.isAmexCard ? '****' : '***';
    }

    get cvv(): string {
        return this.editAutomaticPaymentForm.bankCardCvv;
    }

    get isAmexCard(): boolean {
        const cardNumber = this.editAutomaticPaymentForm.bankCardNumber;
        return cardUtils.parseType(cardNumber) === CardType.AMERICAN_EXPRESS;
    }

    get cardType(): CardType {
        const cardNumber = this.editAutomaticPaymentForm.bankCardNumber;
        return cardUtils.parseType(cardNumber);
    }

    get bankName(): string {
        return this.editAutomaticPaymentForm.bankName;
    }

    get bankAccountType(): string {
        return this.editAutomaticPaymentForm.bankAccountType;
    }

    get billingAccountHolderName(): string {
        return this.titleCasePipe.transform(this.editAutomaticPaymentsFlowService.billingAccountHolderName);
    }

    get billingAccountNumber(): string {
        return this.editAutomaticPaymentsFlowService.billingAccountNumber;
    }

    get nbpEffectiveDate(): string {
        let nextBill = '';
        if (this.session.isNBP) {
            if (this.editAutomaticPaymentsFlowService.isLifeOnlyAccount) {
                nextBill = 'Next Bill';
            } else {
                nextBill = this.editAutomaticPaymentsFlowService.nextBillDueDate ? this.datePipe.transform(this.editAutomaticPaymentsFlowService.nextBillDueDate, 'MM/dd/yyyy') : 'Next Bill';
            }
        }
        return nextBill;
    }

    get effectiveDate(): string {
        return this.editAutomaticPaymentsFlowService.billedStatus ? 'Current Bill' : 'Next Bill';
    }

    get enrollingInPaperless(): string {
        const enrollInPaperless = this.editAutomaticPaymentForm.enrollInPaperless;
        return enrollInPaperless && this.titleCasePipe.transform(enrollInPaperless);
    }

    get considerThisReviewFields(): RecapFormField[] {
        let considerThisReviewFields: RecapFormField[];
        const paperLess = {
            id: 'enroll-in-paperless-recap',
            label: 'Would you like to enroll in paperless for all of your accounts and policies?',
            value: this.enrollingInPaperless
        };
        if (this.editAutomaticPaymentsFlowService.isPaperlessEligible) {
            considerThisReviewFields = [paperLess];
        }
        if (this.enrollingInPaperless === 'Yes') {
            considerThisReviewFields.push({
                id: 'enroll-in-paperless-recap',
                label: 'Paperless enrollment date',
                value: `${this.isbSharedCommonHelperService.getCurrentDateWithGivenFormatType('MM/dd/yyyy, hh:mm aa ')}ET`
            });
            considerThisReviewFields.push({
                id: 'enroll-in-paperless-recap',
                label: 'Paperless effective date',
                value: `${this.isbSharedCommonHelperService.getCurrentDateWithGivenFormatType('MM/dd/yyyy, hh:mm aa ')}ET`
            });
            considerThisReviewFields.push({
                id: 'enroll-in-paperless-recap',
                label: 'Paperless and automatic payments email',
                value: this.editAutomaticPaymentsFlowService.paperlessEmail || this.editAutomaticPaymentsFlowService.emailAddress
            });
        }
        return considerThisReviewFields;
    }

    get isBankAccountFlow(): boolean {
        return this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount;
    }

    get isBankCardFlow(): boolean {
        return this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankCard;
    }

    get accountType(): string {
        return this.editAutomaticPaymentForm.bankAccountType;
    }

    get street(): string {
        return this.editAutomaticPaymentForm.street || this.editAutomaticPaymentsFlowService.billingStreet1;
    }

    get apartmentNumber(): string {
        return this.editAutomaticPaymentForm.apartmentNumber;
    }

    get city(): string {
        return this.editAutomaticPaymentForm.city || this.editAutomaticPaymentsFlowService.billingCity;
    }

    get zip(): string {
        return this.editAutomaticPaymentForm.zip;
    }

    get stateName(): string {
        return this.editAutomaticPaymentForm.state || this.editAutomaticPaymentsFlowService.billingState;
    }

    get routingNumber(): string {
        return this.editAutomaticPaymentForm.bankRoutingNumber;
    }

    get accountNumberLastThree(): string {
        const bankAccountNumber = this.editAutomaticPaymentForm.bankAccountNumber;
        const ACCOUNT_LAST_THREE = 3;
        return bankAccountNumber.slice(bankAccountNumber.length - ACCOUNT_LAST_THREE);
    }

    get bankAccountNumber(): string {
        return this.editAutomaticPaymentForm.bankAccountNumber;
    }

    get currentDate(): string {
        return this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    }

    get cardExpirationMonth(): string {
        return this.editAutomaticPaymentForm.cardExpirationMonth;
    }

    get cardExpirationYear(): string {
        return this.editAutomaticPaymentForm.cardExpirationYear;
    }

    get cardExpirationDate(): string {
        return `${this.cardExpirationMonth}/${this.cardExpirationYear}`;
    }

    get cardNumberLastFour(): string {
        const cardNumber = this.editAutomaticPaymentForm.bankCardNumber;
        const CARD_LAST_FOUR = 4;
        return cardNumber.slice(cardNumber.length - CARD_LAST_FOUR);
    }

    save(params: Partial<EditAutomaticPaymentFlowService>): void {
        this.editAutomaticPaymentsFlowService.save(params);
    }

    get cardNumber(): string {
        return this.editAutomaticPaymentForm.bankCardNumber;
    }

    get paymentDetailsReviewFields(): RecapFormField[] | BankcardRecapFormField[] {
        const fields = [];

        fields.push({
            id: 'account-holders-name-recap',
            label: 'Account holder\'s name',
            value: this.billingAccountHolderName
        });

        fields.push({
            id: 'billing-account-number',
            label: 'Billing account number',
            value: this.billingAccountNumber
        });

        fields.push({
            id: 'transaction-authorized-by',
            label: 'Transaction authorized by',
            value: this.titleCasePipe.transform(this.authorizedUser)
        });

        if (this.isBankAccountFlow) {
            fields.push({
                id: 'enrollment-date',
                label: 'Enrollment date',
                value: this.currentDate
            });

            fields.push({
                id: 'payment-effective-date',
                label: 'Automatic payment effective date',
                value: this.session.isNBP ? this.nbpEffectiveDate : this.effectiveDate
            });

            fields.push({
                id: 'payment-method',
                label: 'Payment method',
                value: 'Bank account'
            });

            fields.push({
                id: 'account-type-recap',
                label: 'Account type',
                value: this.titleCasePipe.transform(this.accountType)
            });

            fields.push({
                id: 'bank-routing-number-recap',
                label: 'Bank routing number',
                value: this.routingNumber
            });

            fields.push({
                id: 'bank-name-recap',
                label: 'Bank name',
                value: this.bankName
            });

            fields.push({
                id: 'account-number-recap',
                label: 'Account number',
                value: `*********${this.accountNumberLastThree}`
            });
        }

        if (this.isBankCardFlow) {
            fields.push({
                id: 'enrollment-date',
                label: 'Enrollment date',
                value: this.currentDate
            });

            fields.push({
                id: 'payment-effective-date',
                label: 'Automatic payment effective date',
                value: this.session.isNBP ? this.nbpEffectiveDate : this.effectiveDate
            });

            fields.push({
                id: 'card-holder-name-recap',
                label: 'Card holder\'s name',
                value: this.payorName
            });

            fields.push({
                id: 'zip-recap',
                label: 'ZIP',
                value: this.zip
            });

            fields.push({
                id: 'card-number-recap',
                label: 'Card number',
                value: `************${this.cardNumberLastFour}`,
                cardType: this.cardType
            });

            fields.push({
                id: 'expiration-date-recap',
                label: 'Expiration date',
                value: this.cardExpirationDate
            });
        }

        return fields;
    }
}
