import { Component, HostListener, Output, EventEmitter } from '@angular/core';
import { WaysToPayPayload, PaymentMethod, Details, PaymentMethodList } from '../ways-to-pay.model';
import { environment } from '../../../../environments/environment';
import { LoggerService } from '../../../shared/logger/logger.service';
import { PayBillLandingComponent } from '../../pay-bill-landing/pay-bill-landing.component';
import { SessionService } from '../../../shared/session/session.service';
import { WaysToPayAdapter } from '../../shared/adapters/ways-to-pay.adapter';
import { PaymentItem } from '../../../billing/payment-preferences/shared/models/payment-item.model';
import { ACCEPTABLE_RESPONSE_CODES } from '../../../account-validation/response-contstants';
import { PlaceholderPaymentItem } from '../../../billing/payment-preferences/shared/models/placeholder-item.model';
declare let jQuery: any;
@Component({
    selector: 'app-ways-to-pay',
    templateUrl: './ways-to-pay.component.html',
    styleUrls: []
})
export class WaysToPayComponent {
    @Output() selectedPaymentMethod = new EventEmitter<WaysToPayPaymentMethodEmitterData>();
    @Output() iframeHeight = new EventEmitter<number>();

    sendDataInterval;
    waysToPayPayload: WaysToPayPayload;
    payBillLanding: PayBillLandingComponent;
    isStopWatchRunning = false;
    hasStopWatchAlreadyRan = false;
    previousHeight = 0;
    setHeightMessageIdIndex = 0;
    setHeightValueIndex = 1;

    // eslint-disable-next-line max-params
    constructor(
        public logger: LoggerService,
        public session: SessionService,
        public waysToPayAdapter: WaysToPayAdapter
    ) { }

    ngAfterViewInit(): void {
        setTimeout(() => this.displayPaymentIframe());
    }

    @HostListener('window:message', ['$event'])
    onMessage(message: MessageEvent): void {
        if (message?.data?.[this.setHeightMessageIdIndex] === 'setHeight') {
            if (this.previousHeight !== message?.data?.[this.setHeightValueIndex]) {
                const newHeight = message.data?.[this.setHeightValueIndex];
                this.previousHeight = newHeight;
                jQuery('#ways-to-pay-iframe').height(newHeight);
                this.iframeHeight.emit(newHeight);
            }
        } else {
            switch (message?.data?.messageId) {
                case 'received':
                    clearInterval(this.sendDataInterval);
                    this.finishStopWatch();
                    break;
                case 'payment-method':
                    this.selectedPaymentMethod.emit({
                        paymentMethod: this.waysToPayAdapter.mapPaymentMethod(message),
                        canShowNavigationBar: true
                    });
                    break;
                case 'payment-type':
                    /* When user clicks "Add new" pay method buttons, widget sends payment-type event
                      This allows us to hide our button bar until we get newly added payment method info */
                    this.selectedPaymentMethod.emit({
                        paymentMethod: new PaymentItem(new PlaceholderPaymentItem('')),
                        canShowNavigationBar: false
                    });
                    break;
                case 'Previous':
                    this.selectedPaymentMethod.emit({
                        paymentMethod: new PaymentItem(new PlaceholderPaymentItem('')),
                        canShowNavigationBar: true
                    });
                    break;
                case 'Initialization_Error':
                    this.finishStopWatch(false);
                    this.logger.error('Ways To Pay Widget Emit: Initialization Error Message', message.data);
                    break;
                case 'Warning':
                    this.finishStopWatch(false);
                    this.logger.warn('Ways To Pay Widget Emit: Warning Message', message.data);
                    break;
                default:
                    this.startStopWatch();
                    if (typeof message.data === 'string' && message.data?.includes('cookie_data')) {
                        this.logger.info('Ways To Pay Widget Emit: Initialization Message', { message: message.data });
                    } else {
                        this.logger.info('Ways To Pay Widget Emit: Unexpected Message', message.data);
                    }
            }
        }
    }

    private startStopWatch(): void {
        if (!this.isStopWatchRunning && !this.hasStopWatchAlreadyRan) {
            this.logger.startStopWatchFor('Ways To Pay Widget Load');
            this.isStopWatchRunning = true;
        }
    }

    private finishStopWatch(wasSuccessful = true): void {
        if (this.isStopWatchRunning) {
            this.logger.finishStopWatchFor('Ways To Pay Widget Load', { wasSuccessful });
            this.isStopWatchRunning = false;
            this.hasStopWatchAlreadyRan = true;
        }
    }

    displayPaymentIframe(): void {
        const TIMEOUT = 5000;
        this.setupIframeWindow();
        this.setWaysToPayPayload();
        const nationwideSiteWindow = window.open(environment.WAYS_TO_PAY.url, 'ways-to-pay-iframe');
        this.sendDataInterval = setTimeout(() => {
            nationwideSiteWindow.postMessage(this.waysToPayPayload, environment.WAYS_TO_PAY.url);
        }, TIMEOUT);
    }

    setupIframeWindow(): void {
        const waysToPayIframe = document.createElement('iframe');
        waysToPayIframe.setAttribute('sandbox', 'allow-modals allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups');
        waysToPayIframe.setAttribute('allowtransparency', 'true');
        waysToPayIframe.setAttribute('frameborder', '0');
        waysToPayIframe.setAttribute('class', 'ways-to-pay-iframe');
        waysToPayIframe.name = 'ways-to-pay-iframe';
        waysToPayIframe.id = 'ways-to-pay-iframe';
        const waysToPayWidget = document.getElementById('ways-to-pay-widget');
        waysToPayWidget.insertBefore(waysToPayIframe, waysToPayWidget.firstElementChild);
    }

    setWaysToPayPayload(): void {
        const availablePaymentMethodTypes: PaymentMethod[] = [];
        if (environment.WAYS_TO_PAY.areBankAccountPaymentMethodsEnabled) {
            availablePaymentMethodTypes.push({ name: 'Bank Account', filledIn: true });
        }
        if (environment.WAYS_TO_PAY.areBankCardPaymentMethodsEnabled) {
            availablePaymentMethodTypes.push({ name: 'Credit Card', filledIn: true });
        }

        const paymentMethods: PaymentMethodList = {
            paymentMethodsListObject: availablePaymentMethodTypes
        };

        const details: Details = {
            acceptableGiactWarnStatuses: ACCEPTABLE_RESPONSE_CODES,
            clientID: environment.CLIENT_ID,
            customerAccessToken: `Bearer ${this.session.accessToken}`,
            lineOfBusiness: 'PL',
            billingAccountNumber: this.session.billingAccountNumber,
            billingSystem: this.session.billingSystem === 'SAP Billing' ? 'SAP' : 'NBP',
            savedPaymentMethod: environment.WAYS_TO_PAY.savedPaymentMethodsEnabled,
            paymentMethod: paymentMethods,
            hideCVV: environment.WAYS_TO_PAY.shouldHideCVVField,
            plaid: environment.WAYS_TO_PAY.isPlaidEnabled,
            consumerId: 'isb'
        };

        this.waysToPayPayload = {
            details,
            messageId: 'choose-payment-method',
            inline: environment.WAYS_TO_PAY.inline
        };
    }
}

export interface WaysToPayPaymentMethodEmitterData {
    paymentMethod: PaymentItem;
    canShowNavigationBar: boolean;
}
