/* eslint-disable no-extra-parens */
import { Component, HostListener, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthEmitterService, OAuthEvent } from '@nationwide/angular-oauth-module';
import { environment } from '../environments/environment';
import { TimeoutModalService } from './shared/session/time-out/time-out.service';
import { isExternal } from './shared/mixins/session-clearing.component';
import { ConsumerConfigurerService } from './billing-timeline-widget/service/consumer-configurer.service';
import { URL_CONSTANT, MILLISECONDS_IN_SECOND } from './shared/constants/common.constants';
import { PersonalBillingConfiguration } from '@nationwide/dgs-angular-billing-common';
import { SessionService } from './shared/session/session.service';
import { PaymentFlowType } from './pay-bill/shared/payment-flow-type.service';
import { UrlUtil } from './shared/url-util/url-util.service';
import { PingRedirectService } from './shared/service/ping-redirect/ping-redirect.service';
import { LoggerService } from './shared/logger/logger.service';
import { BillingEventService } from './shared/service/ebi-service/billing-events.service';
import { EBIEventType } from './shared/service/ebi-service/ebi-model';
import { FooterService } from './shared/footer/footer.service';
import { FooterData } from '@nationwide/internet-servicing-angular-components';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    // TODO canLoadApplication = false currently creates error in console:
    // core.js:4127 ERROR Error: ExpressionChangedAfterItHasBeenCheckedError:
    // Expression has changed after it was checked. Find work around so value
    // does not change from false to true before page is loaded.
    canLoadApplication = false;
    shouldHideWaitMessage = false;
    isLoadedByExternalApplication = false;
    skipOAuth = false;
    footer: FooterData;
    isLoadedByBAM = false;

    // eslint-disable-next-line max-params
    constructor(
        private router: Router,
        private readonly route: ActivatedRoute,
        private timeoutModalService: TimeoutModalService,
        private oauthEmitterService: OAuthEmitterService,
        private elementRef: ElementRef,
        private session: SessionService,
        private consumerConfigurerService: ConsumerConfigurerService,
        private personalBillingConfiguration: PersonalBillingConfiguration,
        private paymentFlowType: PaymentFlowType,
        private urlUtil: UrlUtil,
        private pingRedirectService: PingRedirectService,
        private readonly eventService: BillingEventService,
        private logger: LoggerService,
        private footerService: FooterService
    ) { }

    @HostListener('window:mousedown', ['$event'])
    @HostListener('window:mouseup', ['$event'])
    @HostListener('window:scroll', ['$event'])
    onSessionActivity(): void {
        this.timeoutModalService.recordSessionActivity();
    }

    @HostListener('window:click', ['$event'])
    handleClick(event: Event): void {
        const target = event && <HTMLElement>event.target;
        if (this.isExternalNavClick(target)) {
            const externalUrl: string = this.getExternalUrl(target);
            this.session.externalAppPageName = this.urlUtil.extractPageUrl(externalUrl);
            this.session.logOutUser();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    leavingApp(): void {
        this.logger.info('Leaving ISB');
        this.logger.logCurrentContents();
        this.session.clearAccessToken();
        this.session.clearPageInfo();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(): void {
        const goingTo = window.location.href;
        const comingFromBkey = this.route.snapshot.queryParams[environment.QUERY_PARAMS.billingAccount] || 'noBkey';
        this.navigateToNewBillingAccountCheck(goingTo, comingFromBkey);
    }

    ngOnInit(): void {
        this.logger.generateUniqueId();
        this.logger.info('App Initializing...');
        this.setBAMApplicationFlag();
        this.fetchFooter();
        if (this.pingRedirectService.isBackFromRedirect) {
            this.logger.info('isBackFromRedirect is true');
            this.pingRedirectService.handleBackFromRedirect();
        }
        this.setExternalApplicationFlag();
        this.loadNRScript();
        if (this.isLoadedByExternalApplication) {
            this.consumerConfigurerService.setupTimeline(this.elementRef);
            if (this.isOauthRequired) {
                this.oauthEmitterService.oauthEmitter.subscribe((event: OAuthEvent) => {
                    if (!this.oauthEmitterService.inDescendantIframe()) {
                        this.reactTo(event);
                    }
                });
            } else {
                this.setCanLoadApplication(true);
            }
            this.router.navigateByUrl(URL_CONSTANT.BILLING_TIMELINE_ROUTE_URL);
        } else if (this.shouldSkipOAuth()) {
            this.skipOAuth = true;
            this.setCanLoadApplication(true);
        } else if (this.session.isBrowserBackAfterLogOut) {
            this.logger.info('Leaving ISB isBrowserBackAfterLogOut is true');
            this.session.gotoLogin();
        } else {
            const oAuthtoken = this.getUrlParameter('oauth_token');
            const mpStatus = this.getUrlParameter('mpstatus');
            if (oAuthtoken && mpStatus === 'success') {
                this.logger.info(`oAuthtoken exisits and mpStatus is successful`);
                sessionStorage.setItem('oAuthToken', oAuthtoken);
                const paymentUrl = `${window.location.origin}/${window.location.hash}`;
                window.location.replace(paymentUrl);
            } else {
                this.oauthEmitterService.oauthEmitter.subscribe((event: OAuthEvent) => {
                    this.logger.info(`oauthEmitter emitted event, iniframe is ${this.oauthEmitterService.inDescendantIframe()}`);
                    if (!this.oauthEmitterService.inDescendantIframe()) {
                        this.reactTo(event);
                    }
                    this.logger.logCurrentContents();
                });
            }
        }
        this.logger.logCurrentContents();
    }

    triggerEbiEvent(): void {
        return this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_CONTACT_US_LINK_LOGS_OUT_OF_ISB);
    }

    setCanLoadApplication(value: boolean): void {
        this.logger.info(`Setting canLoadApplication to ${value}`);
        this.canLoadApplication = value;
        if (!this.shouldHideWaitMessage && value) {
            setTimeout(() => this.shouldHideWaitMessage = true, MILLISECONDS_IN_SECOND * 2);
        }
    }

    shouldSkipOAuth(): boolean {
        let shouldSkip = false;

        if (this.paymentFlowType.isQuickpay || (this.urlUtil.hashPath === '#/personal/billing/landing' && !this.pingRedirectService.isBackFromRedirect)) {
            shouldSkip = true;
        }

        return shouldSkip;
    }
    getExternalUrl(eventTarget: HTMLElement): string {
        return eventTarget && (<HTMLLinkElement>eventTarget).href;
    }

    navigateToNewBillingAccountCheck(goingTo, comingFrom): void {
        if (!goingTo.includes(comingFrom)) {
            this.refreshPage();
        }
    }

    refreshPage(): void {
        location.reload();
    }

    private loadNRScript(): void {
        // const script = document.createElement('script');
        // script.type = 'text/javascript';
        // script.src = environment.BAMNR;
        // document.getElementsByTagName('head')[0].appendChild(script);
    }

    private getUrlParameter(name: string): string {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        // eslint-disable-next-line prefer-template
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    private reactTo(event: OAuthEvent): void {
        this.logger.info(`Reacting to OauthEvent of type: ${event.type}`);
        if (
            event.type === 'OAuthSuccess' ||
            event.type === 'OAuthNotNecessary'
        ) {
            this.setCanLoadApplication(true);
            this.timeoutModalService.pollTimeOut();
        }

        // TODO: GH-Issue 1499 - Refactor to OAuth Module
        if (event.type === 'OAuthFailure' && !this.router.url.includes('/pay-bill/review')) {
            this.setCanLoadApplication(true);
            this.router.navigateByUrl('/personal/error/system-down');
        }
    }

    private isExternalNavClick(eventTarget: HTMLElement): boolean {
        if ((<HTMLLinkElement>eventTarget).href === environment.CONTACT_US_URL) {
            this.triggerEbiEvent();
        }
        return !!eventTarget &&
            !!eventTarget.getAttribute && (

                eventTarget.getAttribute('external') !== null ||

                isExternal((<HTMLLinkElement>eventTarget).href) && eventTarget.getAttribute('target') !== '_blank'

            );
    }

    private setExternalApplicationFlag(): void {
        const inputValue: string = this.elementRef.nativeElement.getAttribute('is-external-application');
        this.isLoadedByExternalApplication = inputValue && typeof inputValue === 'string' && inputValue === 'true';
    }

    private setBAMApplicationFlag(): void {
        this.isLoadedByBAM = window.location.href.includes('BAM/BillingTimeline');
    }

    get isOauthRequired(): boolean {
        return this.personalBillingConfiguration.isAccessTokenRequired;
    }

    private fetchFooter(): void {
        this.logger.info('Initializing footer');
        this.footer = this.footerService.defaultFooter(!this.paymentFlowType.isQuickpay);
        this
            .footerService
            .fetchFooter(!this.paymentFlowType.isQuickpay)
            .subscribe((footer) => {
                this.logger.info('Initializing footer complete');
                this.footer = footer;
            });
    }
}
