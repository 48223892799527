/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ContentfulISBDataItem, FooterContentAttributes, ContentfulRawDataItem, FooterImageAttributes } from '@nationwide/dgs-angular-billing-common';

@Injectable()
export class FooterContentAdapter {
    convertResponseV1(contentfulResponse: ContentfulISBDataItem): FooterContentAttributes {
        return this.mapFooterContent(contentfulResponse);
    }

    mapFooterContent(contentfulResponse: ContentfulISBDataItem): FooterContentAttributes {
        const mappedResponse = {
            Components: {}
        };
        contentfulResponse.Components.forEach(({ fields }) => {
            const { id, name, Name, content, Content, Images, images } = fields;
            mappedResponse.Components[id] = {
                id,
                name: name || Name,
                ...Content && { Content: content || Content },
                ...(Images || images) && { Images: this.mapImagesAttributes(Images || images) }
            };
        });
        return mappedResponse;
    }

    private mapImagesAttributes(images: Array<ContentfulRawDataItem>): FooterImageAttributes {
        const imageAttributes = {};
        images.forEach(({ fields }) => {
            const { id, AltText, URL, name, Name, Link } = fields;
            imageAttributes[id] = {
                id,
                AltText,
                URL: URL.fields.file.url,
                Link,
                Name: name || Name,
                styleObj: this.setStyleObj(fields)
            };
        });
        return imageAttributes;
    }

    private setStyleObj(obj: ContentfulRawDataItem): { [x: string]: any } {
        /* eslint-disable @typescript-eslint/quotes */
        /* eslint-disable quote-props */
        return {
            ...obj.URL?.fields?.file?.url && { 'background-image': `url(${obj.URL.fields.file.url})` },
            ...obj.backgroundPositionX && obj.backgroundPositionY && { 'background-position': `${obj.backgroundPositionX} ${obj.backgroundPositionY}` },
            ...obj.Height && { height: obj.Height },
            ...obj.Width && { width: obj.Width }
        };
    }
}
